import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box, Stack, Button, CircularProgress } from '@mui/material';
import { EnsureUser } from '../../auth/EnsureUser';
import { useMyOrgs } from 'components/customer/orgs/MyOrgAPIEndpoints';
import { MyOrgFormDialog } from './MyOrgFormDialog';
import EditIcon from '@mui/icons-material/Edit';
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { userInfo } from '../../auth/UserInfo';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { OrgAppSettingsContext } from '../../../lib/OrgAppSettingsProvider';
import { markdownFormatter } from 'lib/Formatters';
import { UcFooter } from "../../common/UcFooter";
import { PageContext } from '../../../lib/PageProvider';

export const MyOrg = (props) => {
    const { t } = useTranslation();
    const swrConfig = useSWRConfig();
    const [editDialog, setEditDialog] = React.useState({ open: false });
    const [reMutateUrls, setReMutateUrls] = React.useState(false);
    const [pageState, pageDispatch] = React.useContext(PageContext);

    const { orgAppSettings } = React.useContext(OrgAppSettingsContext);

    const orgPhoneNumber = orgAppSettings.result?.phoneNumber;
    const disclaimer = orgAppSettings.result?.contactDisclaimer;
    const disclaimerWithNumber = disclaimer?.replace("{PhoneNumber}", orgPhoneNumber);

    const swrMyOrg = useMyOrgs();

    let itemToEdit = null;
    if (!swrMyOrg.isLoading && swrMyOrg.result?.length > 0) {
        itemToEdit = { ...swrMyOrg.result[0] };
    }

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const openDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem });
    }

    React.useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    React.useEffect(() => {
        pageDispatch({
            header: "My Organization",
        });
    }, [pageState.header, pageDispatch]);

    return (
        <>
            {!swrMyOrg.isLoading && swrMyOrg.result?.length > 0 && !userInfo.isLoading && (!orgAppSettings?.isLoading && orgAppSettings?.result !== undefined) && (
                <EnsureUser permissions={["MyOrg:Modify"]}>
                    <>
                        <h1 className="pageHeader">{t("My Organization")}</h1>
                        <h3 className="pageHeader">{itemToEdit?.name}</h3>
                        <MyOrgFormDialog
                            open={editDialog.open}
                            itemToEdit={editDialog.itemToEdit}
                            onCancel={closeHandler}
                            onSave={saveHandler}
                        />
                        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', mt:4 }}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="h6" gutterBottom> {t("Multi-Factor Authentication")} </Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                                        <Button data-cy-id={"editMyOrgButton"} size="small" sx={{ minWidth: 'auto' }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => openDialog(itemToEdit)}
                                        >
                                            <EditIcon fontSize="small" />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography data-cy-id="myorg_twofactor">
                                                {itemToEdit.twoFactorEnabled ? t("ON") : t("OFF")}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="h6" gutterBottom>
                                            {t("Temperature Unit")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                                        <Typography data-cy-id="myorg_temp">
                                            {itemToEdit.temperatureUnit === "C" ? t("Celsius (°C)") : t("Fahrenheit (°F)")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="h6" gutterBottom>
                                            {t("Measurement Unit")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                                        <Typography data-cy-id="myorg_measure">
                                            {itemToEdit.measurementUnit}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography  variant="h6" gutterBottom>
                                            {t("Support Email")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                                        <Typography data-cy-id="myorg_support_email">
                                            {itemToEdit.supportEmail}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} sm={12} md={12}>
                                        <Typography variant="h6" gutterBottom> {t("Contact Information")} </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                                        <Typography variant="body2" sx={{mb:2}} gutterBottom> {t("This info will be displayed on the register screen.")} </Typography>
                                        <Alert severity='info' data-cy-id="myorg_contactinfo">
                                        <>
                                            <AlertTitle>{t('Contact Us')}</AlertTitle>
                                            {//this line can be changed to just be: markdownFormatter(disclaimerWithNumber), did this condition for testing to see if it made a difference
                                            itemToEdit.contactInformation ? (
                                                markdownFormatter(itemToEdit.contactInformation)
                                            ): (
                                                markdownFormatter(disclaimerWithNumber)
                                            )}
                                        </>
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={8} sm={12} md={12}>
                                        <Typography variant="h6" gutterBottom> {t("Footer Information")} </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                                        <Typography variant="body2" sx={{mb:2}} gutterBottom> {t("This info will be displayed in the footer of residential user pages.")} </Typography>
                                        <UcFooter align={"left"} disablePadding/>
                                        <b/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                     </>
                </EnsureUser>
            )}
            {swrMyOrg.isLoading &&
                <Box sx={{ textAlign: 'center' }}>
                    <CircularProgress size={50} />
                </Box>
            }
        </>
    );
}