// @ts-ignore
import { ApiClient } from "../../lib/ApiClient";
// @ts-ignore
import { ensureLuxonDate } from "../../lib/Formatters";
import { useGenericSWR } from "../../lib/useGenericSWR";
import { SwrOptions } from "../../lib/useGenericSWR.interfaces";

const apiEndpoint = 'api/v1/WeatherChart';

const apiClient = new ApiClient();
apiClient.getDefaultItem = () => {
    return { id: 0 };
};
apiClient.getObjectName = () => {
    return "Weather Chart Data";
};

export const useMyWeatherChartData = (
    startDate: any,
    endDate: any,
    groupBy: string,
    geoCoordinates: any,
    swrOptions: SwrOptions = {}
) => {

    const params = new URLSearchParams();
    params.append('startTimeUTC', ensureLuxonDate(startDate)?.toUTC()?.toISO());
    params.append('endTimeUTC', ensureLuxonDate(endDate)?.toUTC()?.toISO());
    params.append('groupBy', groupBy);
    params.append('geoCoordinatesJson', JSON.stringify(geoCoordinates));

    let url = apiEndpoint + "?" + params.toString();

    return useGenericSWR((geoCoordinates.length < 0 || startDate === null || endDate === null) ? null :
        url,
        apiClient,
        {
            ...swrOptions,
            refreshInterval: 3600000, // refresh every hour
            errorRetryInterval: 3600000, // refresh every hour
            doToast: false
        }
    );
}