import React, { useState, useLayoutEffect, useEffect, useContext } from 'react';
import { OutageFormDialog } from './OutageFormDialog';
import { mutateUrls } from "lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { UcToolbar } from 'components/common/UcToolbar';
import { useTranslation } from "react-i18next";
import { useResponsive } from 'lib/useResponsive';
import { UcIconButtons } from 'components/common/UcIconButtons';
import { UcIconButtonsWithLabel } from 'components/common/UcIconButtonsWithLabel';
import { OutageGridStateful } from './OutageGridStateful';
import { getWholeRow } from '../../../lib/DataGridHelpers';
import { dateFormatter, timeAgoDateFormatter } from '../../../lib/Formatters';
import { Chip, Tooltip } from '@mui/material';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CancelIcon from '@mui/icons-material/Cancel';
import EnsureUserAndModule from '../../auth/EnsureUserAndModule';
import { PageContext } from '../../../lib/PageProvider';

export const OutageListPage = (props) => {
    const { t } = useTranslation();
    const [editDialog, setEditDialog] = useState({ open: false});
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const { isMobile } = useResponsive();
    const [generalSearch, setGeneralSearch] = useState('');
    const [pageState, pageDispatch] = useContext(PageContext);

    useEffect(() => {
        pageDispatch({
            header: "Outages",
        });
    }, [pageState.header, pageDispatch]);

    const actionsHandler = (itemValue, labelFlag) => {
        const actions = [
            {
                icon: <Tooltip data-cy-id={"outage_restore_"+itemValue.extAccountNumber} title={t('Restore Outage')}><SettingsBackupRestoreIcon /></Tooltip>,
                isDisabled: itemValue.status === "Restored",
                requiredPermission: 'Outage:Modify',
                onClick: () => { restoreItem(itemValue); },
            },
            {
                icon: <Tooltip data-cy-id={"outage_cancel_"+itemValue.extAccountNumber} title={t('Cancel Outage')}><CancelIcon /></Tooltip>,
                color: 'primary',
                isDisabled: itemValue.status === "Cancelled",
                requiredPermission: 'Outage:Modify',
                onClick: () => { cancelItem(itemValue); },
            }
        ];

        return (labelFlag ? (
            <UcIconButtonsWithLabel actions={actions} />
            ) : (
            <UcIconButtons actions={actions} />
            )
        );
    }

    let defaultDataItems = {
        columnVisibilityModel: {
            id: true,
            cepOrgId: false,
            content: false,
            updatedBy: false,
        },
        columns: [
            { field: 'type', headerName: t('Outage Type'), width: 200},
            { field: 'status', headerName: t('Outage Status'), minWidth: 200, renderCell: (params) => {
                return (
                    <>
                        {
                            {
                            'Active': <Chip
                                label={t("Active")}
                                color="error"
                                variant="outlined"
                            />,
                            'Cancelled': <Chip
                                label={t("Cancelled")}
                                color="primary"
                                variant="outlined"
                            />,
                            'Restored': <Chip
                                label={t("Restored")}
                                color="success"
                                variant="outlined"
                            />
                            }[params.value]
                        }
                    </>
                    );
                }
            },
            { field: 'extAccountNumber', headerName: t('Account Number'), width: 200 },
            { field: 'serialNo', headerName: t('Meter Number'), width: 200 },
            { field: 'accountName', headerName: t('Account Name'), width: 200 },
            { field: 'accountAddress', headerName: t('Account Address'), width: 200 },
            { field: 'uom', headerName: t('UOM'), width: 200 },
            { field: 'source', headerName: t('Source'), minWidth: 100 },
            { field: "activeTs", headerName: t('Active On'), width: 200, valueFormatter: (params) => { return dateFormatter(params.value); } },
            { field: "restoredTs", headerName: t('Restored On'), width: 200, valueFormatter: (params) => { return dateFormatter(params.value); } },
            { field: "cancelledTs", headerName: t('Cancelled On'), width: 200, valueFormatter: (params) => { return dateFormatter(params.value); } },
            { field: 'estimatedRestoreTime', headerName: t('Est. Restore Time'), minWidth: 200 },
            { field: "timeElapsed", headerName: t('Time Elapsed'), width: 200, valueGetter: (params) => params.row.createdTs, valueFormatter: (params) => { return timeAgoDateFormatter(params.value); } },
            { field: 'id', headerName: '',
                valueGetter: getWholeRow,
                renderCell: (params) => {
                    return actionsHandler(params.value, false);
                }
            },
        ],
        rows: [],
        rowCount: -1,
    };

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    }

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    }

    const restoreDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "restore" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, restore: true, cancel: false });
    }

    const cancelDialog = (dataItem) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "cancel" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, restore: false, cancel: true });
    }

    const restoreItem = (item) => {
        restoreDialog(item);
    }

    const cancelItem = (item) => {
        cancelDialog(item);
    }

    const actions = [];

    useLayoutEffect(() => {
        if (reMutateUrls)
        {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <EnsureUserAndModule permissions={["Outage:Read"]} modules={['OutageManual']}>
            <div className="OutageList">
                <h1 className="pageHeader">{t('Outages')}</h1>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value) => {
                        setGeneralSearch(value);
                    } }
                    actions={actions}>
                </UcToolbar>
                {!reMutateUrls &&
                <>
                    {!isMobile && (<UcToolbar actions={actions}></UcToolbar>)}
                    <OutageGridStateful
                        isFullPage={props.isFullPage || false}
                        defaultDataItems={defaultDataItems}
                        actionsHandler={actionsHandler}
                        generalSearch={generalSearch}
                    />
                </>
                }
                <OutageFormDialog
                    open={editDialog.open}
                    restore={editDialog.restore}
                    cancel={editDialog.cancel}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
            </div>
        </EnsureUserAndModule>
    );
};