import React, { useEffect } from "react";
import { UcProgressStats } from "components/common/UcProgress";

interface UcProgressWrapperProps {
    data?: any,
    groupBy?: string,
    readyForData: boolean,
    selectedMeter?: string | null,
    onProgress?: Function,
    onReset?: Function,
    onDataCompleted?: Function,
}

const UcProgressWrapper = (options: UcProgressWrapperProps) => {
    const { data } = options;
    const [prevOptions, setPrevOptions] = React.useState(options);
    const [progress, setProgress] = React.useState<UcProgressStats>({ current: 0, total: 100 });

    useEffect(() => {
        if (options.selectedMeter !== prevOptions.selectedMeter || options.groupBy !== prevOptions.groupBy) {
            setPrevOptions({...options});
            const newProgress = {current: 1, total: 100};
            setProgress(newProgress); // reset the progress
            if (options.onReset) {
                options.onReset();
            }
        }
    }, [options, prevOptions.selectedMeter, prevOptions.groupBy]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress.current >= progress.total) {
                clearInterval(interval);
                return;
            }
            // Make it so it is never 100%.. this is a fake progress
            const newProgress = {current: progress.current+1, total: progress.total+1};
            setProgress(newProgress);
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }, 200);
        return () => { clearInterval(interval); }
    }, [options, progress]);

    React.useEffect(() => {
        if (data && !data?.isLoading && options.readyForData && progress.current !== 1 && progress.total !== 1) {
            if (options.onDataCompleted) {
                options.onDataCompleted(data.result);
            }
            const newProgress = {current: 1, total: 1};
            setProgress(newProgress); // just complete the progress
            if (options.onProgress) {
                options.onProgress(newProgress);
            }
        }
    }, [data, options, progress]);

    return null;
};

export {
    UcProgressWrapper
};