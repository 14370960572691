import { useTranslation } from 'react-i18next';
import type { Payload } from "recharts/types/component/DefaultLegendContent";
import type { LegendProps } from "recharts";

export const CustomerGenerationLegend = (props: LegendProps) => {
    const { payload } = props;
    const { t } = useTranslation();
    let generationPayload = null;

    if (payload) {
        generationPayload = payload[0];
    }

    const getIcon = (entry: Payload | null) => {
        return <div style={{display:'inline-block', width: props.iconSize, height: props.iconSize, backgroundColor: entry?.color, borderRadius: '10px'}}></div>;
    }

    return (
        // keep ucLegend class for styling
        <ul className="ucLegend" data-cy-id="customerGeneration-legend"> 
            <li>
                <div title={t("Generation")} style={{ color: generationPayload?.color }}> {getIcon(generationPayload)} {t("Generation")}</div> 
            </li>        
        </ul>
    );
  }

export default CustomerGenerationLegend;


