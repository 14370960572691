import * as React from 'react';
import { Box, FormControl, ListItemIcon, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import ClearIcon from '@mui/icons-material/Clear';
import { ThemeProps } from "../common/Common.interfaces";
import { TFunction, useTranslation } from "react-i18next";
import { Account } from 'lib/AccountProvider';
// @ts-ignore
import { commodityToUom } from '../../lib/Formatters';

export interface MeterOption {
    id: string | undefined;
    meterCommodity: string;
    startTimestamp?: string;
    endTimestamp?: string;
    serialNo: string;
    uom?: string;
    meterNickname?: string;
}

interface UcMeterSelectProps {
    account: Account | null;
    startDate?: Date;
    endDate?: Date;
    onlyCommodity: boolean;
    id?: string;
    onChange: Function;
    forcedWidth?: string;
    datacyid?: string;
    disabled?: boolean;
    commodity?: string;
    uom?: string;
    value: MeterOption | null;
    options: MeterOption[];
    marginTop?: string;
}

const commodityText = (t: TFunction<"translation", undefined>, commodity: string, serialNo: string | undefined, meterNickname: string | undefined) => {
    let displayText = "";
    if (meterNickname !== null && meterNickname !== undefined && meterNickname !== "")
        displayText = meterNickname;
    else if (serialNo !== null && serialNo !== undefined && serialNo !== "")
        displayText = serialNo;

    if (commodity.toUpperCase() === "ELECTRIC") {
        return t("Electric - ") + displayText + (serialNo === null ? "All Meters" : "")
    }
    else {
        return t("Water - ") + displayText + (serialNo === null ? "All Meters" : "")
    }
}

export const UcMeterSelectV2 = ((props: UcMeterSelectProps) => {
    const { value, onChange, options } = props;
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const theme: ThemeProps = useTheme();

    const handleChange = (event: any) => {
        const { target: { value } } = event;
        let valueOption = options.find((x: MeterOption) => x.id === value);

        if (valueOption) {
            valueOption.uom = commodityToUom(valueOption.meterCommodity);
        }

        onChange(valueOption);
    };

    let id = React.useId();

    return (
        <FormControl sx={{ minWidth: 120, pb: 1, width: props.forcedWidth ?? '100%', mt: props.marginTop }} size="small">
            <Select
                sx={{ fontSize: 15 }}
                id={props.id || "ucMeterSelect" + id}
                data-cy-id={props.datacyid}
                open={open}
                value={value?.id ?? "No options available..."}
                fullWidth={false}
                disabled={props.disabled || options.length === 0}
                title={value?.serialNo}
                onChange={handleChange}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                input={<OutlinedInput />}
                renderValue={() => (
                    (options.length > 0 && value !== undefined) ? (
                        value?.meterCommodity === "ELECTRIC" ? (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ElectricBoltIcon sx={{ color: (theme.props?.Commodity?.kWhColor), pr: 1, fontSize: 15 }} />
                                {commodityText(t, "ELECTRIC", value?.serialNo, value?.meterNickname)}
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <WaterDropIcon sx={{ color: (theme.props?.Commodity?.galColor), pr: 1, fontSize: 15 }} />
                                    {commodityText(t, "WATER", value?.serialNo, value?.meterNickname)}
                            </Box>
                        )
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ClearIcon sx={{ pr: 1 }} />
                            {t("No options available...")}
                        </Box>
                    )
                )}
            >
                {options?.map((option: MeterOption) => (
                    <MenuItem
                        key={option.id}
                        value={option.id}
                        sx={{ fontSize: 15 }}
                    >
                        {option.meterCommodity === "ELECTRIC" ? (
                            <>
                                <ListItemIcon>
                                    <ElectricBoltIcon sx={{ color: (theme.props?.Commodity?.kWhColor), fontSize: 15 }} />
                                </ListItemIcon>
                                {commodityText(t, "ELECTRIC", option.serialNo, option.meterNickname)}
                            </>
                        ) : (
                            <>
                                <ListItemIcon>
                                        <WaterDropIcon sx={{ color: (theme.props?.Commodity?.galColor), fontSize: 15 }} />
                                </ListItemIcon>
                                {commodityText(t, "WATER", option.serialNo, option.meterNickname)}
                            </>
                        )}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});