// Typography.jsx -- Overrides mui Typography so we can use title attribute
// Do not use this component on new changes
// This was only a stub to get current functionality working again with a TS update
import React from 'react';
import PropTypes from 'prop-types';
import { Typography as MuiTypography } from '@mui/material';

export const Typography = ({ children, ...props }) => {
  return (
    <MuiTypography {...props}>
      {children}
    </MuiTypography>
  );
};

Typography.propTypes = {
  children: PropTypes.node.isRequired
};