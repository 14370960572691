import { useState, useContext, useEffect, useLayoutEffect } from "react";
import { PageContext } from "../../../lib/PageProvider";
import { mutateUrls } from "../../../lib/useGenericSWR";
import { useSWRConfig } from "swr";
// @ts-ignore
import { UcToolbar } from "../../common/UcToolbar";
// @ts-ignore
import { UcListPage } from "components/common/UcListPage";
import { RateClassChangeRequestGridStateful } from "./RateClassChangeRequestGridStateful";
import { RateClassChangeRequestFormDialog } from "./RateClassChangeRequestFormDialog";
import { useTranslation } from "react-i18next";
import EnsureUserAndModule from "components/auth/EnsureUserAndModule";

export const RateClassChangeRequestListPage = () => {
    const { t } = useTranslation();
    const [editDialog, setEditDialog] = useState<any>({
        open: false,
        delete: false,
    });
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageState, pageDispatch] = useContext(PageContext);
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const [generalSearch, setGeneralSearch] = useState("");
    const swrConfig = useSWRConfig();

    const closeHandler = () => {
        setEditDialog({ open: false, itemToEdit: editDialog.dataItem });
    };

    const openDialog = (dataItem: any) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "edit" + dataItem.id;
        }
        setEditDialog({ open: true, itemToEdit: dataItem, delete: false });
    };

    const saveHandler = () => {
        closeHandler();
        setReMutateUrls(true);
    };

    const deleteDialog = (dataItem: any) => {
        if (!dataItem) {
            return;
        }
        if (!dataItem.formIteration) {
            dataItem.formIteration = "delete" + dataItem.id;
        }
        setEditDialog({
            open: true,
            itemToEdit: dataItem,
            delete: true,
            setDefault: false,
        });
    };

    const editItem = (item: any) => {
        openDialog(item);
    };

    const deleteItem = (item: any) => {
        deleteDialog(item);
    };

    const actions: any[] = [];

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: t("Rate Change Requests"),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => {},
            tabValue: "",
        });
    }, [pageState.header, selectedItem, pageDispatch, t]);

    console.log("RateClassChangeRequestListPage - Loaded");

    return (
        <UcListPage>
            <EnsureUserAndModule
                permissions={["RateClassChangeRequest:Modify"]}
                modules={["RateClassComparison"]}
            >
                <h1 className="pageHeader">
                    {t("Rate Change Requests")}
                </h1>
                <UcToolbar
                    hasGeneralSearch={true}
                    onGeneralSearchChange={(value: string) => {
                        setGeneralSearch(value);
                    }}
                    actions={actions}
                ></UcToolbar>
                <RateClassChangeRequestFormDialog
                    open={editDialog.open}
                    delete={editDialog.delete}
                    itemToEdit={editDialog.itemToEdit}
                    onCancel={closeHandler}
                    onSave={saveHandler}
                />
                {!reMutateUrls && (
                    <RateClassChangeRequestGridStateful
                        generalSearch={generalSearch}
                        onEditItem={editItem}
                        onDeleteItem={deleteItem}
                        onSelected={(selectedItem: any) =>
                            setSelectedItem({ ...selectedItem })
                        }
                    />
                )}
            </EnsureUserAndModule>
        </UcListPage>
    );
};
