// @ts-ignore
import React from "react";

export interface PageState {
    header: string;
    variableHeader: string;
    actionItems: any[];
    tabs: any[];
    onTabChange: () => void;
    tabValue: string;
}

export interface PageAction {
    header: string;
    variableHeader?: string;
    actionItems: any[];
    tabs: any[];
    onTabChange: () => void;
    tabValue: string;
}

export type PageProviderProps = {
    children: React.ReactNode;
}

const initialPageState: PageState = {
    header: "Welcome",
    variableHeader: "",
    actionItems: [],
    tabs: [],
    onTabChange: () => { },
    tabValue: ''
};

const pageReducer = (state: PageState, action: PageAction) => {
    return {
        ...state,
        header: action.header || initialPageState.header,
        variableHeader: action.variableHeader ?? initialPageState.variableHeader,
        actionItems: action.actionItems || initialPageState.actionItems,
        tabs: action.tabs || initialPageState.tabs,
        onTabChange: action.onTabChange,
        tabValue: action.tabValue
    };
};

export const PageContext = React.createContext<[PageState, React.Dispatch<PageAction>]>([{ ...initialPageState }, () => { }]);

export const PageProvider = ({ children }: PageProviderProps) => {
    const [pageState, pageDispatch] = React.useReducer(pageReducer, initialPageState);

    return (
        <PageContext.Provider value={[pageState, pageDispatch]}>
            {children}
        </PageContext.Provider>
    );
};