import React from "react";
import { MyUsageFilterDialogProps } from "./MyUsage.interfaces";
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MyUsageFilter from "./MyUsageFilter";

function MyUsageFilterDialog(props: Readonly<MyUsageFilterDialogProps>) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(props.open || false);
    const [dialogSubmit, setDialogSubmit] = React.useState(0);

    const handleClose = () => {
        setOpen(false);
        props.onChangeOpen(false);
    };

    const handleSubmit = () => {
        setDialogSubmit(dialogSubmit + 1);
    }

    React.useEffect(() => {
        if (props.open !== open) {
            setOpen(props.open);
        }
    }, [props.open, open]);

  return (
      <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ display: open ? 'block' : 'none' }}
      >
          <DialogTitle id="alert-dialog-title">
              {t("Date Filter")}
          </DialogTitle>
          <DialogContent>
              <MyUsageFilter
                  dialogSubmit={dialogSubmit}
                  defaultStartDate={props.startDate}
                  defaultEndDate={props.endDate}
                  chartType={props.chartType}
                  defaultValues={props.defaultValues}
                  uom={props.uom}
                  onSubmit={(values) => {
                      props.onSubmit(values);
                      handleClose();
                  }}
              />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>{t('Cancel')}</Button>
              <Button onClick={handleSubmit}>{t('Filter')}</Button>
          </DialogActions>
      </Dialog>
  );
}

export default MyUsageFilterDialog;