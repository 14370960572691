// @ts-ignore
import { DateTime } from "luxon";
// @ts-ignore
import { ApiClient } from "../../../lib/ApiClient";
import { useGenericSWR } from "../../../lib/useGenericSWR";
import { SwrOptions } from '../../../lib/useGenericSWR.interfaces';
import { Org } from "./MyOrg.interfaces";
import * as Yup from 'yup';

const apiEndpoint = 'api/v1/MyOrg';
const orgAppSettingsEndpoint = 'api/v1/OrgAppSettings';

const orgApiClient = new ApiClient();
orgApiClient.requiresAuth = false;

let formIteration = 0;

const getMyOrgsDefaultItem = () => {
    formIteration = formIteration + 1;
    return {
        formIteration: "new" + formIteration,
        id: 0,
        udmOrgId: null,
        name: '',
        description: '',
        logoUrl: '',
        status: '',
        createdBy: 'REACT_FE',
        updatedBy: 'REACT_FE',
        contactInformation: ''
    }
};

const myOrgValidationSchema = Yup.object().shape({
    supportEmail: Yup.string().email('Customer Support Email must be a valid email')
        .max(255, 'Max of 255 characters') 
});

// See definition from Org.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => getMyOrgsDefaultItem();
apiClient.getObjectName = () => {
    return "Organization";
};

const useMyOrgs = (swrOptions = {} as SwrOptions) => {
    // SWR will prevent duplicate requests
    // It will also maintain data state as long as your are using the same urls for your requests
    const filter = {
        take: 100,
        skip: 0,
        sort: null,
        generalSearch: "",
        status: "ACTIVE",
    }

    const params = new URLSearchParams();
    params.append('filter', JSON.stringify(filter));
    return useGenericSWR(apiEndpoint + "?" + params.toString(), apiClient, { ...swrOptions });
};

const useMyTimezone = () => {
    const swrOrgs = useMyOrgs();

    if (swrOrgs.result && !swrOrgs.isLoading && !swrOrgs.error) {
        return swrOrgs.result[0]?.timezone;
    }

    return null;
}

const useMyNow = () => {
    const timezone = useMyTimezone();

    //const localTime = DateTime.local();

    // Set the timezone to the desired timezone (e.g. New York)
    //return localTime.setZone(timezone);
    const utcTime = DateTime.utc();

    // Get the current time in "America/New_York" timezone, adjusting for UTC
    const resultLocal = DateTime.local();
    const result = utcTime.setZone(timezone);
    console.log("USEMYNOW", utcTime.toISO(), result.toISO(), resultLocal.toISO());
    return result;
}

const updateMyOrg = (MyOrg: Org) => {
    return apiClient.putter(apiEndpoint, MyOrg)
        .then((result: Org) => {
            mutateMyOrg();
            return result;
    });
};

const useMyOrgAppSettings = (swrOptions = {}) => {
    return useGenericSWR(orgAppSettingsEndpoint, orgApiClient, { ...swrOptions as SwrOptions });
};

const mutateMyOrg = () => {
    orgApiClient.mutateFetchUrls();
};

export {
    myOrgValidationSchema,
    useMyOrgs,
    updateMyOrg,
    getMyOrgsDefaultItem,
    useMyTimezone,
    useMyNow,
    useMyOrgAppSettings,
    mutateMyOrg,
    
};