import React, { useState, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import { useTheme } from '@mui/material';
import { ChartInstanceOfMuiTheme, updateReadTimestampLocalDates, fillMissingHourData, fillMissingYearData } from './CustomerSummaryChartUtils';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useResponsive } from '../../lib/useResponsive';
import { createNewSeries } from './CustomerGenerationChartUtils';
// @ts-ignore
import { UcTouch } from '../common/UcTouch';
import CustomerGenerationLegend from './CustomerGenerationLegend';
import { UcLoading } from 'components/common/UcLoading';
import { CustomerGenerationChartTooltip } from './CustomerGenerationChartTooltip';
import { cleanKeysWithParentheses } from 'utils/helpers/general.helpers';
// @ts-ignore
import { MD5 } from 'object-hash';
import { UcProgress } from 'components/common/UcProgress';
// @ts-ignore
import { AxisCalc } from 'lib/AxisCalc';

interface CustomerGenerationChartProps {
    groupBy: string;
    swrReadings: any;
    yearlyData: any;
    startDate: Date;
    endDate: Date;
    uomDisplay: string;
    setYearlyData: any;
    progress: any;
}

export const CustomerGenerationChart = (props: CustomerGenerationChartProps) => {

    const { groupBy, swrReadings, endDate, startDate, yearlyData, uomDisplay, setYearlyData, progress} = props;

    const [columnsToShow, setColumnsToShow] = useState<number>(13);
    const [totalColumns, setTotalColumns] = useState<number>(24);
    const [startChartScroll, setStartChartScroll] = useState<number>(totalColumns - columnsToShow);
    const [endChartScroll, setEndChartScroll] = useState<number>(totalColumns - 1);
    const [series, setSeries] = useState<any[]>([]);
    const propsHash: string = MD5(JSON.stringify(props));
    const [currentPropHash, setCurrentPropHash] = useState(propsHash);
    const [usageMinDomain, setUsageMinDomain] = useState<number>(0);
    const [usageMaxDomain, setUsageMaxDomain] = useState<number>(200000);

    const { isMobile } = useResponsive();
    const { t } = useTranslation();
    const isDataLoaded = useRef(false);
    const endDatePlusOneHour = new Date(endDate);
    endDatePlusOneHour.setTime(endDatePlusOneHour.getTime() + (1 * 60 * 60 * 1000));
    const chartMinHeight: string = (isMobile ? "450px" : "500px");
    const theme = useTheme<ChartInstanceOfMuiTheme>();

    const defaultDayChartScrolls = (newSeries: any) => {
        if ((newSeries.length - columnsToShow) > 0 && isMobile) {
            let index = newSeries.map((e: any) => e.localTimestamp).indexOf("21:00:00");

            if (index > 0) {
                setStartChartScroll(Math.max(index - columnsToShow + 1, 0));
                setEndChartScroll(index);
            }
            else {
                setStartChartScroll(Math.max(newSeries.length - columnsToShow - 3, 0));
                setEndChartScroll(newSeries.length - 4);
            }
        }
        else {
            setStartChartScroll(0); // less columns with data than columns to display
            setEndChartScroll(newSeries.length - 1);
        }
    }

    const handleSetSeries = (newSeries: any[]) => {
        setSeries(newSeries);
    }

    if (currentPropHash !== propsHash) {
        setCurrentPropHash(propsHash);
        isDataLoaded.current = false;
    }

    if ((groupBy === "month" && !(yearlyData?.mainPeriod)) ||
        (groupBy !== "month" && swrReadings?.isLoading)) {
        isDataLoaded.current = false;
    }
    else if (((groupBy === "month" && yearlyData?.mainPeriod) ||
        (groupBy !== "month" && (swrReadings?.result && swrReadings?.result?.mainPeriod))) && !isDataLoaded.current) {
        let newMaxDomain: number = 0;
        let newMinDomain: number = 0;

        const mainPeriod = groupBy === "month" ? yearlyData.mainPeriod : swrReadings?.result.mainPeriod;
        updateReadTimestampLocalDates(mainPeriod.readings, groupBy);  

        let domains = { newMaxDomain: newMaxDomain, newMinDomain: newMinDomain };
        let newSeries: any = createNewSeries(mainPeriod.readings, groupBy, t, domains);

        newMaxDomain = domains.newMaxDomain;
        newMinDomain = domains.newMinDomain;

        if (groupBy === "none") {
            newSeries = fillMissingHourData(newSeries, startDate, endDatePlusOneHour, false, null);
        }
        else if (groupBy === "month") {
            newSeries = fillMissingYearData(newSeries, startDate, endDatePlusOneHour, false, null);
        }
        else {
            newSeries = Object.keys(newSeries)
                .sort((a, b) => a.localeCompare(b)) 
                .map((key: string) => {
                return newSeries[key];
            });
        }

        const axisCalc = new AxisCalc(newMinDomain, newMaxDomain);

        // only want to use negative numbers if we actuall have negative numbers
        if (newMinDomain < 0) {
            setUsageMinDomain(axisCalc.min_value);
        }
        else {
            setUsageMinDomain(0);
        }

        if (groupBy === "none") {
            defaultDayChartScrolls(newSeries);
        }
        else {
            setStartChartScroll(Math.max(newSeries.length - (columnsToShow), 0));
            setEndChartScroll(newSeries.length - 1);
        }

        setUsageMaxDomain(axisCalc.max_value);

        setTotalColumns(newSeries.length);
        handleSetSeries(newSeries);

        isDataLoaded.current = true;
    }

    const newItems = cleanKeysWithParentheses(series);

    React.useEffect(() => {
        switch (groupBy) {
            case "day":
                if (isMobile) {
                    setColumnsToShow(14);
                    setStartChartScroll(Math.max(totalColumns - (14), 0));
                }
                else {
                    setColumnsToShow(31);
                    setStartChartScroll(Math.max(totalColumns - (31), 0));
                }
                setYearlyData(null);
                break;
            case "none":
                if (isMobile) {
                    setColumnsToShow(13);
                    setStartChartScroll(Math.max(totalColumns - (13), 0));
                }
                else {
                    setColumnsToShow(24);
                    setStartChartScroll(Math.max(totalColumns - (24), 0));
                }
                setYearlyData(null);
                break;
            default:
                setColumnsToShow(13);
                setStartChartScroll(Math.max(totalColumns - (13), 0));
                break;
        };
    }, [totalColumns, groupBy, isMobile, setYearlyData]);

    return (
        <UcLoading isLoading={!isDataLoaded.current} hasFade={false} loadingRender={<UcProgress {...progress} />}>
            <div className="customerGenerationChart graphWrapper" style={{ width: '100%', height: '400px' }}>
                <UcTouch
                    style={{ height: "100%", width: "100%" }}
                    columnsToShow={columnsToShow}
                    totalColumns={totalColumns}
                    startX={startChartScroll}
                    onRangeChangeX={(newStart: any, newEnd: any) => {
                        setStartChartScroll(Math.max(newStart, 0));
                        setEndChartScroll(newEnd);
                        if (newItems) {
                            handleSetSeries([...newItems])
                        }
                    }}
                >
                    <ResponsiveContainer
                        width="100%"
                        height="100%"
                        minHeight={chartMinHeight}
                        className="recharts-container-scrollable">
                        <LineChart
                            data={newItems}
                            margin={{
                                top: (isMobile ? 5 : 20),
                                right: (isMobile ? 5 : 40),
                                bottom: 5,
                                left: (isMobile ? -15 : 40)
                            }}
                            // @ts-ignore
                            fillOpacity="0.7"
                        >
                            <CartesianGrid stroke={theme?.props?.Rechart?.cartesianGridColor} strokeDasharray="5 5" />
                            <XAxis
                                dataKey="name"
                                interval={isMobile ? 1 : 0}
                                angle={-45}
                                textAnchor="end"
                                tick={{ fill: theme?.props?.Rechart?.axisColor, fontSize: 12 }}
                            />
                            <YAxis
                                label={{
                                    value: isMobile ? "" : t('Electricity ({{uomDisplay}})', {uomDisplay: uomDisplay}),
                                    dx: -30,
                                    angle: -90,
                                    style: { fill: theme?.props?.Rechart?.axisColor } 
                                }}
                                tick={{ fill: theme?.props?.Rechart?.axisColor, fontSize: (isMobile ? '12px' : '16px') }}
                                allowDataOverflow={true}
                                axisLine={!isMobile}
                                domain={[usageMinDomain, usageMaxDomain]}
                            />
                            <Tooltip
                                content={
                                    <CustomerGenerationChartTooltip
                                        groupBy={groupBy}
                                        uomDisplay={uomDisplay}
                                    />
                                } 
                            />
                            <Legend content={<CustomerGenerationLegend />} />
                            <Line type="monotone" dataKey="totalUsage" stroke="#8884d8" activeDot={{ r: 8 }} isAnimationActive={false}/>
                            <Brush
                                updateId={startChartScroll}
                                startIndex={startChartScroll}
                                endIndex={endChartScroll}
                                dataKey="name"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </UcTouch>
            </div>
        </UcLoading>
    );
};