import { DateTime } from 'luxon';
import ReactMarkdown from 'react-markdown'
import { MD5 } from 'object-hash';
import { globalTranslator } from "lib/GlobalTranslator";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import remarkGfm from 'remark-gfm'
import removeMd from 'remove-markdown';

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

const t = globalTranslator;

export const dateOnlyFormat = "yyyy-MM-dd";
export const dateOnlyFormatPicker = "YYYY-MM-DD";  // MUI date picker's format is a bit different
export const fullDateFormat = "yyyy-MM-dd, hh:mm a";

export const ensureLuxonDate = (value, ignoreUtcConvert = false) => {
    if (value === undefined || value === null) {
        return null;
    }

    let localDate;
    if (value instanceof DateTime) {
        localDate = value;
    }
    else if (value instanceof Date) {
        localDate = DateTime.fromJSDate(value);
    }
    else if (typeof value === 'number') {
        localDate = DateTime.fromJSDate(new Date(value));
    }
    else {
        if (ignoreUtcConvert) {
            // assume value is already in local time
            localDate = DateTime.fromISO(value.replace("Z", "")).toJSDate();
            localDate = DateTime.fromJSDate(localDate);
        }
        else {
            localDate = DateTime.fromISO(value, { zone: 'utc' }).toJSDate();
            localDate = DateTime.fromJSDate(localDate);
        }
    }

    return localDate;
}

export const ensureLuxonDateTimezone = (value, timezone) => {
    if (value === undefined || value === null) {
        return null;
    }

    let localDate;
    if (value instanceof DateTime) {
        localDate = value;
    }
    else if (value instanceof Date) {
        localDate = DateTime.fromJSDate(value);
    }
    else if (typeof value === 'number') {
        localDate = DateTime.fromJSDate(new Date(value));
    }
    else {
        if (timezone) {
            localDate = DateTime.fromISO(value, { zone: timezone }).toJSDate();
            localDate = DateTime.fromJSDate(localDate);
        }
        else {
            localDate = DateTime.fromISO(value, { zone: 'utc' }).toJSDate();
            localDate = DateTime.fromJSDate(localDate);
        }
    }

    return localDate;
}

export const ensureLuxonDateOnly = (value, ignoreUtcConvert = false) => {
    let localDate = ensureLuxonDate(value, ignoreUtcConvert);

    let day = localDate?.day;
    let year = localDate?.year;
    let month = localDate?.month;
    let dateOnly = DateTime.local(year, month, day);

    return dateOnly;
}

export const ensureLuxonDateOnlyTimezone = (value, timezone) => {
    let localDate = ensureLuxonDate(value, true);

    let day = localDate?.day;
    let year = localDate?.year;
    let month = localDate?.month;
    let dateOnly = DateTime.fromObject({ year: year, month: month, day: day }, { zone: timezone });

    return dateOnly;
}

export const dateFormatterTimezone = (value, timezone, isShortFormat = false, nullResult = "") => {
    if (value === undefined || value === null) {
        return nullResult;
    }

    let localDate = null;
    if (timezone) {
        localDate = ensureLuxonDateTimezone(value, timezone);
    }
    else {
        localDate = ensureLuxonDate(value);
    }

    if (localDate > DateTime.utc(2092, 1, 1)) {
        return nullResult;
    }

    if (isShortFormat) {
        return localDate.toJSDate().toLocaleDateString("en-CA", // 2023-05-10 We can change this to a MyOrg setting
            {
                timeZone: timezone
            }).replaceAll('/', '-');

    } else {
        return localDate.toJSDate().toLocaleString("en-CA", // 2023-05-10, 12:00 AM We can change this to a MyOrg setting
            {
                timeZone: timezone,
                hour12: true,
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "numeric",
                minute: "numeric",
            }).replaceAll('/', '-').replaceAll('.', '').toUpperCase();
    }
}

// Accepts iso formatted date string or luxon Datetime and ignores Utc conversion
export const dateFormatterIgnoreUtc = (value, isShortFormat = false, nullResult = "") => {
    return dateFormatter(value, isShortFormat, nullResult, true);
}

// Accepts iso formatted date string or luxon Datetime
export const dateFormatter = (value, isShortFormat = false, nullResult = "", ignoreUtcConvert = false, timezone = null) => {
    if (value === undefined || value === null) {
        return nullResult;
    }

    let localDate = null;
    if (timezone) {
        localDate = ensureLuxonDateTimezone(value, timezone);
    }
    else {
        localDate = ensureLuxonDate(value, ignoreUtcConvert);
    }

    if (localDate > DateTime.utc(2092, 1, 1)) {
        return nullResult;
    }

    if (isShortFormat) {
        return localDate.toFormat(dateOnlyFormat);
    } else {
        return localDate.toFormat(fullDateFormat);
    }
}

export const stringDateFormatter = (value, displayFullDateTimeString = false, nullResult = "") => {
    let valueDate = new Date(value);
    var options = { year: 'numeric', month: 'long', day: 'numeric' };

    if (displayFullDateTimeString) {
        options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    }
    if (valueDate === undefined || valueDate === null) {
        return nullResult;
    }

    return valueDate.toLocaleDateString("en-US", options);
}

export const fixRateName = (rateName) => {
    const removeBrackets = (rateName.substring(0, rateName.indexOf('(')) || rateName);
    const removeTilde = (removeBrackets.substring(0, removeBrackets.indexOf('~')) || removeBrackets);
    return removeTilde;
}

const dataKeyTracker = [];
export const getDataKeyOriginal = (hash) => {

    return dataKeyTracker[hash] || hash;
}

export const fixDataKeyName = (rateName) => {
    const hash = MD5(rateName).substring(0, 10);
    if (!dataKeyTracker[hash]) {
        dataKeyTracker[hash] = fixRateName(rateName);
    }
    return hash;
}

export const dateRangeFormatterTimezone = (valueStart, valueEnd, timezone, isShortFormat = false) => {
    return dateFormatterTimezone(valueStart, timezone, isShortFormat) + " to " + dateFormatterTimezone(valueEnd, timezone, isShortFormat);
}

export const dateRangeFormatterIgnoreUtc = (valueStart, valueEnd, isShortFormat = false, nullResult = "", endDayOffset = -1, formatOverride = dateOnlyFormat) => {
    return dateRangeFormatter(valueStart, valueEnd, isShortFormat, nullResult, endDayOffset, true, formatOverride);
}

export const dateRangeFormatter = (valueStart, valueEnd, isShortFormat = false, nullResult = "", endDayOffset = -1, ignoreUtcConvert = false, formatOverride = dateOnlyFormat) => {
    let startFormatted = dateFormatter(valueStart, isShortFormat, nullResult, ignoreUtcConvert);
    valueEnd = ensureLuxonDate(valueEnd, ignoreUtcConvert);
    if (valueEnd != null) {
        valueEnd = valueEnd.plus({ days: endDayOffset });
    }
    let endFormatted = dateFormatter(valueEnd, isShortFormat, nullResult, ignoreUtcConvert);

    if (formatOverride !== "") {
        startFormatted = ensureLuxonDate(startFormatted, ignoreUtcConvert).toFormat(formatOverride);
        endFormatted = ensureLuxonDate(endFormatted, ignoreUtcConvert).toFormat(formatOverride);
    }

    return `${startFormatted} to ${endFormatted}`;
}

export const timezoneShortFormatter = (timezone) => {
    const dt = DateTime.now().setZone(timezone);
    if (dt.offsetNameShort) {
        return dt.offsetNameShort;
    }
    else {
        return timezone;
    }
}

export const dateRangeFormatterV2 = (startDate, endDate, timezone) => {
    const startDateTime = DateTime.fromJSDate(startDate, { zone: timezone });
    const endDateTime = DateTime.fromJSDate(endDate, { zone: timezone });

    const formattedStartDate = startDateTime.toFormat("yyyy-MM-dd, hh:mm a");
    const formattedEndDate = endDateTime.toFormat("yyyy-MM-dd, hh:mm a");

    const timezoneShortForm = timezoneShortFormatter(timezone);

    return `${formattedStartDate} to ${formattedEndDate} (${timezoneShortForm})`;
}

// Accepts iso formatted date string or luxon Datetime
export const monthFormatter = (value, nullResult = "") => {
    if (value === undefined || value === null) {
        return nullResult;
    }
    let localDate = ensureLuxonDate(value)

    return localDate.toFormat("MMM");
}

export const dayFormatter = (value, nullResult = "", ignoreUtcConvert = false) => {
    if (value === undefined || value === null) {
        return nullResult;
    }

    let localDate = ensureLuxonDate(value, ignoreUtcConvert)

    if (localDate > DateTime.utc(2292, 1, 1)) {
        return nullResult;
    }

    return localDate.toFormat("MMM-dd");

}

// Accepts iso formatted date string or luxon Datetime
export const hourFormatter = (value, nullResult = "", ignoreUtcConvert = false) => {
    if (value === undefined || value === null) {
        return nullResult;
    }
    let localDate = ensureLuxonDate(value, ignoreUtcConvert)
    return localDate.toFormat("h a");
}

export const timeAgoDateFormatter = (value, nullResult = "") => {
    if (value === undefined || value === null) {
        return nullResult;
    }
    return timeAgo.format(new Date(value), 'round-minute');
}

export const roundDateNearestHour = (value, nullResult = "") => {
    if (value === undefined || value === null) {
        return nullResult;
    }
    value.setHours(value.getHours() + Math.round(value.getMinutes() / 60));
    value.setMinutes(0, 0, 0);
    return value;
}

export const getDateFromMui = (value, nullResult = "") => {
    if (value === undefined || value === null) {
        return nullResult;
    }
    return (new Date(value));
}

export const getHourFromDate = (value, nullResult = "") => {
    if (value === undefined || value === null) {
        return nullResult;
    }
    return (new Date(value)).toLocaleTimeString('en', { hour: 'numeric', minute: 'numeric' });
}

// Rounds to 1 decimal place
export const kwhFormatter = (value) => {
    let result = Number.parseFloat(value);
    return (Math.round(result * 10) / 10).toFixed(1); //force it to one decimal place
}

// Rounds to 2 decimal place
export const currencyFormatter = (value, decimalPlaces = 2) => {
    if (value === undefined || value === null) {
        return '';
    }

    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value);
}

export const csvHeaderFormatter = (data, uom, excludedKeys = [], labelsToCustomize = []) => {
    let keys = Object.keys(data);
    let headers = keys.filter(k => excludedKeys.indexOf(k) === -1)
        .map((k) => {
            // If headers labels to map have been passed in - we use the matches as the new label text
            // Else we simply add spaces and capitalize each word in the given key
            if (labelsToCustomize.filter((o) => o.key === k).length > 0) {
                const label = labelsToCustomize
                    .filter((o) => o.key === k)
                    .map((o) => {
                        return o.hasUOM ? `${o.label} (${uom})` : o.label;
                    });

                return { label: label, key: k };
            }
            else {
                let keyWithSpaces = k.replace(/([a-z])([A-Z])/g, '$1 $2');
                let firstLetter = keyWithSpaces[0].toUpperCase();

                return { label: firstLetter + keyWithSpaces.slice(1, keyWithSpaces.length), key: k };
            }
        });

    return headers;
}

export const removeMarkdown = (value, nullResult = "") => {
    if (value === undefined || value === null) {
        return nullResult;
    }

    return removeMd(value, { useImgAltText: false });
}

// converts boolean value output to custom values
export const booleanFormatter = (value, trueValue = "Yes", falseValue = "No", nullResult = null) => {
    if (value === undefined || value === null) {
        if (nullResult !== null) {
            return nullResult;
        }
        else {
            return falseValue
        }
    }

    return value ? trueValue : falseValue;
}

// format account name to display name and address
export const accountNameFormatter = (value, nullResult = null) => {
    if (value === undefined || value === null) {
        return nullResult;
    }

    return value.accountName + (value.accountAddress !== undefined ? " - " + value.accountAddress : "");
}

export const formatUom = (uom) => {

    if (uom.toUpperCase() === "KWH") {
        return t("kWh");
    }
    else {
        return t(uom.toUpperCase());
    }
}

export const ticketIdFormatter = (value, nullResult = "") => {
    if (value === undefined || value === null) {
        return nullResult;
    }
    return (value.substring(0, 8));
}

export const getPrecipUnit = (tempUom) => {
    if (tempUom === "F") {
        return t("in.")
    }
    else {
        return t("mm")
    }
}

export const uomToCommodity = (uom) => {
    const mapping = {
        'KWH': 'ELECTRIC',
        'GAL': 'WATER',
        'GAS': 'GAS',
    };

    return mapping[uom];
};

export const commodityToUom = (commodity) => {
    const mapping = {
        'ELECTRIC': 'KWH',
        'WATER': 'GAL',
        'GAS': 'GAS',
    };

    return mapping[commodity];
};

export const consumptionDateFormatter = (date, type) => {
    if (date === undefined || date === null) {
        return "N/A"
    }
    else if (type === "hour") {
        let currentHour = new Date(date).toLocaleString('en-US', { hour: 'numeric', hour12: true });
        let nextHour = new Date(date);
        nextHour.setHours(nextHour.getHours() + 1)
        let nextHourString = nextHour.toLocaleString('en-US', { hour: 'numeric', hour12: true })
        return currentHour + " - " + nextHourString;
    }
    else if (type === "day") {
        return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
    else if (type === "month") {
        return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
    }
    else {
        return "N/A"
    }
}

// convert 24 hour number to string hour range, e.g. 19 -> '6 PM - 7 PM'
export const consumptionNumberHourFormatter = (hour) => {
    const convertTo12HourFormat = (hourNumber) => {

        let period = 'AM';
        if (hourNumber === 0) {
            hourNumber = 12;
        }
        else if (hourNumber >= 12) {
            period = 'PM';
            if (hourNumber > 12) {
                hourNumber -= 12;
            }
        }
        return `${hourNumber} ${period}`;
    };

    // This logic gets the previous hour in 24-hour format, wrapping around midnight (hour = 0)
    const start = convertTo12HourFormat((hour + 23) % 24);
    const end = convertTo12HourFormat(hour);

    return start + " - " + end;
}

const sanitizeUrl = (url) => {
    const fallbackImageSrc = '/missing-img.png';
    try {
        const parsedUrl = new URL(url, window.location.origin);
        const isLocal = parsedUrl.origin === window.location.origin;
        const isDataUrl = url.startsWith('data:');
        return isLocal || isDataUrl ? url : fallbackImageSrc;
    } catch {
        return fallbackImageSrc;
    }
}

const renderImg = ({ node, ...props }) => {
    return <img {...props} alt={node.alt} style={{ maxWidth: '100%' }} />;
};

const renderLink = ({ children, ...props }) => {
    return (
        <a {...props} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
};

// converts markdown string into markdown output
export const markdownFormatter = (value, nullResult = "") => {
    if (value === undefined || value === null) {
        return nullResult;
    }

    return <ReactMarkdown transformImageUri={sanitizeUrl} remarkPlugins={[remarkGfm]} components={{ a: renderLink, img: renderImg }}>
        {value}
    </ReactMarkdown>
}