// @ts-ignore
import useSWR, { useSWRConfig, ConfigInterface } from 'swr';
import { GenericSwr, SwrOptions, UrlsToMutate } from './useGenericSWR.interfaces';
// @ts-ignore
import { ApiClient } from "ApiClient";

const urlsToMutate: UrlsToMutate[] = [];
const allSwrUrls: Set<string> = new Set();
const prefixesToMutate: Set<string> = new Set();

const mutateUrls = (swrConfig: ConfigInterface) => {
    let urlToMutate: UrlsToMutate | undefined;
    while (urlsToMutate.length > 0) {
        urlToMutate = urlsToMutate.pop();
        if (urlToMutate) {
            let revalidate = urlToMutate.data === null;
            swrConfig.mutate(urlToMutate.url, urlToMutate.data, revalidate);
        }
    }

    for (const urlPrefix of prefixesToMutate)
    {
        for (const url of allSwrUrls) {
            if (url.startsWith(urlPrefix)) {
                swrConfig.mutate(url, null, true);
                allSwrUrls.delete(url);
            }
        }
        prefixesToMutate.delete(urlPrefix);
    }

}

// Any urlPrefix that is passed in will mutate the rest
// Be cautious about some names:  eg: "user" would mutate user*, users*, userRoles* ect.
const addMutateUrlPrefix = (urlPrefix: string) => {
    prefixesToMutate.add(urlPrefix);
}

const useMutateUrls = () => {
    const swrConfig = useSWRConfig();
    mutateUrls(swrConfig);
}

// dataClient must have a fetcher routine
const useGenericSWR = (url: string | null, dataClient: ApiClient, swrOptions: SwrOptions) => {
    const swrConfig = useSWRConfig();
    const additionalMessage = swrOptions.additionalMessage ?? "";
    const overrideMainMessage = swrOptions.overrideMainMessage ?? false;
    const doToast = swrOptions.doToast ?? true;

    dataClient.swrConfig = swrConfig;

    if (url) {
        allSwrUrls.add(url);
    }

    useMutateUrls();

    if (swrOptions.refreshInterval && swrOptions.refreshInterval > 0) {
        dataClient.cacheExirationMs = swrOptions.refreshInterval;
    }

    // TODO: pass translation into swrOptions (otherwise doToast doesn't work as expected)
    const translation = null;

    const fetchOptions = {
        signal: swrOptions.signal,
        ...swrOptions.fetchOptions 
    };

    const {
        data,
        error,
        isValidating,
        mutate,
    } = useSWR(url, (url) => dataClient.fetcher(url, additionalMessage, overrideMainMessage, translation, doToast, fetchOptions), { ...swrOptions, revalidateOnMount: true });


    if (dataClient.customDataHandler) {
        let customResult = dataClient.customDataHandler(data, error, isValidating);
        customResult.refetchResult = mutate;
        return customResult;
    }

    let result: GenericSwr = {
        result: data?.result || data,
        rowCount: data ? data.totalCount : 0,
        totalCount: data ? data.totalCount : 0,
        skip: data ? data.skip : 0,
        take: data ? data.take : 0,
        isLoading: !error && !data && (url !== ""),
        isError: !(!error),
        error: error,
        isValidating: isValidating,
        refetchResult: mutate
    }

    return result;
};

export {
    useGenericSWR,
    useMutateUrls,
    mutateUrls,
    addMutateUrlPrefix,
    urlsToMutate,
}
