import React, { useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { MyCostPredictionSummary } from './MyCostPredictionSummary';
import { useTranslation } from "react-i18next";
import { UcWidget } from '../common/UcWidget';
import { getAccountPopoverTitle, AccountContext } from '../../lib/AccountProvider';
import EnsureUserAndModule from 'components/auth/EnsureUserAndModule';
import { UcMeterSelectV1 } from '../common/UcMeterSelectV1';
import { useResponsive } from '../../lib/useResponsive';

export const MyCostPredictionPage = () => {
    const theme = useTheme();
    const { account } = useContext(AccountContext);
    const [currentBill, setCurrentBill] = React.useState(true);
    const [uom, setUom] = React.useState("KWH");
    const popoverTitle = getAccountPopoverTitle(account);
    const { isMobile, isTablet } = useResponsive();

    const { t } = useTranslation();

    const handleNext = () => {
        setCurrentBill(false);
    };

    const handleBack = () => {
        setCurrentBill(true);
    };

    const steps = useMemo(() => {
        return [
            {
                component: (
                    <MyCostPredictionSummary
                        key={currentBill}
                        currentBill={currentBill}
                        uom={uom}
                    />
                )
            }
        ];
    }, [currentBill, uom]);

    const actionItems = [
        {
            component: (
                <UcMeterSelectV1
                    account={account}
                    uom={uom}
                    onlyCommodity={true}
                    forcedWidth={(!isMobile && !isTablet) ? '50%' : '100%'}
                    onChange={async (event, newValue) => {
                        setUom(newValue?.uom);
                    }}
                />
            )
        },
    ]

    const title = t("Cost Prediction") + " - " + account?.accountName + " - " + account?.accountAddress

    return (
        <EnsureUserAndModule modules={['Cost']}>
            <UcWidget
                title={title}
                actionItems={actionItems}
                contentPadding='0 0 0 0'
                alignTitleLeft={true}
                popoverTitle={popoverTitle}
                >
            <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ width: '100%', height:'100%', p: 2 }}>
                {steps[0].component}
                </Box>
                <MobileStepper
                    steps={2}
                    position="static"
                    activeStep={currentBill ? 0 : 1}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={!currentBill}
                        >
                            {t("Next Bill")}
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            disabled={currentBill}>
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowLeft />
                            )}
                            {t("Current Bill")}
                        </Button>
                    }
                />
                </Box>
            </UcWidget>
        </EnsureUserAndModule>
    );
}