import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { PageContext } from "lib/PageProvider";
import { useResponsive } from "lib/useResponsive";
import { useTheme } from '@mui/material/styles';

export const UcTabStrip = (props) => {
    const [value, setValue] = React.useState(props.value || "default tag");
    const [pageState, pageDispatch] = useContext(PageContext);
    const { tabs, onChange, showInMenu } = props;
    const { isMobile } = useResponsive();
    const theme = useTheme();

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);
        onChange(newValue);
    }, [onChange]);

    useEffect(() => {
        if (showInMenu && (pageState.tabs !== tabs || value !== pageState.tabValue)) {
            pageDispatch({...pageState, tabs: tabs, onTabChange: handleChange, tabValue: value});
        }
    }, [pageState.header, pageState, pageDispatch, tabs, handleChange, showInMenu, value]);

    let showTabs = true;
    if (showInMenu && isMobile && !props.showTabsInMobile) {
        showTabs = false;
    }
    
    let indicatorColour = theme.palette.primary;
    if (theme.palette.mode === 'dark') {
        indicatorColour = theme.palette.primary.contrastText
    }

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: showTabs ? 'block' : 'none' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" 
                        //textColor={theme.palette.primary.contrastText} //doesn't actually load from json, just defaults color to white
                        TabIndicatorProps={{sx: {
                            background:indicatorColour,
                        }}}
                    >
                        {tabs.map((tab) => (
                            !tab.isHidden &&
                            <Tab key={tab.title} data-cy-id={"tab_list_button_"+tab.title}label={tab.tabRender || tab.title} value={tab.title} />
                        ))}
                    </TabList>
                </Box>
                {tabs.map((tab) => (
                    !tab.isHidden &&
                    <TabPanel key={tab.title} value={tab.title} sx={{padding: tab.contentPadding}}>{tab.contentRender}</TabPanel>
                ))}
            </TabContext>
        </Box>
    );
};

UcTabStrip.defaultProps = {
    tabs: [
        {
            title: "default tag",
            tabRender: <div>default tab</div>,
            contentRender: <div>someContent</div>
        }
    ],
    onChange: (event, newValue) => { console.log("onChange not implemented", event, newValue); },
};

UcTabStrip.propTypes = {
    tabs: PropTypes.array,
};