import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import { PageContext } from "../lib/PageProvider";
import { useTranslation } from "react-i18next";
import {
    OrgFaqSetting,
    useMyOrgFaqSettings,
} from "./user/MyOrgFaqSettingAPIEndpoints";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    Container,
    Snackbar,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { UcExpandMore } from "./common/UcExpandMore";
// @ts-ignore
import { routePaths } from "routes";
// @ts-ignore
import { useDomainNavigate } from "domain";
// @ts-ignore
import { useAuthUser } from "./auth/useAuthUser";
import { UcLoading } from "./common/UcLoading";
import { MyHelpRequestForm } from "./helpRequest/MyHelpRequestForm";
import LoadingButton from "@mui/lab/LoadingButton";
import { OrgAppSettingsContext } from "../lib/OrgAppSettingsProvider";
// @ts-ignore
import { mutateUrls } from "../lib/useGenericSWR";
import { useSWRConfig } from "swr";
import { saveMyHelpRequest } from "./helpRequest/MyHelpRequestAPIEndpoints";

export default function FaqPage() {
    const swrConfig = useSWRConfig();
    const { orgAppSettings } = React.useContext(OrgAppSettingsContext);
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const navigate = useDomainNavigate();
    const swrMyOrgFaqSettings = useMyOrgFaqSettings();
    const [pageState, pageDispatch] = useContext(PageContext);
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState("");

    let formIteration = 0;
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const [saving, setSaving] = useState(false);

    let itemToEdit = {
        formIteration: "new" + formIteration,
        id: 0,
        // @ts-ignore
        cepOrgId: orgAppSettings?.result ? parseInt(orgAppSettings?.result.cepOrgId) : -1,
        name: "",
        accountNumber: "",
        email: authUser?.username ?? "",
        subject: "",
        question: ""
    }

    const modules = orgAppSettings?.result?.modules?.split?.(",") ?? [];
    const costModuleEnabled = modules.includes("HelpRequest");

    // @ts-ignore
    const showHelpRequest = costModuleEnabled && orgAppSettings?.result?.supportEmail && orgAppSettings?.result?.supportEmail.trim() !== '';

    if (
        !swrMyOrgFaqSettings.isLoading &&
        swrMyOrgFaqSettings.result?.length <= 0
    ) {
        navigate(routePaths.homePage.path);
    }

    const handleExpandClick = (index: any) => {
        if (selectedIndex === index) {
            setSelectedIndex("");
        } else {
            setSelectedIndex(index);
        }
    };

    const handleGoBackClick = () => {
        navigate(routePaths.landingPage.path);
    };


    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };


    const handleCloseSnackbar = (reason: any) => {
        if (reason === "clickaway")
            return;

        setOpenSnackbar(false);
    };

    const handleCloseErrorSnackbar = (reason: any) => {
        if (reason === "clickaway")
            return;

        setOpenErrorSnackbar(false);
    };

    useEffect(() => {
        pageDispatch({
            header: "FAQ",
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => { },
            tabValue: "",
        });
    }, [pageState.header, pageDispatch]);

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <UcLoading isLoading={swrMyOrgFaqSettings.isLoading} hasFade={false}>
            <Container maxWidth={authUser.isLoggedIn ? false : "lg"}>
                {!authUser.isLoggedIn && (
                    <Button
                        variant="text"
                        color={"primary"}
                        startIcon={<KeyboardArrowLeftIcon />}
                        sx={{ mb: 3 }}
                        onClick={handleGoBackClick}
                    >
                        {t("Back")}
                    </Button>
                )}
                <Typography
                    variant="h3"
                    sx={{ fontWeight: "lighter" }}
                    gutterBottom
                >
                    {t("Frequently Asked Questions")}
                </Typography>
                {swrMyOrgFaqSettings.result?.map(
                    (item: OrgFaqSetting, index: any) => {
                        return (
                            <Card
                                sx={{ mt: 1 }}
                                elevation={3}
                                key={`entry_${item.id}`}
                            >
                                <CardHeader
                                    action={
                                        <UcExpandMore
                                            expanded={index === selectedIndex}
                                            onClick={() => handleExpandClick(index)}
                                            aria-expanded={index}
                                            aria-label="show more"
                                        >
                                            <ExpandMoreIcon />
                                        </UcExpandMore>
                                    }
                                    title={item.question}
                                />
                                <Collapse
                                    in={index === selectedIndex}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <CardContent>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                            paragraph
                                        >
                                            {item.answer}
                                        </Typography>
                                    </CardContent>
                                </Collapse>
                            </Card>
                        );
                    }
                )}
                {!authUser.isLoggedIn && showHelpRequest ?
                    <>
                        <Card
                            sx={{ mt: 1 }}
                            elevation={3}
                            key={`entry_helpRequest`}
                        >
                        <CardHeader
                            action={
                                <UcExpandMore
                                    expanded={"999" as any === selectedIndex}
                                    onClick={() => handleExpandClick("999")}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </UcExpandMore>
                            }
                            title={"Need Help?"}
                        />
                        <Collapse
                            in={"999" === selectedIndex}
                            timeout="auto"
                            unmountOnExit
                        >
                            <CardContent>
                                <Typography
                                    variant="body1"
                                    color="text.secondary"
                                    paragraph
                                >
                                    {t('If you have any questions regarding your utility services or about your account please submit them below.')}
                                    <MyHelpRequestForm
                                        itemToEdit={itemToEdit}
                                        dialogSubmit={dialogSubmit}
                                        onSubmit={async (values: any) => {
                                            setSaving(true);
                                            try {
                                                const data = await saveMyHelpRequest(values);
                                                if (data?.id > 0)
                                                    setOpenSnackbar(true);
                                                else
                                                    setOpenErrorSnackbar(true);
                                            } finally {
                                                setSaving(false);
                                                setReMutateUrls(true);
                                            }
                                        }}
                                        onError={(error: any) => {
                                            setSaving(false);
                                        }}
                                        disabled={false}
                                    />
                                    <LoadingButton
                                        sx={{ margin: '15px' }}
                                        loading={saving}
                                        onClick={handleSave}
                                        variant="contained"
                                        title={t("Submit")}
                                    >
                                        {t('Submit')}
                                    </LoadingButton>
                                </Typography>
                            </CardContent>
                        </Collapse>
                    </Card>
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={3000}
                            onClose={handleCloseSnackbar}
                        >
                            <Alert
                                onClose={handleCloseSnackbar}
                                severity="success"
                                variant="filled"
                                sx={{ width: "100%", color: '#fff' }}
                            >
                                {t("Request sent!")}
                            </Alert>
                        </Snackbar>
                        <Snackbar
                            open={openErrorSnackbar}
                            autoHideDuration={3000}
                            onClose={handleCloseErrorSnackbar}
                        >
                            <Alert
                                onClose={handleCloseErrorSnackbar}
                                severity="error"
                                variant="filled"
                                sx={{ width: "100%", color: '#fff' }}
                            >
                                {t("An unexpected error occurred. Please try again later.")}
                            </Alert>
                        </Snackbar>
                    </>
                    : <></>
                }
            </Container>
        </UcLoading>
    );
}
