export const addWeatherData = (chartDataPoints: any, weatherChartData: any, groupBy: string) => {
    if (!chartDataPoints || !weatherChartData?.result) {
        return chartDataPoints;
    }

    if (groupBy === "none") {
        let weatherData = weatherChartData?.result?.days;

        chartDataPoints.forEach((chartDataPoint: any) => {
            const chartDate = new Date(chartDataPoint.localTimestamp);

            const matchedDay = weatherData.find((weatherItem: any) => {
                const weatherDate = new Date(weatherItem.dateTime);
                return (
                    weatherDate.getFullYear() === chartDate.getFullYear() &&
                    weatherDate.getMonth() === chartDate.getMonth() &&
                    weatherDate.getDate() === chartDate.getDate()
                );
            });

            if (!matchedDay) return;

            const chartHour = chartDate.toTimeString().split(" ")[0]; // get "hh:mm:ss" for hour search
            const matchedHour = matchedDay.hours?.find((hourItem: any) => hourItem.dateTime === chartHour);

            if (matchedHour) {
                // select which weatherData properties we want
                const { temp, precip } = matchedHour;
                Object.assign(chartDataPoint, { temp, precip });
            }
        });
    }
    else if (groupBy === "day") {
        let weatherData = weatherChartData?.result?.days;

        chartDataPoints.forEach((chartDataPoint: any) => {
            const chartDate = new Date(chartDataPoint.localTimestamp);

            const matchedDay = weatherData.find((weatherItem: any) => {
                const weatherDate = new Date(weatherItem.dateTime);
                return (
                    weatherDate.getFullYear() === chartDate.getFullYear() &&
                    weatherDate.getMonth() === chartDate.getMonth() &&
                    weatherDate.getDate() === chartDate.getDate()
                );
            });

            if (!matchedDay) return;

            if (matchedDay) {
                // select which weatherData properties we want
                const { temp, tempMax: maxTemp, tempMin: minTemp, precip } = matchedDay;
                Object.assign(chartDataPoint, { temp, maxTemp, minTemp, precip });
            }
        });
    }
    else if (groupBy === "month") {
        let weatherData = weatherChartData?.result;

        chartDataPoints.forEach((chartDataPoint: any) => {
            const chartDate = new Date(chartDataPoint.localTimestamp);

            const matchedYear = weatherData.find((weatherItem: any) => {
                const weatherYear = weatherItem.location.year;

                return (
                    weatherYear === chartDate.getFullYear()
                );
            });
            if (!matchedYear) return;

            const matchedMonth = matchedYear.location.values.find((month: any) => {
                const weatherDate = new Date(month.datetime); // need to use UTC month because these datetimes are first day of the month UTC

                return (
                    weatherDate.getUTCMonth() === chartDate.getMonth()
                );
            })
            if (!matchedMonth) return;

            if (matchedMonth) {
                // select which weatherData properties we want
                Object.assign(chartDataPoint, matchedMonth);
            }
        });
    }

    return chartDataPoints;
}

export default addWeatherData;