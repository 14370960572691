import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useOrgModule } from './OrgModuleApiEndpoints';
// @ts-ignore
import { UcLoading } from 'components/common/UcLoading';
import { Alert, Grid, Switch, IconButton, Popover, Box, Card, CardContent} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLayoutEffect, useState } from 'react';
import { mutateUrls } from 'lib/useGenericSWR';
import { useSWRConfig } from 'swr';
import InfoIcon from '@mui/icons-material/Info';
import EnsureUserAndModule from 'components/auth/EnsureUserAndModule';
// @ts-ignore
import { useEnums } from 'components/auth/PermissionApiEndpoint';

export const OrgModuleGrid = (props: any) => {
    const { t } = useTranslation();
    const swrOrgModule = useOrgModule();
    const isLoading = swrOrgModule.isLoading;

    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();

    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopover, setOpenPopover] = useState(null);

    const moduleEnums = useEnums("Module");

    const handleClick = (moduleName: any) => (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenPopover(moduleName);
      };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenPopover(null);
    };
    
    const handleChange = (moduleName: string) => {
        const moduleToChange: OrgModule | undefined = findModule(moduleName);

        if(moduleToChange) {
            props.onEditItem(moduleToChange);
        }
        else {
            props.onEditItem({moduleName: moduleName});
        }
        
        setReMutateUrls(true);
    };

    const findModule = (moduleName: string): OrgModule | undefined => {
       return swrOrgModule?.result
            ?.find((orgModule: OrgModule) => orgModule.moduleName === moduleName);
    };

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);
    
    const moduleAttributes: { [key: string]: { description?: string, disabled?: boolean } } = {
        Billing: {
            description: t(`Enabling will:
                \u2022 Rearrange the dashboard and show the Billing Summary tile
                \u2022 Show the Billing Options tile
                \u2022 Show the Billing side menu icon`)
        },
        Cost: {
            description: t(`Enabling allows residential users to view:
                \u2022 cost toggle on the My Usage page
                \u2022 cost toggle on the profile Preferences page
                \u2022 cost Notification Set Up
                \u2022 Cost Prediction page`)
        },
        OutageManual: {
            description: t(`Enabling will give users access to view:
                \u2022 Outage map menu icon
                \u2022 Outage map page
                \u2022 Outage send function under Admin Users
                \u2022 Outage page in the Admin`)
        },
        RateClassComparison: {
            description: t(`Enabling Rate Comparison will give users access to:
                \u2022 Rate Comparisons side menu icon
                \u2022 Rate comparison page
                \u2022 Change Rate Req in the Admin`)
        },
        General: { disabled: true },
        ModuleSetupPage: { disabled: true }
    };
    return (
        <EnsureUserAndModule permissions={["OrgModule:Read"]} modules={['ModuleSetupPage']}>
            <UcLoading isLoading={isLoading} hasFade={false}>
                <h1 className="pageHeader">{t("Module Setup")}</h1>
                <Box>
                    <Grid item xs={12} sm={12} md={6} sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography
                                                sx={{ fontSize: 14 }}
                                                color="text.secondary"
                                            >
                                            {t("Set up the below toggles to enable or disable modules for this organization. Paymentus modules are excluded and it's setup can be accessed on the Payments page.")}
                                        </Typography>
                                        {swrOrgModule.isError && (
                                            <Alert severity="error" sx={{ mb: 2 }}>
                                                {t('Error retrieving Modules')}
                                            </Alert>
                                        )}
                                        <Grid container spacing={4} padding="1rem" direction="column" sx={{mt: 1}}  wrap="nowrap">
                                            {moduleEnums?.map((module: any) => {
                                                const moduleToCheck = findModule(module.id);
                                                const attributes = moduleAttributes[module.id];

                                                return (
                                                    <Grid container direction="row" wrap="nowrap" key={module.id}>
                                                        <Grid item>
                                                            <Switch
                                                                onChange={() => handleChange(module.id)}
                                                                checked={moduleToCheck !== undefined}
                                                                disabled={attributes?.disabled}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h6" gutterBottom>{t(module.display)}</Typography>
                                                        </Grid>
                                                        {attributes?.description && (
                                                            <Grid item>
                                                                <IconButton color="primary" onClick={handleClick(module.id)} aria-label={`${module.id} info`} size='small' sx={{ p: 0, ml: 2, mt: 0.5 }}>
                                                                    <InfoIcon sx={{ fontSize: 20 }} />
                                                                </IconButton>
                                                                <Popover
                                                                    id={`${module.id} info popover`}
                                                                    open={openPopover === module.id}
                                                                    anchorEl={anchorEl}
                                                                    onClose={handleClose}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{ p: 2 }}>
                                                                        <Typography variant='body2' component="pre">
                                                                            {t(attributes?.description)}
                                                                        </Typography>
                                                                    </Box>
                                                                </Popover>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </UcLoading>
        </EnsureUserAndModule>
    );
}

