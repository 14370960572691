import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { useLocation } from "react-router-dom";
// @ts-ignore
import { DateTime } from 'luxon';
// @ts-ignore
import { EnsureAccount } from 'components/customer/accounts/EnsureAccount';
import MyUsageChart from "./MyUsageChart";
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
import useAccount from '../../lib/useAccount';
import { getAccountPopoverTitle } from '../../lib/AccountProvider';
import { useTranslation } from 'react-i18next';
import { UcWidget } from '../common/UcWidget';
import useMyUsageActions from './MyUsageActions';
import { USAGE_FILTER_TYPES, USAGE_HEADERS } from './MyUsage.constants';
import { UsageHelpers } from './MyUsage.helpers';
// @ts-ignore
import { dateRangeFormatterV2, commodityToUom } from '../../lib/Formatters';
import MyUsageFilterDialog from './MyUsageFilterDialog';
import { useMyUsageChartData } from './MyUsageApi';
import useOrgAppSettings from '../../lib/useOrgAppSettings';
import { UcSummaryTable } from '../common/UcSummaryTable';
import { useMyWeatherChartData } from './MyWeatherChartApi';
import { addWeatherData } from './MyUsagePageWeatherData';
import { UcLoading } from '../common/UcLoading';
import { UcProgress } from '../common/UcProgress';
import { useMyOrgs } from "../customer/orgs/MyOrgAPIEndpoints";
// @ts-ignore
import { useReadingsGB } from '../readings/ReadingAPIEndpoint';
// @ts-ignore
import { saveAs } from 'file-saver';
import { UcProgressWrapper } from './UcProgressWrapper';
import { PageContext, PageState, PageAction } from '../../lib/PageProvider';
import { Alert } from '@mui/material';
// @ts-ignore
import { useHasModule } from "components/auth/PermissionApiEndpoint";

function MyUsagePage() {
    // Hooks
    const { t } = useTranslation();
    const { account } = useAccount();
    const timezone = useMyTimezone();
    const location = useLocation();
    const popoverTitle = getAccountPopoverTitle(account);
    const { orgAppSettings } = useOrgAppSettings();
    const swrMyOrg = useMyOrgs();
    const hasCostModule = useHasModule("Cost");

    const yesterday = useMemo(() => DateTime.now().minus({ days: 1 }), []);

    // States
    const [chartHeader, setChartHeader] = useState(USAGE_HEADERS.DAY);
    const [chartType, setChartType] = useState(USAGE_FILTER_TYPES.DAY);
    const [uom, setUom] = useState(location.state?.locUom || 'KWH');

    const [yearStart, setYearStart] = useState(UsageHelpers.createDateFromObject(yesterday.year, yesterday.month, 1, timezone, 'yearStart'));
    const [yearEnd, setYearEnd] = useState(UsageHelpers.createDateFromObject(yesterday.year, yesterday.month, yesterday.day, timezone, 'yearEnd'));
    const [monthStart, setMonthStart] = useState(UsageHelpers.createDateFromObject(yesterday.year, yesterday.month, yesterday.day, timezone, 'monthStart'));
    const [monthEnd, setMonthEnd] = useState(UsageHelpers.createDateFromObject(yesterday.year, yesterday.month, yesterday.day, timezone, 'monthEnd'));
    const [hourStart, setHourStart] = useState(UsageHelpers.createDateFromObject(yesterday.year, yesterday.month, yesterday.day, timezone, 'hourStart', 0));
    const [hourEnd, setHourEnd] = useState(UsageHelpers.createDateFromObject(yesterday.year, yesterday.month, yesterday.day, timezone, 'hourEnd'));

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [pageState, pageDispatch] = useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);
    const isInitialMount = useRef(true);

    useEffect(() => { // Making sure we use proper timezone on first render
        if ((isInitialMount.current || timezone) && (chartType === USAGE_FILTER_TYPES.DAY)) { 
            const timezoneStartDate = UsageHelpers.createDateFromObject(yesterday.year, yesterday.month, yesterday.day, timezone, 'hourStart', 0);
            const timezoneEndDate = UsageHelpers.createDateFromObject(yesterday.year, yesterday.month, yesterday.day, timezone, 'hourEnd');

            setStartDate(timezoneStartDate);
            setEndDate(timezoneEndDate);
            setHourStart(timezoneStartDate);
            setHourEnd(timezoneEndDate);

            isInitialMount.current = false;
        }
    }, [timezone, yesterday, chartType]);

    const [groupBy, setGroupBy] = useState("none");
    const [progress, setProgress] = useState({ current: 0, total: 100 });
    const [serialNo, setSerialNo] = useState<string | null>("none");
    const [csvDataToDownload, setCsvDataToDownload] = useState([]);
    const [isDownloadReady, setIsDownloadReady] = useState<boolean>(false);
    const [meterOptionsLoaded, setMeterOptionsLoaded] = useState<boolean>(false);

    let uomDisplay = orgAppSettings?.result?.uomMappings[uom.toUpperCase()]?.safeUomDisplay;

    // FOR CHART ONLY
    const [geoCoordinates, setGeoCoordinates] = useState(UsageHelpers.getGeoCoordinates(account, uom) || []);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    const accountIdRef = React.useRef(account?.id);

    const usageChartData = useMyUsageChartData(accountIdRef.current, groupBy, uom, startDate, endDate, serialNo);

    useEffect(() => {
        accountIdRef.current = account?.id; // Used to fix the extra api request when account updates too quickly
    }, [account?.id]);

    const weatherChartData = useMyWeatherChartData(startDate, endDate, groupBy, geoCoordinates);
    const swrGBReadings = useReadingsGB(startDate, endDate, groupBy, uom, account?.id, isDownloadReady);

    let chartDataPoints = usageChartData?.result?.dataPoints;
    chartDataPoints = addWeatherData(chartDataPoints, weatherChartData, groupBy);

    const onChangeOpenFilterDialog = (value: boolean) => {
        setOpenFilterDialog(value);
    }

    useEffect(() => {
        if (chartDataPoints?.length > 0) {
            setCsvDataToDownload(chartDataPoints);
        }
    }, [chartDataPoints])

    let orgTempUnit: string | null = null;
    let precipUnit: string | null = null;
    if (!swrMyOrg.isLoading && swrMyOrg.result?.length > 0) {
        orgTempUnit = swrMyOrg.result[0].temperatureUnit;
        precipUnit = (orgTempUnit === "F") ? t("in.") : t("mm");
    }

    const { actionItems, getFileName, meterOptions } = useMyUsageActions({
        startDate,
        endDate,
        uom,
        csvDataToDownload,
        setIsDownloadReady,
        orgTempUnit,
        hasCostModule,
        parentHandleOnChangeChartType: (value: string) => {
            switch (value) {
                case USAGE_FILTER_TYPES.YEAR:
                    setChartHeader(USAGE_HEADERS.YEAR);
                    setStartDate(yearStart);
                    setEndDate(yearEnd);
                    setGroupBy("month");

                    break;
                case USAGE_FILTER_TYPES.MONTH:
                    setChartHeader(USAGE_HEADERS.MONTH);
                    setStartDate(monthStart);
                    setEndDate(monthEnd);
                    setGroupBy("day");

                    break;
                case USAGE_FILTER_TYPES.DAY:
                    setChartHeader(USAGE_HEADERS.DAY);
                    setStartDate(hourStart);
                    setEndDate(hourEnd);
                    setGroupBy("none");

                    break;
            }

            setChartType(value);
        },
        parentHandleOnChangeSelectedMeter: (option: any) => {
            setSerialNo(option.serialNo);
            setUom(commodityToUom(option.meterCommodity));
            setGeoCoordinates(UsageHelpers.getGeoCoordinates(account, commodityToUom(option.meterCommodity)));
        },
        parentHandleOpenFilterDialog: () => setOpenFilterDialog(true)
    });

    if (isDownloadReady && swrGBReadings.result) {
        let xmlResult = swrGBReadings.result;
        let blob = new Blob([xmlResult], { type: "text/plain;charset=utf-8" });
        saveAs(blob, getFileName("xml"));
        setIsDownloadReady(false);
    }

    let defaultValues = {
        accountId: account?.id ?? 0,
        serialNo: "",
        uom: "KWH",
        uomName: "KWH",
        startDate: startDate,
        endDate: endDate,
        endingOn: yesterday
    }

    useEffect(() => {
        pageDispatch({
            header: t(""),
            actionItems: [] as any[],
            tabs: [],
            onTabChange: () => { },
            tabValue: ''
        });
    }, [pageState.header, pageDispatch, t]);

    useEffect(() => {
        if (account?.id && uom && startDate && endDate) {
            setMeterOptionsLoaded(true);
        }
    }, [account, uom, startDate, endDate]);

    return (
        <EnsureAccount>
            <MyUsageFilterDialog
                chartType={chartType}
                defaultValues={defaultValues}
                uom={uom}
                startDate={startDate}
                endDate={endDate}
                open={openFilterDialog}
                onChangeOpen={onChangeOpenFilterDialog}
                onSubmit={(values: any) => {
                    if (chartType === USAGE_FILTER_TYPES.DAY) {
                        setHourStart(values.startDate);
                        setHourEnd(values.endDate);
                    }
                    else if (chartType === USAGE_FILTER_TYPES.YEAR) {
                        setYearStart(values.startDate);
                        setYearEnd(values.endDate);
                    }
                    else if (chartType === USAGE_FILTER_TYPES.MONTH) {
                        setMonthStart(values.startDate);
                        setMonthEnd(values.endDate);
                    }

                    setStartDate(values.startDate);
                    setEndDate(values.endDate);
                }}
            />
            <UcWidget
                title={chartHeader + " - " + account?.accountAddress}
                subTitle={dateRangeFormatterV2(startDate, endDate, timezone)}
                popoverTitle={popoverTitle}
                actionItems={actionItems}
                contentPadding={'0 0 0 0'}
                alignTitleLeft={true}
                height={"100%"}
            >
                {meterOptionsLoaded ? (
                    meterOptions.length > 0 ? (
                        <>
                            <UcProgressWrapper
                                data={usageChartData}
                                selectedMeter={serialNo}
                                groupBy={groupBy}
                                readyForData={true}
                                onProgress={(progressLocal: any) => {
                                    if (progressLocal.current !== progress.current) {
                                        setProgress({ ...progressLocal });
                                    }
                                }}
                            />
                            <UcLoading hasError={false} hasFade={false} isLoading={usageChartData.isLoading} loadingRender={<UcProgress {...progress} />} >
                                <MyUsageChart
                                    data={usageChartData}
                                    dataPoints={chartDataPoints}
                                    uomDisplay={uomDisplay}
                                    uom={uom}
                                    groupBy={groupBy}
                                    orgTempUnit={orgTempUnit}
                                    precipUnit={precipUnit}
                                    hasCostModule={hasCostModule}
                                />
                            </UcLoading>
                        </>
                    ) : (
                            <Alert severity="warning" sx={{ mx: 5, mt: 10 }}>{t("No meter options are available to display data.")}</Alert>
                    )
                ) : (
                    <UcLoading hasError={false} hasFade={false} isLoading={true} loadingRender={<UcProgress current={0} total={100} />} />
                )}
            </UcWidget>
            <br />
            <UcSummaryTable
                rateCategories={usageChartData?.result?.rateDescriptions}
                uom={orgAppSettings?.result?.uomMappings[uom]?.safeUomDisplay}
            />
        </EnsureAccount>
    );
}

export default MyUsagePage;