import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import type { TooltipProps } from 'recharts';
import {
    ValueType,
    NameType
} from 'recharts/types/component/DefaultTooltipContent';
//@ts-ignore
import { dayFormatter } from './Formatters';

interface RateClassComparisonTooltipInterface extends TooltipProps <ValueType, NameType> {
    groupBy: string;
}

export const RateClassComparisonTooltip = ({ active, payload, groupBy }: RateClassComparisonTooltipInterface) => {
    const { t } = useTranslation();
    let mainPeriodPayload = null;
    let comparePeriodPayload = null;

    if (payload && payload.length >= 2) {
        mainPeriodPayload = payload[0]?.payload;
        comparePeriodPayload = payload[1]?.payload;
    }

    let label = "";
    let prevHour: Date | string = "";
    if (groupBy === "none" && payload) { 
        prevHour = new Date("2023-05-05 " + payload[0]?.payload.localTimestamp); // date itself doesnt matter
        prevHour.setHours(prevHour.getHours() - 1);
        prevHour = prevHour.toLocaleString('en-US', { hour: 'numeric', hour12: true })

        label = prevHour + " - " + payload[0]?.payload.name; // Display an hour time range as label
    }
    if (groupBy === "month" && payload) {
        label = dayFormatter(payload[0]?.payload.billingPeriodStart, "", true) + " to " + dayFormatter(payload[0]?.payload.billingPeriodEnd);
    }

    if (active && payload && payload.length) {
        return (
            <List className='rateClassComparisonTooltip'
                sx={{
                    width: '100%',
                    minWidth: '250px',
                    bgcolor: 'background.paper',
                    border: '1px solid #cccccc',
                    borderRadius: '5px',
                    padding: '5px'
                }}
                component="nav"
            >
                <ListItem>
                    <Typography gutterBottom variant="h6">
                        {label || mainPeriodPayload.name}
                    </Typography>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        sx={{ color: payload[0]?.color }}
                        primaryTypographyProps={{ fontSize: '15px' }}
                        primary={t("Current Rate Cost")}
                        secondaryTypographyProps={{ fontSize: '13px' }}
                        secondary={"$" + mainPeriodPayload.Cost_mainPeriod?.toFixed(2)}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        sx={{ color: payload[1]?.color }}
                        primaryTypographyProps={{ fontSize: '15px' }}
                        primary={t("Compared Rate Cost")}
                        secondaryTypographyProps={{ fontSize: '13px' }}
                        secondary={"$" + comparePeriodPayload.Cost_comparePeriod?.toFixed(2)}
                    />
                </ListItem>           
            </List>
        )
    }
    return null;
};