import { red, blue, green, orange, pink, brown, deepPurple, teal, purple } from '@mui/material/colors';

export const COLOR_PICKER_OPTIONS = [
    green[500],
    orange[500],
    red[500],
    blue[500],
    pink[300],
    deepPurple[900],
    brown[500],
    green[900],
    blue[900],
    pink[800],
    teal[500],
    purple[400]
];

export const READING_DIRECTIONS = {
    DELIVERED: 'DELIVERED',
    RECEIVED: 'RECEIVED'
}