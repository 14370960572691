import { useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
//@ts-ignore
import { Typography } from "components/common/tsOverrides/Typography";
import {
    Switch,
    FormControlLabel,
    Grid,
    Box,
    Card,
    CardContent,
    TextField
} from "@mui/material";
import EnsureUserAndModule from 'components/auth/EnsureUserAndModule';
import { useOrgModule, deleteOrgModule, insertOrgModule } from '../OrgModules/OrgModuleApiEndpoints';
import { mutateUrls } from 'lib/useGenericSWR';
import { useSWRConfig } from 'swr';
import { UcLoading } from 'components/common/UcLoading';
import { Link } from '@mui/material';

export const PaymentusListPage = () => {
    const { t } = useTranslation();
    const swrOrgModule = useOrgModule();
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const isLoading = swrOrgModule.isLoading;
    const showPaymentusConfig = false;

    const findModule = (moduleName: string) => {
        return swrOrgModule?.result?.find((orgModule: OrgModule) => orgModule.moduleName === moduleName);
    };

    const handleChangeModule = async (moduleName: string) => {
        try {
            const moduleToDelete = swrOrgModule?.result?.find((orgModule: OrgModule) => orgModule.moduleName === moduleName);

            if (moduleToDelete) {
                await deleteOrgModule(moduleToDelete);
            }
            else {
                await insertOrgModule({ moduleName: moduleName });
            }
        }
        finally {
            setReMutateUrls(true);
        }
    };

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    return (
        <EnsureUserAndModule permissions={["Org:Modify"]} modules={['Billing']}>
            <UcLoading isLoading={isLoading} hasFade={false}>
                <Box>
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography
                                            sx={{ fontSize: 14, mt: 2, mb: 2 }}
                                            color="text.secondary"
                                        >
                                            {t("Set up the below toggles for user access to Paymentus features")}
                                        </Typography>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControlLabel
                                                label={
                                                    <Typography title={t("Enable One Time Payments")}>
                                                        {t("Enable One Time Payments")}
                                                    </Typography>
                                                }
                                                control={
                                                    <Switch
                                                        checked={findModule("PaymentusOneTimePayment") !== undefined}
                                                        onChange={() => handleChangeModule("PaymentusOneTimePayment")}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControlLabel
                                                label={
                                                    <Typography title={t("Enable AutoPay")}>
                                                        {t("Enable AutoPay")}
                                                    </Typography>
                                                }
                                                control={
                                                    <Switch
                                                        checked={findModule("PaymentusAutoPay") !== undefined}
                                                        onChange={() => handleChangeModule("PaymentusAutoPay")}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControlLabel
                                                label={
                                                    <Typography title={t("Enable My Wallet")}>
                                                        {t("Enable My Wallet")}
                                                    </Typography>
                                                }
                                                control={
                                                    <Switch
                                                        checked={findModule("PaymentusWallet") !== undefined}
                                                        onChange={() => handleChangeModule("PaymentusWallet")}
                                                        inputProps={{
                                                            "aria-label": "controlled",
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Typography
                                            sx={{ fontSize: 14, mt: 3, mb: 1 }}
                                            color="text.secondary"
                                        >
                                            {t("Documentation can be found by clicking ")}
                                            <Link
                                                href="https://developer.paymentus.io/home/Guide/Customer-Portal-Integration"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t("here.")}
                                            </Link>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {showPaymentusConfig && (
                                <Grid item xs={12} sm={12} md={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography
                                                sx={{ fontSize: 14, mt: 2, mb: 2 }}
                                                color="text.secondary"
                                            >
                                                {t("Below are the current Paymentus configurations")}
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        disabled
                                                        size="small"
                                                        label="Domain"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        disabled
                                                        size="small"
                                                        label="TLA"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </UcLoading>
        </EnsureUserAndModule>
    );
}