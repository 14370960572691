import { MeterOption } from "components/common/Common.interfaces";

export const generateRandom = (): string => {
    let length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

export const isMeterInRange = (meter: MeterOption, startDate?: Date, endDate?: Date): boolean => {
    const meterStartDate = meter.startTimestamp ? new Date(meter.startTimestamp) : null;
    const meterEndDate = meter.endTimestamp ? new Date(meter.endTimestamp) : null;

    if (!meterStartDate || !meterEndDate || !startDate || !endDate) {
        return false; 
    }
    return meterEndDate > startDate && meterStartDate < endDate;
};

// Remove the number in parentheses from the key in a series, and add up all the values with the same name but different parantheses values
// used for chart data clean up after series is created
export const cleanKeysWithParentheses = (series: Record<string, any>[]): Record<string, any>[] => {
    const numberInParenthesesRegex = new RegExp(/ \((\d*[.])?\d+\)/);
    return series.map(item => {
        const newItem = { ...item };
        const keys = Object.keys(newItem);
        
        keys.forEach(key => {
            if (numberInParenthesesRegex.test(key)) {
                // Remove the number in parentheses from the key e.g. "KWH (1)" => "KWH"
                const newKey = key.replace(numberInParenthesesRegex, '');
                // Add up all the values with the same name but different parantheses values, e.g "KWH (1)": 100, "KWH (2)": 200 => "KWH": 300
                newItem[newKey] = (newItem[newKey] || 0) + newItem[key];
                newItem[newKey] = Number((newItem[newKey]).toFixed(2));
                delete newItem[key];
            }
        });

        return newItem;
    });
};