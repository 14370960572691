import { READING_DIRECTIONS } from '../common/Common.constants'
import { buildSeriesData } from './CustomerSummaryChartUtils';
// @ts-ignore
import { fixDataKeyName } from 'lib/Formatters';

function createNewSeries(readings: any[], groupBy: any, t: any, domains: { newMaxDomain: number, newMinDomain: number }) {
    let newSeries: any[] = [];
    let totalReadingValues: any[] = [];
    let totalReadingValue: number = 0;

    readings.forEach((reading: any) => {

        if (reading.direction !== READING_DIRECTIONS.RECEIVED) {
            return;
        }

        buildSeriesData({
            series: newSeries,
            totalReadingValues: totalReadingValues,
            totalCostValues: [],
            reading: reading,
            groupBy: groupBy,
            showEST: false,
            translator: t,
            alterSeriesTemperatureProps: {
                hasWeatherData: false,
                groupBy: groupBy,
                series: newSeries,
                reading: reading,
                showStackedAvg: false,
                weatherHistorySummaryData: [],
                weatherDataDays: []
            }
        });

        if (reading.showOnUsageChart) {
            totalReadingValues[reading.readTimestampLocal.ts] += reading.readingValue;
            totalReadingValue = totalReadingValues[reading.readTimestampLocal.ts];
        }

        // in case of undefined reading (will cause an error if unhandled), skip
        if (!newSeries[reading.readTimestampLocal.ts]) {
            return;
        }

        newSeries[reading.readTimestampLocal.ts]["totalUsage"] = totalReadingValue;
        newSeries[reading.readTimestampLocal.ts][`${fixDataKeyName(reading.uniqueName)}_rate`] = reading.rate;

        if (totalReadingValue > domains.newMaxDomain) {
            domains.newMaxDomain = totalReadingValue;
        }

        if (totalReadingValue < domains.newMinDomain) {
            domains.newMinDomain = totalReadingValue;
        }
    });

    return newSeries;
}

export {
    createNewSeries
}