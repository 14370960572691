import { useTranslation } from 'react-i18next';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import type { TooltipProps } from 'recharts';
import {
    ValueType,
    NameType
} from 'recharts/types/component/DefaultTooltipContent';

interface MyUsageTooltipProps extends TooltipProps<ValueType, NameType> {
    active?: boolean;
    uom: string;
    orgTempUnit: string | null;
    precipUnit: string | null;
    payload?: any;
    hasCostModule: boolean;
}

export const MyUsageTooltip = (props: MyUsageTooltipProps) => {
    const { active, uom, orgTempUnit, precipUnit, payload, hasCostModule } = props;
    const { t } = useTranslation();
    const tooltipPayload: any = payload[0]?.payload;

    const tooltipSummary = tooltipPayload?.tooltipSummary;
    const totalUsage = tooltipPayload?.totalUsage;
    const totalCost = tooltipPayload?.totalCost;
    const tooltipLabel = tooltipPayload?.tooltipLabel;

    const getValueDisplayText = (item: any) => {
        if (item.rateChargeType === 'MONTHLY') {
            return t(`Total: $${item?.rate?.toFixed(2) ?? '0.00'}`);
        }
        else {
            const costText = hasCostModule ? `, $${item?.cost?.toFixed(2) ?? '0.00'}` : '';
            return t(`Total: ${item?.usage?.toFixed(2)} ${uom}${costText}`);
        }
    };

    const formatRate = (rate: number): string => {
        const [, decimals] = rate.toString().split(".");
        if (decimals && decimals.length > 2) {
            return rate.toString();
        }

        return rate.toFixed(2);
    };

    if (active && payload?.length) {
        return (
            <List className='myUsageTooltip'
                sx={{
                    width: '100%',
                    minWidth: '250px',
                    bgcolor: 'background.paper',
                    border: '1px solid #cccccc',
                    borderRadius: '5px'
                }}
                component="nav"
            >
                <ListItem>
                    <Typography gutterBottom variant="h6" component="p">
                        {tooltipLabel}
                    </Typography>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText
                        primary={totalUsage?.toFixed(2) + " " + uom + (hasCostModule ? " - $" + totalCost?.toFixed(2) : "")}
                        secondary={t('Total')}
                    />
                </ListItem>
                {tooltipSummary?.map((item: any) =>
                    <>
                        <Divider />
                        <ListItem>
                            <ListItemText
                                sx={{ color: item.htmlColor }}
                                primaryTypographyProps={{ fontSize: '15px' }}
                                primary={`${item.name} @ $${formatRate(item.rate)}${item?.rateChargeType?.toUpperCase() === "MONTHLY" ? " per month" : `/${uom}`}`}
                                secondaryTypographyProps={{ fontSize: '13px' }}
                                secondary={getValueDisplayText(item)}
                            />
                        </ListItem>
                    </>    
                )}
                <Divider />
                {tooltipPayload?.temp !== undefined && (
                    <ListItem
                        sx={{
                            pt: 1,
                            pb: 0
                        }}
                    >
                        <ListItemText
                            secondary={t("Avg (\u00B0" + orgTempUnit + ") " + tooltipPayload?.temp + "\u00B0" + orgTempUnit)}
                        />
                    </ListItem>
                )}
                {tooltipPayload?.maxTemp !== undefined && (
                    <ListItem
                        sx={{
                            pt: 0,
                            pb: 0
                        }}
                    >
                        <ListItemText
                            secondary={t("High (\u00B0" + orgTempUnit + ") " + tooltipPayload?.maxTemp + "\u00B0" + orgTempUnit)}
                        />
                        <LocalFireDepartmentIcon sx={{ color: "red" }} fontSize='small' />
                    </ListItem>
                )}
                {tooltipPayload?.minTemp !== undefined && (
                    <ListItem
                        sx={{
                            pt: 0,
                            pb: 0
                        }}
                    >
                        <ListItemText
                            secondary={t("Low (\u00B0" + orgTempUnit + ") " + tooltipPayload?.minTemp + "\u00B0" + orgTempUnit)}
                        />
                        <AcUnitIcon sx={{ color: "blue" }} fontSize='small' />
                    </ListItem>
                )}
                {tooltipPayload?.precip !== undefined && (
                    <ListItem
                        sx={{
                            pt: 0,
                            pb: 0
                        }}
                    >
                        <ListItemText
                            secondary={t("Precipitation: " + tooltipPayload?.precip + " " + precipUnit)}
                        />
                        <WaterDropIcon sx={{ color: "#4287f5" }} fontSize='small' />
                    </ListItem>
                )}
            </List>
        )
    }
 };