import { useTranslation } from "react-i18next";
// @ts-ignore
import { MyUsageTooltip } from './MyUsageTooltip';
// @ts-ignore
import { useResponsive } from '../../lib/useResponsive';
// @ts-ignore
import { UcTouch } from 'components/common/UcTouch';
import { Area, Bar, Brush, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
// @ts-ignore
import UcLegendV2 from './UcLegendV2';
import { Alert, FormControlLabel, FormGroup, Switch, Typography, useTheme } from "@mui/material";
// @ts-ignore
import { useUserPref } from "components/user/useUserPref";
import { ChartInstanceOfMuiTheme, UomAxisLabel } from "../readings/CustomerSummaryChartUtils";
import { useEffect, useMemo, useRef, useState } from "react";
import CustomTick from "../readings/CustomTick";
import { UsageHelpers } from "./MyUsage.helpers";

// Takes in the data as a parameter
interface UsageChartProps {
    readonly data: any;
    readonly dataPoints: any;
    readonly uomDisplay: any;
    readonly uom: any;
    readonly groupBy: string;
    readonly orgTempUnit: string | null;
    readonly precipUnit: string | null;
    readonly hasCostModule: boolean;
}

function MyUsageChart({ data, dataPoints, uom, uomDisplay, groupBy, orgTempUnit, precipUnit, hasCostModule }: UsageChartProps) {
    const theme = useTheme<ChartInstanceOfMuiTheme>();
    const { t } = useTranslation();
    const { isMobile } = useResponsive();

    const showCost = useUserPref("showCost", false);
    const showPrecip = useUserPref("showPrecip", false);
    const showCostWithModule = showCost.valueBool && hasCostModule;

    const totalColumns = dataPoints?.length;
    const [columnsToShow, setColumnsToShow] = useState<number>(13);
    const [startChartScroll, setStartChartScroll] = useState<number>(totalColumns - columnsToShow);
    const [endChartScroll, setEndChartScroll] = useState<number>(totalColumns - 1);

    useEffect(() => {
        const { columnsToShow, startChartScroll } = UsageHelpers.calculateColumnsToShow(groupBy, isMobile, totalColumns);
        setColumnsToShow(columnsToShow);
        setStartChartScroll(startChartScroll);
        setEndChartScroll(totalColumns - 1);
    }, [totalColumns, groupBy, isMobile]);

    // Transform data for chart
    const formattedData = dataPoints?.map((dataPoint: any) => ({
        ...dataPoint,
        ...dataPoint.rateGroups.reduce((acc: any, rateItem: any) => { // Spread product data into the main object
            Object.keys(rateItem).forEach(key => {
                acc[key] = rateItem[key];
            });
            return acc;
        }, {}) 
    }));

    const handleChangeShowCost = () => {
        showCost.setUserPref("showCost", !(showCost.valueBool));
        if (showPrecip.valueBool) {
            showPrecip.setUserPref("showPrecip", false);
        }
    }

    const handleChangeShowPrecip = () => {
        showPrecip.setUserPref("showPrecip", !(showPrecip.valueBool));
        if (showCost.valueBool) {
            showCost.setUserPref("showCost", false);
        }
    }

    const uomAxisLabel: { [key: string]: UomAxisLabel } = {
        'KWH': t("Electricity ({{uom}})", { uom: uomDisplay }),
        'GAL': t("Water ({{uom}})", { uom: uomDisplay }),
    }

    const chartRef = useRef(null);
    const [barWidth, setBarWidth] = useState(0);

    // Custom hourly bar ticks
    useEffect(() => {
        const timeout = setTimeout(() => {
            const chartElement: any = chartRef.current;
            if (chartElement) {
                const barElements = Array.from(chartElement.querySelectorAll('.recharts-layer.recharts-bar-rectangle'));

                const validBarElement = barElements.find((element) => {
                    const width = (element as HTMLElement).getBoundingClientRect().width;

                    return width > 0;
                });

                if (validBarElement) {
                    const width = (validBarElement as HTMLElement).getBoundingClientRect().width;
                    setBarWidth(width);
                }
            }
        }, 200);
        return () => clearTimeout(timeout);
    }, [formattedData]);

    const useCustomTick = groupBy === 'none'; // Hourly view only

    const graphFontColor = theme?.props?.Rechart?.axisColor;
    const graphTextStyle = {
        fill: graphFontColor,
        fontSize: isMobile ? 12 : 16
    };

    const { totalUsageDomain, totalCostDomain, precipDomain, tempDomain } = useMemo(() => UsageHelpers.calculateAxisDomains(formattedData),
        [formattedData]
    );

    return (
        <UcTouch
            style={{ width: "100%" }}
            columnsToShow={columnsToShow}
            totalColumns={totalColumns}
            startX={startChartScroll}
            onRangeChangeX={(newStart: any, newEnd: any) => {
                setStartChartScroll(Math.max(newStart, 0));
                setEndChartScroll(newEnd);
            }}
        >
            <div ref={chartRef} className={"customTickDivRef"} style={{ height: '100%', width: '100%'}}>
                <ResponsiveContainer width={"100%"} height={"100%"} maxHeight={620} minHeight={isMobile ? "450px" : "600px"} className="recharts-container-scrollable">
                    {formattedData?.length > 0 ? 
                        <ComposedChart
                            data={formattedData}
                            margin={{
                                top: 15,
                                right: (isMobile ? 5 : 20),
                                bottom: 0,
                                left: (isMobile ? 5 : 20),
                            }}
                            // @ts-ignore
                            fillOpacity="0.7"
                        >
                            <CartesianGrid strokeDasharray="5 5" />
                            <XAxis
                                dataKey="label"
                                scale="auto"
                                interval={isMobile ? 1 : 0} // always display ticks
                                angle={-45}
                                textAnchor="end"
                                tick={useCustomTick ? <CustomTick barWidth={barWidth} /> : { fill: graphFontColor, fontSize: 12 }}
                                tickLine={!useCustomTick}
                            />                 
                            <YAxis
                                domain={[totalUsageDomain.min, totalUsageDomain.max]}
                                label={{
                                    value: uomAxisLabel[uom],
                                    angle: -90,
                                    position: "left",
                                    style: graphTextStyle,
                                    offset: isMobile ? -10 : 0
                                }}
                                tick={graphTextStyle}
                                axisLine={!isMobile}
                            />
                            <YAxis
                                yAxisId="temperature"
                                label={{
                                    value: t("Temperature (\u00B0" + orgTempUnit + ")"),
                                    angle: -90,
                                    position: "insideRight",
                                    style: graphTextStyle,
                                    offset: isMobile ? 0 : 15,
                                }}
                                domain={[tempDomain.min, tempDomain.max]}
                                tickFormatter={(value) => {
                                    return value + "\u00B0";
                                }}
                                tick={graphTextStyle}
                                orientation="right"
                                hide={isMobile}
                            />
                            <YAxis
                                yAxisId="precip"
                                domain={[precipDomain.min, precipDomain.max]}
                                label={{
                                    value: t(`Precipitation (${precipUnit})`),
                                    angle: -90,
                                    position: "insideRight",
                                    style: graphTextStyle
                                }}
                                tick={graphTextStyle}
                                orientation="right"
                                hide={!showPrecip.valueBool}
                                axisLine={!isMobile}
                            />
                            <YAxis
                                yAxisId="totalCost"
                                domain={[totalCostDomain.min, totalCostDomain.max]}
                                label={{
                                    value: t("Cost($)*"),
                                    angle: -90,
                                    position: "insideRight",
                                    style: graphTextStyle
                                }}
                                tick={graphTextStyle}
                                orientation="right"
                                hide={!showCostWithModule}
                                axisLine={!isMobile}
                            />                   
                            <Area
                                yAxisId="temperature"
                                name="Temperature"
                                // @ts-ignore
                                displayName={t("Temperature")}
                                isAnimationActive={false}
                                baseValue="dataMin" // fills area under axis < 0
                                dataKey="temp"
                                type="monotone"
                                fill="#ffc658"
                                stroke="#ffc658"
                                activeDot={{ r: 8 }}
                                style={{ fillOpacity: "0.3" }}
                            />
                            <Tooltip
                                trigger='click' 
                                content={
                                    <MyUsageTooltip uom={uomDisplay} orgTempUnit={orgTempUnit} precipUnit={precipUnit} hasCostModule={hasCostModule} />
                                }
                            />
                            <Legend content={(props) => <UcLegendV2 {...props} />} />
                            {data.result?.groupings?.map((group: any) => {
                                return (
                                    <Bar
                                        key={"myUsageChartGroupings_" + group.uniqueName}
                                        isAnimationActive={false}
                                        stackId="a"
                                        dataKey={group.dataKeyName}
                                        // @ts-ignore
                                        displayName={group.displayName}
                                        // @ts-ignore
                                        showOnUsageChart={group.showOnUsageChart}
                                        fill={group.timeOfUseHtmlColor}
                                    />
                                );
                            })}                       
                            {showPrecip.valueBool && (
                                <Line
                                    yAxisId="precip"
                                    name="Precipitation"
                                    // @ts-ignore
                                    displayName={t("Precipitation")}
                                    isAnimationActive={false}
                                    dataKey="precip"
                                    type="monotone"
                                    fill="#4287f5"
                                    stroke="#4287f5"
                                    activeDot={{ r: 8 }}
                                />
                            )}
                            {showCostWithModule && (
                                <Line
                                    yAxisId="totalCost"
                                    type="monotone"
                                    dataKey="totalCost"
                                    stroke="#8884d8"
                                    // @ts-ignore
                                    displayName={t("Cost")}
                                    activeDot={{ r: 8 }}
                                />
                            )} 
                            <Brush
                                updateId={startChartScroll}
                                startIndex={startChartScroll}
                                endIndex={endChartScroll}
                                dataKey="label"
                            />
                        </ComposedChart>
                    :
                        <Alert severity="warning" sx={{ mx: 5, mt: 10 }}>{t("No data available.")}</Alert>
                    }
                </ResponsiveContainer>
                {hasCostModule && (
                    <FormGroup className="form-inline" style={{ flexDirection: 'row', paddingRight: "15px", justifyContent: 'flex-end' }}>
                        <FormControlLabel
                            control={<Switch
                                checked={(showCostWithModule)}
                                onChange={handleChangeShowCost}
                                inputProps={{ 'aria-label': 'controlled' }} />
                            }
                            labelPlacement="start"
                            label={t("Cost ($)*")}
                        />
                    </FormGroup>
                )}
                <FormGroup className="form-inline" style={{ flexDirection: 'row', paddingRight: "15px", justifyContent: 'flex-end' }}>
                    <FormControlLabel
                        control={<Switch
                            checked={(showPrecip.valueBool)}
                            onChange={handleChangeShowPrecip}
                            inputProps={{ 'aria-label': 'controlled' }} />
                        }
                        labelPlacement="start"
                        label={t(`Precipitation (${precipUnit})`)}
                    />
                </FormGroup>
                {hasCostModule && (
                    <Typography color={theme.palette.primary.light} sx={{
                        justifyContent: "right",
                        alignItems: "right",
                        width: "100%",
                        display: "flex",
                        pr: "15px",
                        pb: "10px",
                        fontStyle: "italic",
                        fontSize: "13px"
                    }}>
                        {t("* Actual charges may vary")}
                    </Typography>
                )}
            </div>
        </UcTouch>
    );
}

export default MyUsageChart;