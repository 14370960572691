import React, { useState, useRef } from 'react';
import { AccountReadingsWrapperYearly } from 'components/readings/AccountReadingWrappers';
import { UcLoading } from '../common/UcLoading';
import { UcProgress } from '../common/UcProgress';
// @ts-ignore
import { RateClassComparisonTooltip } from '../../lib/RateClassComparisonTooltip';
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Brush
} from 'recharts';
import { ChartInstanceOfMuiTheme, updateReadTimestampLocalDates } from 'components/readings/CustomerSummaryChartUtils';
import { createNewSeries, createBillDateRangeText } from './RateClassComparisonUtils';
// @ts-ignore
import { useAccountReadings } from 'components/readings/ReadingAPIEndpoint';
import { useTheme, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { useResponsive } from '../../lib/useResponsive';
// @ts-ignore
import { ensureLuxonDate } from '../../lib/Formatters';
// @ts-ignore
import { UcTouch } from 'components/common/UcTouch';
// @ts-ignore
import { MD5 } from 'object-hash';
import Alert from '@mui/material/Alert';
// @ts-ignore
import { RateClassComparisonLegend } from './RateClassComparisonLegend';
import { cleanKeysWithParentheses } from 'utils/helpers/general.helpers';

export const RateClassComparisonGraph = (props: any) => {
    // Refs
    const isDataLoaded = useRef(false);

    // Hooks
    const theme = useTheme<ChartInstanceOfMuiTheme>();
    const { t } = useTranslation();
    const { isMobile } = useResponsive();

    // Props
    const propsHash: string = MD5(JSON.stringify(props));
    const endDatePlusFiveMinutes = new Date(props.endDate);
    endDatePlusFiveMinutes.setMinutes(endDatePlusFiveMinutes.getMinutes() + 5);
    const endDatePlusOneHour = new Date(props.endDate);
    endDatePlusOneHour.setTime(endDatePlusOneHour.getTime() + (1 * 60 * 60 * 1000));
    const uom: string = props.uom || "KWH";
    const groupBy: string = props.groupBy || "month";
    const chartMinHeight: string = props.chartMinHeight || (isMobile ? "450px" : "600px");
    const endYear: number = ensureLuxonDate(props.endDate).year;
    const endMonth: number = ensureLuxonDate(props.endDate).month;

    // States
    const [progress, setProgress] = useState({ current: 0, total: 100 });
    const [series, setSeries] = useState<any[]>([]);
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const [currentPropHash, setCurrentPropHash] = useState(propsHash);
    const [yearlyData, setYearlyData] = useState<any>(null);
    const [totalColumns, setTotalColumns] = useState<number>(24);
    const [columnsToShow, setColumnsToShow] = useState<number>(13);
    const [startChartScroll, setStartChartScroll] = useState<number>(totalColumns - columnsToShow);
    const [endChartScroll, setEndChartScroll] = useState<number>(totalColumns - 1);
    const [seriesHasRateData, setSeriesHasRateData] = useState<boolean>(false);
    
    // SWR
    const swrReadings = useAccountReadings(props.startDate, endDatePlusFiveMinutes, groupBy, uom, props.accountId, null, null, false, {}, groupBy !== "month", props.currentRateClassId, props.comparedRateClassId); //Pass the datastate directly as the filter.  The API will adjust filters, orderbys ect.
    let swrDictionary = { mainPeriod: null, comparePeriod: null };

    // Other
    const yearlyDataCount: number = yearlyData?.mainPeriod?.result?.length;

    React.useEffect(() => {
        switch (groupBy) {
            case "day":
                if (isMobile) {
                    setColumnsToShow(14);
                    setStartChartScroll(Math.max(totalColumns - (14), 0));
                }
                else {
                    setColumnsToShow(31);
                    setStartChartScroll(Math.max(totalColumns - (31), 0));
                }
                break;
            case "none":
                if (isMobile) {
                    setColumnsToShow(13);
                    setStartChartScroll(Math.max(totalColumns - (13), 0));
                }
                else {
                    setColumnsToShow(24);
                    setStartChartScroll(Math.max(totalColumns - (24), 0));
                }
                break;
            default:
                setColumnsToShow(13);
                setStartChartScroll(Math.max(totalColumns - (13), 0));
                break;
        };
    }, [totalColumns, groupBy, isMobile]);

    React.useEffect(() => {
        const hasResults = groupBy === "month" ? (yearlyData?.mainPeriod && yearlyData?.comparePeriod) : swrReadings.result;

        if (hasResults && initialLoad) {
            setInitialLoad(false);
        }

    }, [swrReadings.result, initialLoad, props, groupBy, yearlyData?.mainPeriod, yearlyData?.comparePeriod]);

    if (swrReadings.result) {
        swrDictionary = {
            mainPeriod: swrReadings.result[0],
            comparePeriod: swrReadings.result[1]
        };
    }

    const defaultDayChartScrolls = (newSeries: any) => {
        if ((newSeries.length - columnsToShow) > 0 && isMobile) {
            let index = newSeries.map((e: any) => e.localTimestamp).indexOf("21:00:00");

            if (index > 0) {
                setStartChartScroll(Math.max(index - columnsToShow + 1, 0));
                setEndChartScroll(index);
            }
            else {
                setStartChartScroll(Math.max(newSeries.length - columnsToShow - 3, 0));
                setEndChartScroll(newSeries.length - 4);
            }
        }
        else {
            setStartChartScroll(0); // less columns with data than columns to display
            setEndChartScroll(newSeries.length - 1);
        }
    }

    const handleSetSeries = (newSeries: any[]) => {
        const seriesStringified = JSON.stringify(newSeries);
        setSeriesHasRateData(seriesStringified.includes('_rate'));
        setSeries(newSeries);
    }

    if (currentPropHash !== propsHash) {
        setCurrentPropHash(propsHash);
        isDataLoaded.current = false;
    }

    if ((groupBy === "month" ? !(yearlyData?.mainPeriod) : swrReadings.isLoading)) {
        isDataLoaded.current = false;
    }
    else if ((groupBy === "month" ? (yearlyData?.mainPeriod && yearlyData?.comparePeriod) : (swrReadings?.result && swrDictionary?.mainPeriod && swrDictionary?.comparePeriod)) && !isDataLoaded.current) {
        const mainPeriod = groupBy === "month" ? yearlyData.mainPeriod : swrDictionary.mainPeriod;
        const comparePeriod = groupBy === "month" ? yearlyData.comparePeriod : swrDictionary.comparePeriod;

        if (mainPeriod && comparePeriod) {
            props.setRateCategories({
                mainPeriod: mainPeriod.rateRules,
                comparePeriod: comparePeriod.rateRules
            });
        } 

        updateReadTimestampLocalDates(mainPeriod.result, groupBy);
        updateReadTimestampLocalDates(comparePeriod.result, groupBy);

        let newSeries: any = createNewSeries(mainPeriod.result, groupBy, props.showEST, t, "mainPeriod");
        let comparePeriodSeries: any = createNewSeries(comparePeriod.result, groupBy, props.showEST, t, "comparePeriod");
        
        let concatArrays = {};
        Object.keys(newSeries).forEach(key => {
            concatArrays = {
                ...concatArrays,
                [key]: { ...newSeries[key], ...comparePeriodSeries[key] }
            }
        });

        newSeries = concatArrays;
        newSeries = Object.keys(newSeries).sort().map((key: any) => {
            return newSeries[key];
        });

        if (groupBy === "none") {
            defaultDayChartScrolls(newSeries);
        }
        else {
            setStartChartScroll(Math.max(newSeries.length - (columnsToShow), 0)); // don't want a negative number here
            setEndChartScroll(newSeries.length - 1);
        }
        setTotalColumns(newSeries.length);
        handleSetSeries(newSeries);

        isDataLoaded.current = true;
    }

    const newItems = cleanKeysWithParentheses(series);

    let billDateRangeText: string = createBillDateRangeText(newItems);
    props.setBillDateRangeText(billDateRangeText);

    return (
        <div className="rateClassComparisonGraph graphWrapper" data-cy-id={"rateClassComparison-graph"}>
            {groupBy === "month" &&
                <AccountReadingsWrapperYearly
                    accountId={props.accountId}
                    uom={uom}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    month={endMonth}
                    year={endYear}
                    readyForData={!hasError}
                    currentRateClassId={props.currentRateClassId}
                    comparedRateClassId={props.comparedRateClassId}
                    onDataCompleted={(newData) => {
                        const newDataDictionary = {
                            mainPeriod: newData[0],
                            comparePeriod: newData[1]
                        };

                        if (yearlyDataCount !== newDataDictionary?.mainPeriod?.result?.length && isDataLoaded.current) {
                            isDataLoaded.current = false;
                        }
                        
                        setYearlyData(newDataDictionary);
                        setHasError(false);
                    }}
                    onProgress={(progressLocal) => {
                        if (progressLocal.current !== progress.current) {
                            setProgress({ ...progressLocal });
                        }
                    }}
                    onReset={() => {
                        setYearlyData(null);
                        setHasError(false);
                    }}
                    onError={(error) => {
                        console.error(error);
                        setHasError(true);
                    }} />
            }
            <UcLoading hasError={hasError || !props.areRatesAvailable}
                isLoading={!isDataLoaded.current}
                loadingRender={<UcProgress {...progress} />}
                errorRender={<Alert severity="warning">{t("No data available.")}</Alert>}
            >
                <div className="loadingChartWrapper">
                    {!seriesHasRateData ? (
                        <Alert severity="warning" sx={{ mx: 5, mb: 40, mt: 10 }}>{t("No data available.")}</Alert>
                    ) : (
                        <>
                            {isMobile && (
                                <Grid container marginTop="10px">
                                    <Grid item xs={6} justifyContent="left" alignItems="right" display="flex">
                                        <Typography fontSize="16px" color={theme?.props.Rechart.axisColor}> {"Cost ($)*"} </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <UcTouch
                                style={{ height: "100%", width: "100%" }}
                                columnsToShow={columnsToShow}
                                totalColumns={totalColumns}
                                startX={startChartScroll}
                                onRangeChangeX={(newStart: any, newEnd: any) => {
                                    setStartChartScroll(Math.max(newStart, 0));
                                    setEndChartScroll(newEnd);
                                    if (newItems) {
                                        handleSetSeries([...newItems])
                                    }
                                }}
                            >
                                <ResponsiveContainer
                                    width="100%"
                                    height="100%"
                                    minHeight={chartMinHeight}
                                    className="recharts-container-scrollable">
                                    <ComposedChart
                                        data={newItems}
                                        margin={{
                                            top: (isMobile ? 5 : 20),
                                            right: (isMobile ? 5 : 20),
                                            bottom: 5,
                                            left: (isMobile ? -25 : 10)
                                        }}
                                        // @ts-ignore
                                        fillOpacity="0.7"
                                    >
                                        <CartesianGrid stroke={theme?.props?.Rechart?.cartesianGridColor} strokeDasharray="5 5" />
                                        <XAxis dataKey="name" interval={isMobile ? 1 : 0} angle={-45} textAnchor="end" tick={{ fill: theme?.props?.Rechart?.axisColor, fontSize: 12 }} />
                                        <YAxis yAxisId="Cost" tick={{ fill: theme?.props?.Rechart?.axisColor, fontSize: (isMobile ? '12px' : '16px') }}
                                            label={{ value: isMobile ? "" : "Cost ($)*", dx: -30, angle: -90, style: { fill: theme?.props?.Rechart?.axisColor } }}
                                            axisLine={!isMobile} 
                                        />
                                        <Tooltip                         
                                            content={<RateClassComparisonTooltip groupBy={groupBy} />}                                        
                                        />
                                        <Legend content={<RateClassComparisonLegend />} />
                                        <Line yAxisId="Cost"
                                            id="rateClassComparison-line-mainPeriod"
                                            isAnimationActive={false}
                                            dataKey="Cost_mainPeriod"
                                            type="monotone"
                                            fill={theme.palette.success.main}
                                            stroke={theme.palette.success.main}
                                            activeDot={{ r: 8 }}
                                            legendType={"line"}
                                            />
                                        <Line yAxisId="Cost"
                                            id="rateClassComparison-line-comparePeriod"
                                            isAnimationActive={false}
                                            dataKey="Cost_comparePeriod"
                                            type="monotone"
                                            fill={theme.palette.info.main}
                                            stroke={theme.palette.info.main}
                                            activeDot={{ r: 8 }}
                                            legendType={"line"}
                                        />
                                        <Brush
                                            updateId={startChartScroll}
                                            startIndex={startChartScroll}
                                            endIndex={endChartScroll}
                                            dataKey="name"
                                        />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </UcTouch>
                            <Typography color={theme.palette.primary.light} sx={{
                                justifyContent: "right",
                                alignItems: "right",
                                width: "100%",
                                display: "flex",
                                pr: "15px",
                                pb: "10px",
                                fontStyle: "italic",
                                fontSize: "13px"
                            }}>
                                {t("* Actual charges may vary")}
                            </Typography>
                        </>
                    )}
                </div>
            </UcLoading>
        </div>
    );
};