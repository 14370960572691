import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useDebouncedValue } from '@mantine/hooks';
import { useFilterItems } from '../../lib/useFilterItems';

// From: https://mui.com/components/autocomplete/#Asynchronous.js
export const UcSelect = (props) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState(props.value || null);
    const [filterRefresh, setFilterRefresh] = React.useState(props.filterRefresh || null);
    const [valueRefresh, setValueRefresh] = React.useState(props.valueRefresh || null);
    const needsUpdate = React.useRef(true);
    const firstValueSet = React.useRef(false);

    const [filter, setFilter] = React.useState(
        props.filter ||
        {
            take: 100,
            skip: 0,
            sort: [],
            generalSearch: "",
    });

    React.useEffect(() => {
        // refreshes the value
        if (value && props.valueRefresh && props.valueRefresh !== valueRefresh) {
            setValue(props.value || null);
            setValueRefresh(props.valueRefresh);
            firstValueSet.current = false;
        }

        // change filterRefresh prop to refetch using the original filter
        if (props.filterRefresh && props.filterRefresh !== filterRefresh) {
            setFilterRefresh(props.filterRefresh);
            setFilter(props.filter || { ...filter });
            needsUpdate.current = true;
        }
    }, [filter, filterRefresh, props.filter, props.filterRefresh, props.value, props.valueRefresh, value, valueRefresh]);

    let loading = true;
    let loadedOptions = [];

    const swrFilteredItems = useFilterItems(props.apiEndPoint, filter);
    if (swrFilteredItems.result && !swrFilteredItems.isLoading && !swrFilteredItems.isError)
    {
        if (props.onDataReceived) {
            props.onDataReceived(swrFilteredItems.result);
        }
       
        loadedOptions = [...swrFilteredItems.result];
         
        if (value && value.id > 0 && !loadedOptions.find(x => x.id === value.id)) {
            loadedOptions.push(value);
        }
        loading = false;
        if (needsUpdate.current) {
            needsUpdate.current = false;

            setOptions([...loadedOptions]);
        }       
    }
    else
    {
        needsUpdate.current = true;
        loading = true;
    }

    const propOnChange = props.onChange;

    React.useEffect(() => {
        // If the current value is not in the new options, reset the selected value
        if (!firstValueSet.current && options && options.length > 0 && (!options.find(option => option?.accountMeterId === value?.accountMeterId))) {
            firstValueSet.current = true;
            propOnChange(null, options[0], options, true);
            setValue(options[0]);
        }
        else if (options && options.length > 0 && (!options.find(option => option?.accountMeterId === value?.accountMeterId))) {
            firstValueSet.current = false;
        }
        else if (!value && options && options.length > 0 && !firstValueSet.current) {
            // Only do this once
            firstValueSet.current = true;
            propOnChange(null, options[0], options);
            setValue(options[0]);
        }
        else if (!value) {
            // no options
            firstValueSet.current = false;
        }
        else {
            // Has a value so no need to set it to first
            firstValueSet.current = true;
        }

        setValueRefresh(props.valueRefresh);
    }, [options, value, propOnChange, props.valueRefresh]);

    const [generalSearch, setGeneralSearch] = React.useState(filter.generalSearch);
    const [debounced] = useDebouncedValue(generalSearch, 500);

    React.useEffect(() => {
        if (filter.generalSearch !== debounced) {
            needsUpdate.current = true;
            setFilter({
                ...filter,
                generalSearch: debounced,
            });
        }
    }, [debounced, filter]);

    const getOptionLabel = (option) => {
        if (props.getOptionLabel) {
            return props.getOptionLabel(option);
        }

        return option.name;
    }    

    const sxProps = props.minWidth != null ? { sx: { minWidth: props.minWidth } } : {};

    return (
        <Autocomplete
            {...sxProps}
            disableClearable={props.disableClearable}
            id={props.id || "ucSelect"}
            open={open}
            value={value}
            className={props.className}
            onChange={(event, newValue) => {
                setValue(newValue);
                props.onChange(event, newValue);
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                if (filter.generalSearch !== "")
                {
                    needsUpdate.current = true;
                    setFilter({...filter, generalSearch: ""});
                }
                setOpen(false);
            }}
            isOptionEqualToValue={(option, autoValue) => option.id === autoValue.id}
            getOptionLabel={(option) => getOptionLabel(option)}
            options={options}
            loading={loading}
            disabled={props.disabled}
            renderInput={(params) => (
                <TextField 
                    color={props.color} focused={props.color || false}
                    {...params}
                    onChange={(e) => {
                        setGeneralSearch(e.target.value);
                    }}
                    label={props.label || props.defaultLabel || "Select"}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                />
        )}
        />
    );
};

UcSelect.defaultProps = {
    onChange: (event, value) => { console.log("ucSelect onChange not implemented", event, value); },
};