import {
    Domain as DomainIcon,
    GitHub as GitHubIcon,
    Settings as SettingsIcon,
    Groups as GroupsIcon,
    AttachMoney as AttachMoneyIcon,
    //Difference as DifferenceIcon,
    ShowChart as ShowChartIcon,
    Campaign as CampaignIcon,
    FolderShared as FolderSharedIcon,
    BarChart as BarChartIcon,
    Apartment as ApartmentIcon,
    Dashboard as DashboardIcon,
    CrisisAlert as CrisisAlertIcon,
    CompareArrows as CompareArrowsIcon,
    Paid as PaidIcon,
    TrendingUp as TrendingUpIcon,
    SpaceDashboard as SpaceDashboardIcon,
    EventNote as EventNoteIcon,
    QuestionMark as QuestionMarkIcon
} from "@mui/icons-material";
import { OrgListPage } from "./components/adminUc/Orgs/OrgListPage";
import { MyOrg } from "./components/adminUc/Orgs/MyOrg";
import { RateClassChangeRequestListPage } from "./components/admin/Rates/RateClassChangeRequestListPage";
import { OutgoingNotificationListPage } from "./components/adminUc/Emails/OutgoingNotificationListPage";
import { ResidentialUserListPage } from "./components/adminUc/Users/ResidentialUserListPage";
import { AdminUserListPage } from "./components/adminUc/Users/AdminUserListPage";
import { SuperAdminUserListPage } from "./components/adminUc/Users/SuperAdminUserListPage";
import { RoleListPage } from "./components/adminUc/Roles/RoleListPage";
import { RoleDetailsPage } from "./components/adminUc/Roles/RoleDetailsPage";
import { UserDetailsPage } from "./components/adminUc/Users/UserDetailsPage";
import { DevOneSignalInfo } from "./components/dev/DevOneSignalInfo";
import { DevAdminDetailPage } from "./components/dev/DevAdminDetailPage";
import { DevHomePage } from "./components/dev/DevHomePage";
import { DevDashboardPage } from "./components/dev/DevDashboardPage";
import DevNeighborCompare from './components/dev/DevNeighborCompare';
import { DevMobileCostPrediction } from "./components/dev/DevMobileCostPrediction";
import MyUsagePage from "./components/usage/MyUsagePage";
import { DevCostPredictionAccountMetersWithinRadius } from "./components/dev/DevCostPredictionAccountMetersWithinRadius";
import { HomePage } from "./components/HomePage";
import FaqPage from './components/FaqPage';
import { DashboardPage } from "./components/DashboardPage";
import { AdminDashboardPage } from "./components/admin/AdminDashboardPage";
import { AccountAuthorizationPage } from "./components/accountAuthorization/AccountAuthorizationPage";
import { AccountVerificationPage } from "./components/accountAuthorization/AccountVerificationPage";
import { LandingPage } from "./components/LandingPage";
import { CustomerSummaryPage } from "./components/readings/CustomerSummaryPage";
// TODO: Resolve page fetching errors
// import { CustomerSummaryTabBillPeriod } from "./components/readings/CustomerSummaryTabBillPeriod";
import { DevResetPermissionCache } from "components/dev/DevResetPermissionCache";
import { RateClassListPage } from "components/admin/Rates/RateClassListPage";
import { DevDateValidatePage } from "components/dev/DevDateValidatePage";
import { OutgoingNotificationDetailsPage } from "components/adminUc/Emails/OutgoingNotificationDetailsPage";
import { BillingCycleListPage } from "components/admin/Billings/BillingCycleListPage";
import { BillingCycleDetailsPage } from "components/admin/Billings/BillingCycleDetailsPage";
import { BillApiHealthListPage } from "./components/admin/BillApiHealth/BillApiHealthListPage";
import { MyBillsListPage } from "components/user/bills/MyBillsListPage";
import { MyBillsPdfPage } from "components/user/bills/MyBillsPdfPage";
import { PaymentusWalletPage } from "components/user/bills/PaymentusWalletPage";
import { PaymentusAutoPayPage } from "components/user/bills/PaymentusAutoPayPage";
import { PaymentsPage } from "components/admin/Payments/PaymentsPage";
import { globalTranslator } from "lib/GlobalTranslator";
import { MyProfilePage } from "components/user/MyProfilePage";
import { UserNotificationPage } from "components/user/UserNotificationPage";
import { ImpersonatePage } from "components/admin/Impersonate/ImpersonatePage";
import { AnnouncementListPage } from "components/admin/Announcements/AnnouncementListPage";
import { HolidayListPage } from "components/admin/Holidays/HolidayListPage";
import { AccountListPage } from "components/admin/Customers/AccountListPage";
import { OutageMapListPage } from "components/outageMap/OutageMapListPage";
import { MyCostPredictionPage } from "./components/costPrediction/MyCostPredictionPage";
import { MyHelpRequestPage } from "./components/helpRequest/MyHelpRequestPage";
import RateClassComparisonPage from "./components/rateClassComparison/RateClassComparisonPage";
import RateVersionListPage from "components/admin/Rates/RateVersionListPage";
import React from "react";
import { EnsureUser } from "./components/auth/EnsureUser";
import { OutageListPage } from "./components/admin/Outages/OutageListPage";
import { OrgModuleListPage } from "./components/admin/OrgModules/OrgModuleListPage";
import { AdminRateExpirationListPage } from "./components/admin/Widgets/AdminRateExpirationListPage";
import { HelpRequestListPage } from "./components/helpRequest/HelpRequestListPage";
import { BlacklistedIpAddressListPage } from "./components/admin/BlacklistedIpAddress/BlacklistedIpAddressListPage";
import { UtilityDashboardPage } from "./components/admin/Dashboard/UtilityDashboardPage";

let devMenu = [];
const t = globalTranslator;
// Items in this file will not be translated properly
// However, we need the text wrapped in the t() function so that parsing tools can pick it up

if (process.env.REACT_APP_SHOW_DEV_ROUTES === "true") {
    devMenu = [
        {
            name: 'devMenu',
            displayName: t('Developer'),
            menu: {
                startIcon: <GitHubIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 1000,
                collapse: true
            }
        },
        {
            name: 'devHomePage',
            displayName: 'Dev Home Page For Testing',
            path: '/dev/homePage',
            element: <DevHomePage />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
        {
            name: 'devDashboardPage',
            displayName: 'Dev Dashboard Page For Testing',
            path: '/dev/dashboardPage',
            element: <DevDashboardPage />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
        {
            name: 'devNeighborCompare',
            displayName: 'Dev Neighbor-Compare',
            path: '/dev/neighborCompare',
            element: <DevNeighborCompare />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
        {
            name: 'devOneSignalInfo',
            displayName: 'Dev One Signal Info',
            path: '/dev/oneSignalInfo',
            element: <DevOneSignalInfo />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
        {
            name: 'devAdminDetailsPage',
            displayName: 'Dev Admin Details Page Sample',
            path: '/dev/adminDetailsPage',
            element: <DevAdminDetailPage />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
        {
            name: 'devResetPermissionCache',
            displayName: 'Dev Reset Permission Cache on server',
            path: '/dev/resetPermission',
            element: <DevResetPermissionCache />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
        {
            name: 'devDateForm',
            displayName: 'Dev Date Form Sample',
            path: '/dev/dateForm',
            element: <DevDateValidatePage />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
        {
            name: 'devCostPredictionAccountMetersWithinRadius',
            displayName: 'Dev Get Account Meters Within Radius Sample',
            path: '/dev/costPredictionAccountMetersWithinRadius',
            element: <DevCostPredictionAccountMetersWithinRadius />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
        {
            name: 'devMobileCostPrediction',
            displayName: 'Dev Mobile Cost Prediction Sample',
            path: '/dev/mobileCostPrediction',
            element: <DevMobileCostPrediction />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
        {
            name: 'devMyUsageV2',
            displayName: 'Dev My Usage - V2',
            path: '/dev/v2/myUsage',
            element: <MyUsagePage />,
            menu: {
                parentMenu: 'devMenu',
                startIcon: <DomainIcon />,
                conditions: ["normal", "displayNoAccountsAdmin"],
                order: 0
            }
        },
    ];

}

// The new "parentName" property could potentially be replaced with an Id
export const routeDetails = [
    {
        name: 'homePage',
        displayName: t('Dashboard'),
        path: '/',
        element: <DashboardPage />,
        menu: {
            startIcon: <DashboardIcon />,
            order: 0,
            authenticationRequired: true,
            conditions: ["normal"],
        }
    },
    {
        name: 'homePage',
        displayName: t('Dashboard'),
        path: '*',
        element: <DashboardPage />,
    },
    {
        name: 'impersonateHomePage',
        displayName: t('Impersonate Dashboard'),
        path: '/',
        element: <DashboardPage />,
        menu: {
            startIcon: <DashboardIcon />,
            order: 0,
            authenticationRequired: true,
            conditions: ["impersonate"],
        }
    },
    {
        name: 'myBillsListPage',
        displayName: t('Billing'),
        path: '/billing',
        element: <MyBillsListPage />,
        menu: {
            startIcon: <AttachMoneyIcon/>,
            permissions: ["Org:Modify"],
            modules: ['Billing'],
            conditions: ["normal"],
            order: 1
        },
        parentName: 'homePage'
    },   
    {
        name: 'impersonateMyBillsListPage',
        displayName: t('Billing'),
        path: '/billing',
        element: <MyBillsListPage />,
        menu: {
            startIcon: <AttachMoneyIcon />,
            permissions: ["Org:Modify"],
            modules: ['Billing'],
            conditions: ["impersonate"],
            order: 1
        },
        parentName: 'homePage'
    },
    {
        name: 'paymentusWalletPage',
        displayName: t('My Wallet'),
        path: '/paymentus/wallet',
        element: <PaymentusWalletPage />,
        modules: ['PaymentusWallet']
    },  
    {
        name: 'paymentusAutoPayPage',
        displayName: t('AutoPay'),
        path: '/paymentus/autoPay',
        element: <PaymentusAutoPayPage />,
        modules: ['PaymentusAutoPay']
    },  
    {
        name: 'myBillsPdfPage',
        displayName: t('My Bills PDF'),
        path: '/customer/myBills/pdf/:accountNumber',
        element: <MyBillsPdfPage />,
        getPath: (accountNumber) => {
            return `/customer/myBills/pdf/${accountNumber}`;
        }
    },
    {
        name: 'faqPage',
        displayName: t('FAQ'),
        path: '/faq',
        element: <FaqPage />,
    },
    {
        name: 'rateClassComparisonPage',
        displayName: t('Rate Comparison'),
        path: '/rateClassComparison',
        element: <RateClassComparisonPage />,
        parentName: 'homePage',
        menu: {
            startIcon: <CompareArrowsIcon />,
            modules: ['RateClassComparison', 'Cost'],
            order: 3,
            authenticationRequired: true,
            conditions: ["normal"],
        }
    },
    {
        name: 'impersonateRateClassComparisonPage',
        displayName: t('Rate Comparison'),
        path: '/rateClassComparison',
        element: <RateClassComparisonPage />,
        parentName: 'homePage',
        menu: {
            startIcon: <CompareArrowsIcon />,
            modules: ['RateClassComparison', 'Cost'],
            order: 3,
            authenticationRequired: true,
            conditions: ["impersonate"],
        }
    },
    {
        name: 'adminPage',
        displayName: t('Admin'),
    },
    {
        name: 'accountAuthorizationPage',
        displayName: t('Account Authorization'),
        path: '/accountAuthorization',
        element: <AccountAuthorizationPage />,
    },
    {
        name: 'accountVerificationPage',
        displayName: t('Account Verification'),
        path: '/accountAuthorization/verification',
        element: <AccountVerificationPage />,
    },
    {
        name: 'homePageImpersonate',
        displayName: t('Impersonating User Details'),
        path: '/impersonate',
        element: <ImpersonatePage />,
    },
    {
        name: 'landingPage',
        displayName: t('Landing'),
        path: '/landing',
        element: <LandingPage />,
    },
    {
        name: 'myUsagePage',
        displayName: t('My Usage'),
        path: '/myUsage',
        element: <EnsureUser><MyUsagePage /></EnsureUser>,
        parentName: 'homePage',
        menu: {
            startIcon: <BarChartIcon />,
            order: 1,
            authenticationRequired: true,
            permissions: ["MyAccount:Read"],
            conditions: ["normal"],
        }
    },
    {
        name: 'outageMapPage',
        displayName: t('Outage Map'),
        path: '/outageMap',
        element: <OutageMapListPage />,
        parentName: 'homePage',
        menu: {
            startIcon: <CrisisAlertIcon />,
            modules: ['OutageManual'],
            order: 2,
            conditions: ["normal"],
        }
    },
    {
        name: 'impersonateOutageMapPage',
        displayName: t('Outage Map'),
        path: '/outageMap',
        element: <OutageMapListPage />,
        parentName: 'homePage',
        menu: {
            startIcon: <CrisisAlertIcon />,
            modules: ['OutageManual'],
            order: 2,
            conditions: ["impersonate"],
        }
    },
    {
        name: 'costPredictionCostPage',
        displayName: t('Cost Prediction'),
        path: '/costPrediction',
        element: <MyCostPredictionPage />,
        parentName: 'homePage',
        menu: {
            startIcon: <ShowChartIcon />,
            order: 3,
            modules: ['Cost'],
            authenticationRequired: true,
            conditions: ["normal"],
        }
    },
    {
        name: 'impersonateCostPredictionCostPage',
        displayName: t('Cost Prediction'),
        path: '/costPrediction',
        element: <MyCostPredictionPage />,
        parentName: 'homePage',
        menu: {
            startIcon: <ShowChartIcon />,
            order: 3,
            authenticationRequired: true,
            conditions: ["impersonate"],
        }
    },
    {
        name: 'myHelpRequestPage',
        displayName: t('Help'),
        path: '/helpRequest',
        element: <MyHelpRequestPage />,
        parentName: 'homePage',
        menu: {
            startIcon: <QuestionMarkIcon />,
            order: 4,
            authenticationRequired: true,
            conditions: ["normal"],
            modules: ['HelpRequest']
        }
    },
    // TODO: Resolve page fetching errors
    // {
    //     name: 'customerSummaryTabBillPeriod',
    //     displayName: t('Bill Comparison'),
    //     path: '/customer/billing/billingComparison',
    //     element: <CustomerSummaryTabBillPeriod />,
    //     menu: {
    //         parentMenu: 'billingMenu',
    //         startIcon: <DifferenceIcon />,
    //         order: 5,
    //         authenticationRequired: true,
    //         permissions: ["MyAccount:Read"],
    //         conditions: ["normal"],
    //     },
    //     parentName: 'homePage'
    // },
    {
        name: 'impersonateSummaryPage',
        displayName: t('My Usage'),
        path: '/customer/summary',
        element: <CustomerSummaryPage />,
        parentName: 'homePage',
        menu: {
            startIcon: <BarChartIcon />,
            order: 1,
            authenticationRequired: true,
            conditions: ["impersonate"],
        }
    },
    {
        name: 'notificationsPage',
        displayName: t('Notifications'),
        path: '/notifications',
        element: <UserNotificationPage />,
        parentName: 'homePage'
    },
    {
        name: 'myProfilePage',
        displayName: t('My Profile'),
        path: '/myProfile',
        element: <MyProfilePage />,
        parentName: 'homePage'
    },
    {
        name: 'OIDC SIGN OUT CALLABACK',
        displayName: t('Signed Out'),
        path: '/signout-callback-oidc',
        element: <HomePage />
    },
    // Admin Routes
    {
        name: 'adminMenu',
        displayName: t('Admin'),
        menu: {
            startIcon: <SettingsIcon />,
            conditions: ["normal", "displayNoAccountsAdmin"],
            collapse: true,
            order: 4
        }
    },
    {
        name: 'adminDashboardPage',
        displayName: t('Admin Dashboard'),
        path: '/admin',
        element: <AdminDashboardPage />,
        menu: {
            parentMenu: 'adminMenu',
            startIcon: <SpaceDashboardIcon />,
            permissions: ["RateClass:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 0
        },
    },
    {
        name: 'AccountsPage',
        displayName: t('Accounts'),
        path: '/admin/uc/accounts',
        element: <AccountListPage />,
        menu: {
            parentMenu: 'adminMenu',
            startIcon: <FolderSharedIcon />,
            permissions: ["Account:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 1
        },
        parentName: 'adminMenu'
    },
    {
        name: 'billingCyclesPage',
        displayName: t('Billing Cycles'),
        path: '/admin/billingCycles',
        element: <BillingCycleListPage />,
        menu: {
            parentMenu: 'adminMenu',
            startIcon: <EventNoteIcon />,
            permissions: ["BillingCycle:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 2
        },
        parentName: 'adminMenu'
    },
    {
        name: 'billingCycleDetailPage',
        displayName: t('Billing Cycle Details'),
        path: '/admin/billingCycles/:billingCycleId',
        element: <BillingCycleDetailsPage />,
        getPath: (id, name) => {
            return `/admin/billingCycles/${id}?name=${name}`;
        },
        parentName: 'billingCyclesPage'
    },
    {
        name: 'outagePage',
        displayName: t('Outages'),
        path: '/admin/uc/outages',
        element: <OutageListPage />,
        menu: {
            parentMenu: 'adminMenu',
            startIcon: <CrisisAlertIcon />,
            modules: ['OutageManual'],
            permissions: ["Outage:Read"],
            conditions: ["normal"],
            order: 3
        },
        parentName: 'adminMenu'
    },
    // Admin / Billing
    {
        name: 'adminBillingMenu',
        displayName: t('Billing'),
        menu: {
            parentMenu: 'adminMenu',
            startIcon: <PaidIcon />,
            conditions: ["normal", "displayNoAccountsAdmin"],
            permissions: ["Org:Modify"],
            modules: ['Billing'],
            order: 4
        },
        parentName: 'adminPage'
    },
    {
        name: 'paymentsPage',
        displayName: t('Payments'),
        path: '/admin/payments',
        element: <PaymentsPage />,
        menu: {
            parentMenu: 'adminBillingMenu',
            permissions: ["Org:Modify"],
            modules: ['Billing'],
            order: 0
        },
        parentName: 'adminBillingMenu'
    },
    {
        name: 'billApiHealthPage',
        displayName: t('Bill API Health'),
        path: '/admin/billApiHealth',
        element: <BillApiHealthListPage />,
        menu: {
            parentMenu: 'adminBillingMenu',
            permissions: ["Org:Modify"],
            modules: ['Billing'],
            order: 0
        },
        parentName: 'adminBillingMenu'
    },
     // Admin / Communications
    {
        name: 'communicationsMenu',
        displayName: t('Communications'),
        menu: {
            parentMenu: 'adminMenu',
            permissions: ["Announcement:Read", "OutgoingNotification:Read"],
            startIcon: <CampaignIcon />,
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 5
        },
        parentName: 'adminPage'
    },
    {
        name: 'AnnouncementPage',
        displayName: t('Announcements'),
        path: '/admin/announcements',
        element: <AnnouncementListPage/>,
        menu: {
            parentMenu: 'communicationsMenu',
            permissions: ["Announcement:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 1
        },
        parentName: 'communicationsMenu'
    },
    {
        name: 'outgoingNotificationsPage',
        displayName: t('Outgoing Notifications'),
        path: '/admin/uc/outgoingNotifications',
        element: <OutgoingNotificationListPage />,
        menu: {
            parentMenu: 'communicationsMenu',
            permissions: ["OutgoingNotification:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 2
        },
        parentName: 'communicationsMenu'
    },
    {
        name: 'outgoingNotificationDetailPage',
        displayName: t('Outgoing Notifications Details'),
        path: '/admin/uc/outgoingNotifications/:outgoingNotificationId',
        element: <OutgoingNotificationDetailsPage />,
        getPath: (id) => {
            return '/admin/uc/outgoingNotifications/' + id;
        },
        parentName: 'outgoingNotificationsPage'
    },

    // Admin / Users & Roles
    {
        name: 'usersAndRolesMenu',
        displayName: t('Users & Roles'),
        menu: {
            parentMenu: 'adminMenu',
            permissions: ["User:Read", "RateClass:Modify", "Org:Read", "Role:Read"],
            startIcon: <GroupsIcon />,
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 6
        },
        parentName: 'adminPage'
    },
    {
        name: 'residentialUsersPage',
        displayName: t('Residential Users'),
        path: '/admin/uc/residentialUsers',
        element: <ResidentialUserListPage />,
        menu: {
            parentMenu: 'usersAndRolesMenu',
            permissions: ["User:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 0
        },
        parentName: 'usersAndRolesMenu'
    },
    {
        name: 'adminUsersPage',
        displayName: t('Admin Users'),
        path: '/admin/uc/adminUsers',
        element: <AdminUserListPage />,
        menu: {
            parentMenu: 'usersAndRolesMenu',
            permissions: ["RateClass:Modify"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 1
        },
        parentName: 'usersAndRolesMenu'
    },
    {
        name: 'allUsersPage',
        displayName: t('All Users'),
        path: '/admin/uc/allUsers',
        element: <SuperAdminUserListPage />,
        menu: {
            parentMenu: 'usersAndRolesMenu',
            permissions: ["Org:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 2
        },
        parentName: 'usersAndRolesMenu'
    },
    {
        name: 'userDetailsPage',
        displayName: t('User Details'),
        path: '/admin/uc/users/:userId',
        element: <UserDetailsPage />,
        getPath: (id) => {
            return '/admin/uc/users/' + id;
        },
        parentName: 'usersPage'
    },
    {
        name: 'rolesPage',
        displayName: t('Roles'),
        path: '/admin/uc/roles',
        element: <RoleListPage />,
        menu: {
            parentMenu: 'usersAndRolesMenu',
            permissions: ["Role:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 3
        },
        parentName: 'usersAndRolesMenu'
    },
    {
        name: 'roleDetailsPage',
        displayName: t('Role Details'),
        path: '/admin/uc/roles/:roleId',
        element: <RoleDetailsPage />,
        getPath: (id) => {
            return '/admin/uc/roles/' + id;
        },
        parentName: 'rolesPage'
    },

    // Admin / Utility Settings
    {
        name: 'utilityMenu',
        displayName: t('Utility Settings'),
        menu: {
            parentMenu: 'adminMenu',
            permissions: ["MyOrg:Modify", "Org:Read"],
            startIcon: <ApartmentIcon />,
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 7
        },
        parentName: 'adminPage'
    },
    {
        name: 'utilityDashboardPage',
        displayName: t('Utility Dashboard'),
        path: '/admin/utilityDashboard',
        element: <UtilityDashboardPage />,
        menu: {
            parentMenu: 'utilityMenu',
            permissions: ["MyOrg:Modify"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 0
        },
    },
    {
        name: 'myOrganizationPage',
        displayName: t('My Organization'),
        path: '/admin/uc/myOrg',
        element: <MyOrg />,
        menu: {
            parentMenu: 'utilityMenu',
            permissions: ["MyOrg:Modify"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 1
        },
        parentName: 'utilityMenu'
    },
    {
        name: 'organizationsPage',
        displayName: t('Organizations'),
        path: '/admin/uc/orgs',
        element: <OrgListPage />,
        menu: {
            parentMenu: 'utilityMenu',
            permissions: ["Org:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 2
        },
        parentName: 'utilityMenu'
    },
    {
        name: 'helpRequestsPage',
        displayName: t('Help Requests'),
        path: '/admin/uc/helpRequests',
        element: <HelpRequestListPage />,
        menu: {
            parentMenu: 'utilityMenu',
            permissions: ["HelpRequest:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            modules: ['HelpRequest'],
            order: 3
        },
        parentName: 'utilityMenu'
    },
    {
        name: 'ipSpamWatchlistPage',
        displayName: t('IP Spam Watchlist'),
        path: '/admin/ipSpamWatchlist',
        element: <BlacklistedIpAddressListPage />,
        menu: {
            parentMenu: 'utilityMenu',
            permissions: ["BlacklistedIpAddress:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 4
        },
        parentName: 'utilityMenu'
    },
    {
        name: 'orgModulesPage',
        displayName: t('Module Setup'),
        path: '/admin/uc/orgModuleSetup',
        element: <OrgModuleListPage />,
        menu: {
            parentMenu: 'utilityMenu',
            modules: ['ModuleSetupPage'],
            permissions: ["OrgModule:Read"],
            conditions: ["normal"],
            order: 5
        },
        parentName: 'utilityMenu'
    },

    // Admin / Rates
    {
        name: 'ratesMenu',
        displayName: t('Rates'),
        menu: {
            parentMenu: 'adminMenu',
            permissions: ["Holiday:Read", "RateClassChangeRequest:Read", "RateClass:Read"],
            startIcon: <TrendingUpIcon />,
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 8
        },
        parentName: 'adminPage'
    },
    {
        name: 'HolidayPage',
        displayName: t('Holidays'),
        path: '/admin/Holidays',
        element: <HolidayListPage />,
        menu: {
            parentMenu: 'ratesMenu',
            permissions: ["Holiday:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 0
        },
        parentName: 'ratesMenu'
    },
    {
        name: 'rateClassChangeRequestPage',
        displayName: t('Rate Change Requests'),
        path: '/admin/rateClassChangeRequests',
        element: <RateClassChangeRequestListPage />,
        menu: {
            parentMenu: 'ratesMenu',
            permissions: ["RateClassChangeRequest:Read"],
            modules: ['RateClassComparison'],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 1
        },
        parentName: 'ratesMenu'
    },
    {
        name: 'rateClassesPage',
        displayName: t('Rate Classes'),
        path: '/admin/rateClasses',
        element: <RateClassListPage />,
        menu: {
            parentMenu: 'ratesMenu',
            permissions: ["RateClass:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 2
        },
        parentName: 'ratesMenu'
    },
    {
        name: 'rateVersionListPage',
        displayName: t('Rate Versions'),
        path: '/admin/rateClasses/:rateClassId',
        element: <RateVersionListPage />,
        getPath: (id, name) => {
            return `/admin/rateClasses/${id}?name=${name}`;
        },
        parentName: 'rateClassesPage'
    },
    {
        name: 'rateExpirationPage',
        displayName: t('Rate Expiration'),
        path: '/admin/rateExpiration',
        element: <AdminRateExpirationListPage />,
        menu: {
            parentMenu: 'ratesMenu',
            permissions: ["RateClass:Read"],
            conditions: ["normal", "displayNoAccountsAdmin"],
            order: 3
        },
        parentName: 'ratesMenu'
    },
    ...devMenu
];

let routePathsWithNames = {};

routeDetails.forEach(route => {
    // If the current route has a parentName property, we populate a parentObj property
    if (route.parentName != null) {
        const parentObj = routeDetails.find(r => r.name === route.parentName);

        route.parentObj = parentObj;
    }

    routePathsWithNames[route.name] = route;
});

export const routePaths = routePathsWithNames;

export const getMenuItems = (userInfo, parentMenuName, allowedPermissions, allowedModules, hiddenRoutes, condition = "normal") => {

    const menuItems = routeDetails.filter(route => {
        if (!route.menu) {
            return false;
        }

        if (hiddenRoutes != null && hiddenRoutes.includes(route.name)) {
            return false;
        }

        let conditions = route.menu.conditions || ["normal"];
        if (conditions.indexOf(condition) < 0)
        {
            return false;
        }

        if (route.menu.authenticationRequired !== undefined && route.menu.authenticationRequired !== userInfo.isLoggedIn) {
            return false;
        }

        const hasCorrectParent = (route.menu.parentMenu === parentMenuName ||
            (!route.menu.parentMenu && !parentMenuName));

        if (!hasCorrectParent) {
            return false;
        }

        const hasPermission = !route.menu.permissions || (
            allowedPermissions?.some(r => route.menu.permissions.includes(r.permission))
        );

        const hasModule = !route.menu.modules || (
            route.menu.modules.every(m => allowedModules?.some(r => r.module === m))
        );


        return route.menu && hasPermission && hasModule;
    });

    menuItems.sort(function(a, b) {
        return parseFloat(a.menu.order) - parseFloat(b.menu.order);
    });

    return menuItems;
}

export const buildBreadcrumbs = (routePath, breadcrumbs = []) => {
    if (routePath !== null) {
        if (routePath.parentObj === null || routePath.parentObj === undefined) {
            return routePath;
        }
        else {
            breadcrumbs.push(routePath.parentObj);
            buildBreadcrumbs(routePath.parentObj, breadcrumbs);
        }
    }

    return breadcrumbs;
}

export const getCurrentRoutePathFromPageState = (pageState) => {
    let result = {};

    Object.entries(routePathsWithNames).forEach(([key, value]) => {
        if (value.displayName.toLowerCase() === pageState.header.toLowerCase() || t(value.displayName) === pageState.header) {
            let routeObj = routePathsWithNames[key];

            if (pageState.variableHeader !== "") {
                routeObj['variableHeader'] = pageState.variableHeader;
            }

            result = routeObj;
        }
    });

    return result;
}

export const getRouteParent = (parentMenuName) => {
    let filteredRoutes = routeDetails.filter(route =>
        route.menu && (
            route.menu.parentMenu === parentMenuName ||
                (!route.menu.parentMenu && !parentMenuName))
        );
    return filteredRoutes;
}

// eg:  routePaths.customerSummaryPage.path