import '../common/UcLegend.scss';
import {
    Commit as CommitIcon,
} from '@mui/icons-material'

interface UcLegendV2Props {
    iconSize?: number;
    payload?: any
}

interface UcLegendV2Item {
    color: string,
    originalName: string,
    showOnUsageChart?: boolean,
    type: string,
    value: string
}

export const UcLegendV2 = (props: UcLegendV2Props) => {
    const { payload, iconSize = 14 } = props;

    const getIcon = (entry: any) => {
        if (entry.type === "line") {
            return <div style={{ display: 'inline-block', width: iconSize * 1.5, height: iconSize, backgroundColor: 'transparent', overflow: 'hidden' }}>
                <CommitIcon style={{ width: '115%', height: '115%', marginBottom: 10, fill: entry.color }} />
            </div>;
        }
        else {
            // default to 'rect'
            return <div style={{ display: 'inline-block', width: iconSize, height: iconSize, backgroundColor: entry.color, borderRadius: '10px' }}></div>;
        } 
    }

    const cleanArray = payload 
        ?.filter((fullItem: any) => fullItem.payload?.showOnUsageChart !== false)
        ?.map((fullItem: any) => {
            return ({
                originalName: fullItem.value,
                value: fullItem.payload?.displayName,
                color: fullItem.color,
                type: fullItem.type,
                showOnUsageChart: fullItem.payload?.showOnUsageChart
            });
        })
        ?.filter((item: UcLegendV2Item, index: number, self: any) => // Filter out duplicates based on color & value (name)
            index === self.findIndex((t: any) => (t.color === item.color) && (t.value.toLowerCase() === item.value.toLowerCase()))
        );

    return (
        <ul className="ucLegend">
            {cleanArray?.map((item: UcLegendV2Item, index: number) =>
                <li key={`item-${item.originalName}-${item.value}`}>
                    {getIcon(item)} {item.value}</li>
            )}
        </ul>
    );
  }

export default UcLegendV2;


