import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import { CSVLink } from 'react-csv';
import IconButton from '@mui/material/IconButton';
import { Download as DownloadIcon } from '@mui/icons-material'
import { useTranslation } from "react-i18next";
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
// @ts-ignore
import { getPrecipUnit } from '../../lib/Formatters';

export const UcButtonChartDownloadV2 = (props: any) => {
    const { t } = useTranslation();
    const { data, filename, palletColor, selectedMeter, uom, csvTempUom, hasCostModule } = props;
    const csvLink = React.useRef<any>();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleCSVClick = () => {
        csvLink.current.link.click();
        setAnchorEl(null);
    }

    const handleXMLClick = () => {
        props.onXMLClick();
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!props.isXmlGenerating) {
            setAnchorEl(null);
        }
    }, [props.isXmlGenerating]);

    const modifiedData = data.map((item: any) => {
        let result: any = {};
        result["Meter Number"] = selectedMeter;
        result["Interval"] = item.label;
        result[`Total Usage (${uom})`] = item.totalUsage?.toFixed(2);
        if (hasCostModule) {
            result["Total Cost ($)"] = item.totalCost?.toFixed(2);
        }
        result["Billing Period Start"] = item.billingPeriodStart;
        result["Billing Period End"] = item.billingPeriodEnd;
        result[`Avg. Temperature (${"\u00B0" + csvTempUom})`] = item.temp?.toFixed(1);
        result[`Precipitation (${getPrecipUnit(csvTempUom)})`] = item.precip?.toFixed(2);

        item?.tooltipSummary?.forEach((rate: any) => {
            result[`${rate.name} - Rate ($)`] = rate.rate?.toFixed(2);
            result[`${rate.name} - Usage (${uom})`] = rate.usage?.toFixed(2);
            if (hasCostModule) {
                result[`${rate.name} - Cost ($)`] = rate.cost?.toFixed(2);
            }
        });

        return result;
    });

    return (
        <>
            <IconButton
                sx={{ color: palletColor }}
                onClick={handleClick}
                title={t('Download')}
            >
                <DownloadIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <LoadingButton loading={props.isXmlGenerating} onClick={handleCSVClick} style={{ display: 'inline-block', width: '100%', textAlign: 'justify' }} >
                    <span>
                        <img src="/csv-logo.svg" style={{ margin: '-5px 7px -5px 5px' }}
                            alt="CSV Logo" width="35" height="35" />CSV
                    </span>
                </LoadingButton>
                <Divider />
                <LoadingButton loading={props.isXmlGenerating} onClick={handleXMLClick} style={{ display: 'inline-block', width: '100%', textAlign: 'justify' }} >
                    <span>
                        <img src="/gba-logo.png" style={{ margin: '0 0 -13px -5px' }}
                            alt="GreenButton Logo" width="55" height="40" />GreenButton (XML)
                    </span>
                </LoadingButton>
            </Menu>
            <CSVLink style={{ display: "none" }}
                data={modifiedData}
                filename={filename}
                ref={csvLink}
            />
        </>
    )
}

UcButtonChartDownloadV2.propTypes = {
    filename: PropTypes.string.isRequired
};