import { useContext, useState, useLayoutEffect, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import { UcWidget } from '../common/UcWidget';
import { AccountContext, AccountContextType } from '../../lib/AccountProvider';
import EnsureUserAndModule from 'components/auth/EnsureUserAndModule';
import { saveMyHelpRequest } from './MyHelpRequestAPIEndpoints';
import { MyHelpRequestForm } from './MyHelpRequestForm';
import LoadingButton from '@mui/lab/LoadingButton';
//@ts-ignore
import { useUser } from 'components/auth/PermissionApiEndpoint';
import { useSWRConfig } from "swr";
import { mutateUrls } from "../../lib/useGenericSWR";
import { OrgAppSettingsContext } from 'lib/OrgAppSettingsProvider';
import { Alert } from '@mui/material';
import { PageContext, PageState, PageAction } from '../../lib/PageProvider';

export const MyHelpRequestPage = () => {
    const { account } = useContext<AccountContextType>(AccountContext);
    const { t } = useTranslation();
    const user = useUser();
    const [reMutateUrls, setReMutateUrls] = useState(false);
    const swrConfig = useSWRConfig();
    const [saving, setSaving] = useState(false);
    const [dialogSubmit, setDialogSubmit] = useState(0);
    const { orgAppSettings } = useContext(OrgAppSettingsContext);
    const [pageState, pageDispatch] = useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);

    const title = t("Need Help?");
    let formIteration = 0;

    const orgAppInfo : any = orgAppSettings?.result;
    const supportEmailSetupStatus = orgAppInfo?.supportEmail && orgAppInfo?.supportEmail.trim() !== '';

    let itemToEdit = {
        formIteration: "new" + formIteration,
        id: 0,
        cepOrgId: user?.cepOrgId,
        name: "",
        accountNumber: "",
        email: user?.email,
        subject: "",
        question: ""
    }

    const handleSave = () => {
        setDialogSubmit(dialogSubmit + 1);
    };

    useLayoutEffect(() => {
        if (reMutateUrls) {
            mutateUrls(swrConfig);
            setReMutateUrls(false);
        }
    }, [reMutateUrls, swrConfig]);

    useEffect(() => {
        pageDispatch({
            header: t(''),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => { },
            tabValue: ''
        });
    }, [pageState.header, pageDispatch, t]);

    return (
        <EnsureUserAndModule modules={['HelpRequest']}>
            <UcWidget
                title={title}
                contentPadding='0 0 0 0'
                alignTitleLeft={true}
                popoverTitle={undefined}
                subTitle={supportEmailSetupStatus && t("If you have any questions regarding your utility services or about your account please submit them below.")}
            >
                <Box sx={{ flexGrow: 1 }}>
                    {supportEmailSetupStatus ? (
                        <>
                            <MyHelpRequestForm
                                account={account}
                                itemToEdit={itemToEdit}                      
                                dialogSubmit={dialogSubmit}
                                onSubmit={async (values: any) => {
                                    setSaving(true);
                                    try {
                                        await saveMyHelpRequest(values);

                                    } finally {
                                        setSaving(false);
                                        setReMutateUrls(true);
                                    }
                                }}
                                onError={(error: any) => {
                                    setSaving(false);
                                }}
                                disabled={false}
                            />
                            <LoadingButton
                                sx={{ margin: '15px' }}
                                loading={saving}
                                onClick={handleSave}
                                variant="contained"
                                title={t("Submit") }
                            >
                                {t('Submit')}
                            </LoadingButton>
                        </>
                    ) : (
                        <Alert severity="error">{t("You do not have access to this page.")}</Alert>
                    )}
                    
                </Box>
            </UcWidget>
        </EnsureUserAndModule>
    );
}