import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useDebouncedValue } from '@mantine/hooks';
import { useMyAccounts } from './MyAccountAPIEndpoints';
import { dateFormatter } from 'lib/Formatters';
import { useTranslation } from "react-i18next";
import { ensureLuxonDate } from '../../../lib/Formatters';
import { useMyNow, useMyTimezone } from '../orgs/MyOrgAPIEndpoints';

// From: https://mui.com/components/autocomplete/#Asynchronous.js
export const MyAccountSelect = (props) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState(props.value || null);
    const needsUpdate = React.useRef(true);
    const firstValueSet = React.useRef(false);
    const { t } = useTranslation();

    const [filter, setFilter] = React.useState({
        take: 100,
        skip: 0,
        sort: [{ field: "accountName", sort: "asc" }],
        generalSearch: "",
        userId: props.userId,
        allowDelegations: props.allowDelegations || false
    });

    const today = useMyNow();
    const timezone = useMyTimezone();

    let loading = true;
    let loadedOptions = [];
    const swrMyAccounts = useMyAccounts(filter);

    if (swrMyAccounts.result && !swrMyAccounts.isLoading && !swrMyAccounts.isError)
    {
        loadedOptions = swrMyAccounts.result;
        loading = false;

        let delegatesJson = [];
        let isImpersonate = false;
        let impersonateArray = [];

        loadedOptions.forEach((option, index) => {
            if (option.delegatesJson) {
                delegatesJson = JSON.parse(option.delegatesJson);
                if (delegatesJson.filter(r => r.isImpersonate).length > 0 && delegatesJson.find(r => r.isImpersonate)?.email?.toLowerCase() === props.userEmail?.toLowerCase()) {
                    impersonateArray.push(option);
                    isImpersonate = true;
                }
            }
        })

        impersonateArray = impersonateArray.reduce((unique, o) => { // remove duplicates from array
            if (!unique.some(obj => obj.accountId === o.accountId)) {
                unique.push(o);
            }
            return unique;
        }, []);

        if (needsUpdate.current) {
            needsUpdate.current = false;

            if (isImpersonate) {
                setOptions([...impersonateArray]);
            }
            else {
                setOptions([...loadedOptions]);
            }
        }
    }
    else
    {
        needsUpdate.current = true;
        loading = true;
    }

    const propOnChange = props.onChange;

    React.useEffect(() => {
        // Set the value to the first option if it is not set
        if (!value && options && options.length >= 1)
        {
            // Only do this once
            if (!firstValueSet.current)
            {
                firstValueSet.current = true;
                // Adding this auto select causes a strange error when switching tabs
                propOnChange(null, options[0]);
                setValue(options[0]);
            }
        }
        else if (value) {
            firstValueSet.current = true;
        }
    }, [options, value, propOnChange]);

    const [generalSearch, setGeneralSearch] = React.useState(filter.generalSearch);
    const [debounced] = useDebouncedValue(generalSearch, 500);

    React.useEffect(() => {
        if (filter.generalSearch !== debounced) {
            needsUpdate.current = true;
            setFilter({
                ...filter,
                generalSearch: debounced,
            });
        }
    }, [debounced, filter]);

    const getOptionLabel = (option, showNicknameHover = true) => {
        const metersJson = JSON.parse(option.metersJson) || [];
        let delegatesJson = [];
        let isImpersonate = false;

        if (option.delegatesJson) {
            delegatesJson = JSON.parse(option.delegatesJson);
            if (delegatesJson.filter(r => r.isImpersonate).length > 0 && delegatesJson.find(r => r.isImpersonate)?.email?.toLowerCase() === props.userEmail?.toLowerCase()) {
                isImpersonate = true;
            }
        }

        const startTimestampAll = metersJson.map(r => r.startTimestamp);
        const endTimestampAll = metersJson.map(r => r.endTimestamp);
        const startTimestamp = startTimestampAll.reduce(function (a, b) { return a < b ? a : b; }); // Grab the earliest start date
        const endTimestamp = endTimestampAll.reduce(function (a, b) { return a < b ? b : a; }); // Grab the latest end date

        const endTimestampDate = ensureLuxonDate(endTimestamp).setZone(timezone).startOf('day');
        const todayStartOf = today.startOf('day');

        const diffDays = endTimestampDate.diff(todayStartOf, 'days');
        const hasEndTime = diffDays.days < 0;

        let dateString = "(" + dateFormatter(startTimestamp, true);
        if (hasEndTime) {
            dateString += " to " + dateFormatter(endTimestamp, true) + ")";
        }
        else {
            dateString += " to present)";
        }
        let addressString = (option.accountAddress ?? "N/A");

        let accountNumber = option.extAccountNumber
        
        let dropdownString = "";
        if (props.showFullLabel) {
            dropdownString = option.accountName + " - " + addressString;
        }
        else if (props.showAddressOnly) {
            dropdownString = addressString;
        }

        if (props.showAccountNumber) {
            dropdownString += " ("+accountNumber+")";
        }
        if (props.showDate) {
            dropdownString +=  " " + dateString;
        }
        if (props.showNickname && option.accountNickname && showNicknameHover) {
            dropdownString = option.accountNickname;
        }

        if (isImpersonate) {
            dropdownString += " - " + t("Impersonated");
        }
        else if (option.delegatesJson !== undefined) {
            if (option.username?.toLowerCase() !== props.userEmail?.toLowerCase()) {
                dropdownString += " - " + t("Delegate");
            }           
        }

        return dropdownString;
    }

    return (
        <Autocomplete
            disableClearable={props.disableClearable}
            disabled={props.isDisabled}
            id={props.id || "myAccountSelect"}
            open={open}
            value={value}
            size={props.size || 'medium'}
            onChange={(event, newValue) => {
                if (newValue !== null) {
                    setValue(newValue);
                    props.onChange(event, newValue);
                }
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                if (filter.generalSearch !== "")
                {
                    needsUpdate.current = true;
                    setFilter({...filter, generalSearch: ""});
                }
                setOpen(false);
            }}
            isOptionEqualToValue={(option, autoValue) => option.id === autoValue.id}
            getOptionLabel={getOptionLabel}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={props.sx}
                    onChange={(e) => {
                        setGeneralSearch(e.target.value);
                    }}
                    label={props.label || "My Accounts"}
                    title={value ? getOptionLabel(value, false) : ''}
                    InputProps={{
                        ...params.InputProps,
                        inputProps: {
                            ...params.inputProps,
                            readOnly: props.readOnly || false,
                        },
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

MyAccountSelect.defaultProps = {
    onChange: (event, value) => { console.log("myAccountSelect onChange not implemented", event, value); },
};