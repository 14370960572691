// @ts-ignore
import { DateTime } from 'luxon';
import { Account } from '../../lib/AccountProvider';
import { AccountMeter } from '../user/MyUserAccount.interfaces';
// @ts-ignore
import { uomToCommodity } from '../../lib/Formatters';
// @ts-ignore
import { AxisCalc } from '../../lib/AxisCalc';

export const UsageHelpers = {
    // Add hours always necessry?
    createDateFromObject: (year: number, month: number, day: number, timezone: string, type: string = 'none', hoursToAdd: number = 1) => {
        const dt = DateTime.fromObject({ year, month, day }, { zone: timezone });

        switch (type) {
            case 'yearStart':
                return dt.minus({ months: 12 }).startOf("month").toJSDate();
            case 'yearEnd':
                return dt.endOf("month").toJSDate();
            case 'monthStart':
                return dt.startOf("month").toJSDate();
            case 'monthEnd':
                return dt.plus({ months: 1 }).toJSDate();
            case 'hourStart':
                return dt.startOf("day").plus({ hours: hoursToAdd }).toJSDate();
            case 'hourEnd':
                return dt.endOf("day").plus({ hours: hoursToAdd }).toJSDate();
            default:
                return dt.toJSDate(); // Fallback if no type matches
        }
    },
    getGeoCoordinates: (account: Account | null, uom: string) => {
        if (!account) {
            return [];
        }

        const result = JSON.parse(account?.metersJson)
            .filter((meter: AccountMeter) =>
                meter.latitude !== undefined &&
                meter.longitude !== undefined &&
                meter.meterCommodity?.trim().toUpperCase() === uomToCommodity(uom)?.trim().toUpperCase()
            )
            .map((meter: AccountMeter) => ({
                latitude: meter.latitude,
                longitude: meter.longitude,
            }));
        return result;
    },
    calculateColumnsToShow: (groupBy: string, isMobile: boolean, totalColumns: number) => {
        switch (groupBy) {
            case "none":
                return {
                    columnsToShow: isMobile ? 13 : 24,
                    startChartScroll: Math.max(totalColumns - (isMobile ? 13 : 24), 0),
                };
            case "day":
                return {
                    columnsToShow: isMobile ? 14 : 31,
                    startChartScroll: Math.max(totalColumns - (isMobile ? 14 : 31), 0),
                };       
            default:
                return {
                    columnsToShow: 13,
                    startChartScroll: Math.max(totalColumns - 13, 0),
                };
        }
    },
    calculateAxisDomains: (data: any) => {
        const defaultValues = {
            minTotalUsage: 0, maxTotalUsage: 0,
            minTotalCost: 0, maxTotalCost: 0,
            minPrecip: 0, maxPrecip: 0,
            minTemp: 0, maxTemp: 0
        };

        const result = data?.reduce(
            (acc: any, dataPoint: any) => ({
                minTotalUsage: Math.min(acc.minTotalUsage, dataPoint.totalUsage ?? Infinity),
                maxTotalUsage: Math.max(acc.maxTotalUsage, dataPoint.totalUsage ?? -Infinity),
                minTotalCost: Math.min(acc.minTotalCost, dataPoint.totalCost ?? Infinity),
                maxTotalCost: Math.max(acc.maxTotalCost, dataPoint.totalCost ?? -Infinity),
                minPrecip: Math.min(acc.minPrecip, dataPoint.precip ?? Infinity),
                maxPrecip: Math.max(acc.maxPrecip, dataPoint.precip ?? -Infinity),
                minTemp: Math.min(acc.minTemp, dataPoint.minTemp ?? dataPoint.temp ?? Infinity),
                maxTemp: Math.max(acc.maxTemp, dataPoint.maxTemp ?? dataPoint.temp ?? -Infinity),
            }),
            defaultValues
        ) || defaultValues;

        const usageAxisCalc = new AxisCalc(result.minTotalUsage, result.maxTotalUsage);
        const costAxisCalc = new AxisCalc(result.minTotalCost, result.maxTotalCost);
        const precipAxisCalc = new AxisCalc(result.minPrecip, result.maxPrecip);
        const tempAxisCalc = new AxisCalc(result.minTemp, result.maxTemp);

        if (result.minTotalUsage >= 0) {
            usageAxisCalc.min_value = 0;
        }
        if (result.minPrecip >= 0) {
            precipAxisCalc.min_value = 0;
        }

        return {
            totalUsageDomain: {
                min: usageAxisCalc.min_value,
                max: usageAxisCalc.max_value,
            },
            totalCostDomain: {
                min: costAxisCalc.min_value,
                max: costAxisCalc.max_value,
            },
            precipDomain: {
                min: (precipAxisCalc.min_value === 0 && precipAxisCalc.max_value === 0) ? result.minPrecip : precipAxisCalc.min_value,
                max: (precipAxisCalc.min_value  === 0 && precipAxisCalc.max_value === 0) ? result.maxPrecip : precipAxisCalc.max_value,
            },
            tempDomain: {
                min: tempAxisCalc.min_value,
                max: tempAxisCalc.max_value,
            }
        };
    }
}