import { EnsureUser } from "components/auth/EnsureUser";
import { UcTabStrip } from '../common/UcTabStrip';
import { UcMobileNavPage } from '../common/UcMobileNavPage';
import { PageContext } from "lib/PageProvider";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MyUser } from "./MyUser";
import { MyUserAccountList } from "./MyUserAccountList";
import { useResponsive } from 'lib/useResponsive';
import { useStatefulParam } from 'lib/useStatefulParam';
import { MyPreferences } from "./MyPreferences";
import { MyUserMFASettings } from "./MyUserMFASettings";
import { UcWidget } from '../common/obsolete/UcWidget';
import { useIsSuperAdmin } from "lib/useIsUserRole";
import { useTheme } from '@mui/material/styles';


export const MyProfilePage = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const statefulParam = useStatefulParam({paramName: "profileNav_link", paramValue: 'Details'});
    const [pageState, pageDispatch] = useContext(PageContext);
    const params = new URLSearchParams(window.location.search);
    const isSuperAdmin = useIsSuperAdmin();
    const theme = useTheme();

    const views = useMemo(() => {
        const commonViews = [
            {
                title: t("Details"),
                subtitle: t("Manage your profile and change your password"),
                tabRender: <div>{t("Details")}</div>,
                contentRender: <MyUser />
            },
            {
                title: t("MFA Settings"),
                subtitle: t("Manage your Multi-factor authentication settings"),
                tabRender: <div>{t("MFA Settings")}</div>,
                contentRender: <MyUserMFASettings />
            },
            {
                title: t("Accounts"),
                subtitle: t("View account information associated with user"),
                tabRender: <div>{t("Accounts")}</div>,
                contentRender: <MyUserAccountList onlyAccounts={isMobile}/>
            },
            {
                title: t("Preferences"),
                subtitle: t("Manage your user preferences"),
                tabRender: <div>{t("Preferences")}</div>,
                contentRender: 
                    <MyPreferences 
                        showLanguageToggle={false}
                        showDebug={isSuperAdmin}
                        showDarkModeToggle={false}
                        showTemperatureToggle={false}
                    />
            }
        ];

        const mobileViews = [
            {
                title: t("Shared Accounts"),
                subtitle: t("View account information shared with other users"),
                tabRender: <div>{t("Accounts")}</div>,
                contentRender: <MyUserAccountList onlySharedAccounts={true}/>
            }
        ];

        if (isMobile) {
            commonViews.splice(3, 0, ...mobileViews);
            return commonViews;
        }
        else {
            return commonViews;
        }
    }, [isMobile, t, isSuperAdmin]);

    useEffect(() => {
        pageDispatch({
            header: t("My Profile"),
            tabs: views
        });
    }, [pageState.header, pageDispatch, t, views]);

    console.log("MyProfilePage - Loaded");
    return (
        
            <EnsureUser>
                { isMobile && (
                    <UcMobileNavPage
                        pageTitle={t("Profile Settings")}
                        onChange={statefulParam.onSetValue}
                        views={views}
                        value={params.get("profileNav_link") !== null ? statefulParam.value : 0}
                        contentBackgroundColor={theme.palette.background.default}
                    />
                )}
                { !isMobile && statefulParam.isRenderReady && (
                    <UcWidget contentPadding={0}>
                        <UcTabStrip
                            onChange={statefulParam.onSetValue}
                            showInMenu={true}
                            tabs={views}
                            value={statefulParam.value}
                        />
                    </UcWidget>
                )}
            </EnsureUser>
    );
}