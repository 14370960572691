import React, { useRef, useContext } from 'react';
import { Formik, Form, Field, FormikProps } from 'formik';
// @ts-ignore
import { ensureLuxonDateOnly } from '../../lib/Formatters';
// @ts-ignore
import Grid from '@mui/material/Grid';
// @ts-ignore
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { UcDatePickerFormik } from 'components/common/UcDatePicker';
// @ts-ignore
import { DateTime } from 'luxon';
import { filterTypes } from '../../utils/Enums';
// @ts-ignore
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
// @ts-ignore
import { BillPeriodAdminSelect } from 'components/admin/Customers/BillPeriodAdminSelect'
import { AccountContext } from 'lib/AccountProvider';
import Alert from '@mui/material/Alert';

interface CustomerSummaryFilterValues {
    account: any;
    serialNo?: string;
    startDate: Date | number;
    endDate: Date | number;
    endingOn: Date;
    endingOnMonthly: Date;
    endingOnYearly: Date;
}

interface CustomerSummaryFilterProps {
    dialogSubmit: any;
    serialNo: any;
    chartType: any;
    defaultValues: CustomerSummaryFilterValues;
    uom: string;
    onSubmit(values: CustomerSummaryFilterValues): void;
}

export const CustomerSummaryFilter = (props: CustomerSummaryFilterProps) => {
    const dialogSubmit = useRef(props.dialogSubmit);
    const { t } = useTranslation();
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const serialNo = props.serialNo || null;
    const chartType = props.chartType;
    const formRef = useRef<FormikProps<CustomerSummaryFilterValues>>(null);
    const { account } = useContext(AccountContext);

    const minDate = account?.minStartTimestamp ? new Date(account.minStartTimestamp) : new Date();
    const maxDate = account?.maxEndTimestamp ? new Date(account.maxEndTimestamp) : new Date();

    const [areBillingPeriods, setAreBillingPeriods] = React.useState(true);

    const timezone = useMyTimezone();

    React.useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    const inputFormat = () => {
        switch (chartType) {
            case filterTypes.DAY:
                return "MMM/DD/YYYY";
            case filterTypes.YEAR:
            case filterTypes.MONTH:
            default:
                return "MMM/YYYY";
        }
    }

    const dateViews = () => {
        let views = ['day'];

        if (chartType === filterTypes.YEAR || chartType === filterTypes.MONTH) {
            views = ['month', 'year'];
        }

        return views;
    }

    function getChartMaxDaysLabel(chartType: any): string {
        if (chartType === filterTypes.YEAR) {
            return t("Month/Year (Past {{maxDays}} Months)", { maxDays: 12 });
        }
        if (chartType === filterTypes.MONTH) {
            return t("Billing Period");
        }
        return t("Past {{maxHours}} Hours (Day Start to End)", { maxHours: 24 });
    }

    const handleChangeEndingOn = function (value: any) {
        if (formRef.current !== null && formRef.current !== undefined) {
            formRef.current.values.endingOn = DateTime.fromJSDate(new Date(value)).toUTC().toISO();
        }
    }

    const handleChangeEndingOnMonthly = function (value: any) {
        if (formRef.current !== null && formRef.current !== undefined) {
            formRef.current.values.endingOnMonthly = DateTime.fromJSDate(new Date(value)).toUTC().toISO();
        }
    }

    const handleChangeEndingOnYearly = function (value: any) {
        if (formRef.current !== null && formRef.current !== undefined) {
            formRef.current.values.endingOnYearly = DateTime.fromJSDate(new Date(value)).toUTC().toISO();
        }
    }

    return (
        <div className="customerSummaryFilter ignoreCustomFormStyles">
            <Formik
                innerRef={formRef}
                initialValues={props.defaultValues}
                onSubmit={(values) => {
                    switch (chartType) {
                        case filterTypes.YEAR:
                            const endingOnDateTimeYearly = DateTime.fromISO(values.endingOnYearly, { zone: timezone });
                            let yearStart = endingOnDateTimeYearly.minus({ months: 12 }).startOf("month").toJSDate();
                            const yearEnd = endingOnDateTimeYearly.endOf("month").toJSDate();

                            if (yearStart < minDate) {
                                yearStart = DateTime.fromObject({ year: minDate.getFullYear(), month: minDate.getMonth() + 1, day: minDate.getDate() }, { zone: timezone }).startOf("month").toJSDate();
                            }

                            values.startDate = yearStart;
                            values.endDate = yearEnd;

                            break;
                        case filterTypes.MONTH:
                            const endingOnDateTimeMonthly = DateTime.fromISO(values.endingOnMonthly, { zone: timezone });
                            const monthStart = endingOnDateTimeMonthly.toJSDate();
                            const monthEnd = endingOnDateTimeMonthly.plus({ months: 1 }).toJSDate();

                            values.startDate = monthStart;
                            values.endDate = monthEnd;

                            break;
                        case filterTypes.DAY:
                            const endingOnDateTime = DateTime.fromISO(values.endingOn, { zone: timezone });
                            const dayStart = endingOnDateTime.startOf("day").plus({ hours: 1 }).toJSDate();
                            const dayEnd = endingOnDateTime.endOf("day").plus({ hours: 1 }).toJSDate();

                            values.startDate = new Date(dayStart);
                            values.endDate = new Date(dayEnd);

                            break;
                        default:
                            break;
                    }

                    values.account = account;

                    if (values.serialNo === "") {
                        values.serialNo = props.defaultValues.serialNo;
                    }
                    else {
                        values.serialNo = serialNo;
                    }

                    if (isNaN(values.startDate as number) || isNaN(values.endDate as number)) {
                        values.startDate = new Date(props.defaultValues.startDate);
                        values.endDate = new Date(props.defaultValues.endDate);
                    }

                    props.onSubmit(values);
                }}
            >
                <Form style={{ paddingTop: '6px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            {chartType === filterTypes.DAY && (
                                <Field
                                    label={getChartMaxDaysLabel(chartType)}
                                    name={'endingOn'}
                                    dateOnly={true}
                                    disabled={false}
                                    defaultValue={today}
                                    component={UcDatePickerFormik}
                                    minDate={ensureLuxonDateOnly(minDate)}
                                    maxDate={ensureLuxonDateOnly(maxDate)}
                                    inputFormat={inputFormat()}
                                    views={dateViews()}
                                    sendValueToForm={handleChangeEndingOn}
                                />
                            )}
                            {chartType === filterTypes.MONTH && (
                                <Field
                                    name={'endingOnMonthly'}
                                    label={getChartMaxDaysLabel(chartType)}
                                    component={BillPeriodAdminSelect}
                                    accountId={account?.id}
                                    startDate={minDate}
                                    endDate={maxDate}
                                    excludeFutureStart={true}
                                    minWidth={300}
                                    disableClearable
                                    setAreBillingPeriods={setAreBillingPeriods}
                                    uom={props.uom}
                                    onChange={(event: any, newValue: any, allOptions: any[], forceSubmit: boolean) => {
                                        handleChangeEndingOnMonthly(newValue.startDateTimeUtc);
                                        if (forceSubmit) {
                                            formRef.current?.submitForm();
                                        }
                                    }}
                                />
                            )}
                            {chartType === filterTypes.YEAR && (
                                <Field
                                    label={getChartMaxDaysLabel(chartType)}
                                    name={'endingOnYearly'}
                                    dateOnly={true}
                                    disabled={false}
                                    defaultValue={today}
                                    component={UcDatePickerFormik}
                                    minDate={ensureLuxonDateOnly(minDate)}
                                    maxDate={ensureLuxonDateOnly(maxDate)}
                                    inputFormat={inputFormat()}
                                    views={dateViews()}
                                    sendValueToForm={handleChangeEndingOnYearly}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {!areBillingPeriods && (
                        <Alert sx={{ mt: 2 }} severity="warning">{t("No billing cycle information available for this account.")}</Alert>
                    )}
                </Form>
            </Formik>
        </div>
    );
};