import React, { useContext, useEffect, useState } from "react";
import { PageContext, PageState, PageAction } from "../../../lib/PageProvider";
import "./MyBillsListPage.scss";
// @ts-ignore
import { UcListPage } from "components/common/UcListPage";
import { UcWidget } from "../../common/UcWidget";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { useResponsive } from "lib/useResponsive";
import { MyBillsList } from "./MyBillsList";
import { Bill } from "./MyBills.interfaces";
import UserBillsWidgetWrapper from "components/widgets/UserBillsWidgetWrapper";
import UserBillOptionsWidgetWrapper from "../../widgets/UserBillOptionsWidgetWrapper";
import { Grid } from "@mui/material";

export const MyBillsListPage = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const [pageState, pageDispatch] =
        useContext<[PageState, React.Dispatch<PageAction>]>(PageContext);
    const [selectedItem, setSelectedItem] = useState<Bill | null>(null);

    useEffect(() => {
        pageDispatch({
            header: t(""),
            actionItems: [] as any[],
            variableHeader: "",
            tabs: [],
            onTabChange: () => {},
            tabValue: "",
        });
    }, [pageState.header, pageDispatch, selectedItem, t]);

    console.log("MyBillsListPage - Loaded");

    return isMobile ? (
        <>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <UserBillsWidgetWrapper hideWidgetActionItems={true} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ paddingBottom: 2 }}>
                    <UserBillOptionsWidgetWrapper />
                </Grid>
            </Grid>
            <MyBillsList setSelectedItem={setSelectedItem} />
        </>
    ) : (
            <>
                <Grid container direction="row" spacing={2} sx={{ pb:2 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <UserBillsWidgetWrapper hideWidgetActionItems={true} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <UserBillOptionsWidgetWrapper />
                    </Grid>
                </Grid>
                <UcWidget contentPadding="1rem" alignTitleLeft={false} title={t("Bill History")}>
                    <UcListPage>
                        <MyBillsList
                            setSelectedItem={(val: any) => setSelectedItem(val)}
                        />
                    </UcListPage>
                </UcWidget>
            </>      
    );
};
