// External Dependencies
import React from 'react';
import { useTranslation } from "react-i18next";
import { IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// Internal Dependencies
import useAccount from '../../lib/useAccount';
import { UcMeterSelectV2 } from '../common/UcMeterSelectV2';
import { USAGE_DESCRIPTIONS, USAGE_FILTER_TYPES, USAGE_METER_COMMODITIES } from './MyUsage.constants';
import { isMeterInRange } from 'utils/helpers/general.helpers';
import { UseMyUsageActionsProps } from './MyUsage.interfaces';
// @ts-ignore
import { UcButtonChartDownloadV2 } from './UcButtonChartDownloadV2';
// @ts-ignore
import { UcHint } from '../common/UcHint';
// @ts-ignore
import { dateFormatter, uomToCommodity } from '../../lib/Formatters';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';

function useMyUsageActions(props: UseMyUsageActionsProps) {
    const { startDate, endDate, uom, csvDataToDownload, setIsDownloadReady, orgTempUnit, hasCostModule, parentHandleOnChangeChartType, parentHandleOnChangeSelectedMeter, parentHandleOpenFilterDialog } = props;
    const { t } = useTranslation();
    const { account } = useAccount();
    const { isMobile, isTablet } = useResponsive();

    const [chartDescription, setChartDescription] = React.useState(USAGE_DESCRIPTIONS.DAY);

    const [selectedMeterOption, setSelectedMeterOption] = React.useState<any>(null);
    const [meterOptions, setMeterOptions] = React.useState<any>([]);

    const [chartType, setChartType] = React.useState(USAGE_FILTER_TYPES.DAY);

    // Runs whenever account, uom, or meter options change
    React.useEffect(() => {
        if (account?.metersJson) {
            const commodity = uomToCommodity(uom);
            const meters = JSON.parse(account.metersJson);
            let metersAdjusted: any[] = [];

            let electricMeters = meters
                .filter((item: any) => item.meterCommodity === USAGE_METER_COMMODITIES.ELECTRIC && isMeterInRange(item, startDate, endDate));

            if (electricMeters.length > 1) {
                metersAdjusted = [(
                    {
                        id: "-1",
                        meterCommodity: USAGE_METER_COMMODITIES.ELECTRIC,
                        serialNo: null, 
                    }
                )];
            }

            metersAdjusted = [...metersAdjusted, ...electricMeters];

            let waterMeters = meters
                .filter((item: any) => item.meterCommodity === USAGE_METER_COMMODITIES.WATER && isMeterInRange(item, startDate, endDate));

            if (waterMeters.length > 1) {
                metersAdjusted = [...metersAdjusted, (
                    {
                        id: "-2",
                        meterCommodity: USAGE_METER_COMMODITIES.WATER,
                        serialNo: null,
                    }
                )]
            }

            metersAdjusted = [...metersAdjusted, ...waterMeters];

            const metersAdjustedIds = metersAdjusted.map(meter => meter.id);
            const meterOptionsIds = meterOptions.map((meter:any) => meter.id);

            const arraysHaveSameIds = metersAdjustedIds.every(id => meterOptionsIds.includes(id))
                                        && meterOptionsIds.every((id: number) => metersAdjustedIds.includes(id));

            const meterExistsInNewOptions = metersAdjustedIds.includes(selectedMeterOption?.id);

            if (!arraysHaveSameIds) {
                setMeterOptions(metersAdjusted);
                if (metersAdjusted.length > 0 && !meterExistsInNewOptions) {
                    let option = metersAdjusted.find((x) => x.meterCommodity === commodity);
                    if (!option) { // new meters do not have existing commodity
                        option = metersAdjusted[0];
                    }
                    setSelectedMeterOption(option);
                    parentHandleOnChangeSelectedMeter(option);
                }
            }
        }
    }, [account, endDate, startDate, uom, selectedMeterOption, meterOptions, parentHandleOnChangeSelectedMeter]);

    const handleXMLClick = () => {
        setIsDownloadReady(true);
    }

    const getFileName = (fileType: string) => {
        const formattedCurrentDate = dateFormatter(new Date(), true);

        switch (chartType) {
            case USAGE_FILTER_TYPES.YEAR:
                return `myYearlyUsage_${account?.extAccountNumber}_${formattedCurrentDate}.${fileType}`;
            case USAGE_FILTER_TYPES.MONTH:
                return `myMonthlyUsage_${account?.extAccountNumber}_${formattedCurrentDate}.${fileType}`;
            case USAGE_FILTER_TYPES.DAY:
                return `myDailyUsage_${account?.extAccountNumber}_${formattedCurrentDate}.${fileType}`;
            default:
                return `myUsage_${account?.extAccountNumber}_${formattedCurrentDate}.${fileType}`;
        }
    }

    const handleOnChangeSelectedMeter = (option: any) => {
        setSelectedMeterOption(option);
        parentHandleOnChangeSelectedMeter(option);
    };

    const handleOnChangeChartType = (event: React.MouseEvent) => {
        const value = (event.target as HTMLButtonElement).value;

        switch (value) {
            case USAGE_FILTER_TYPES.YEAR:
                setChartDescription(USAGE_DESCRIPTIONS.YEAR);
                break;
            case USAGE_FILTER_TYPES.MONTH:
                setChartDescription(USAGE_DESCRIPTIONS.MONTH);
                break;
            case USAGE_FILTER_TYPES.DAY:
                setChartDescription(USAGE_DESCRIPTIONS.DAY);
                break;
        }
        setChartType(value);
        parentHandleOnChangeChartType(value);
    };

    let actionItems = [
        {
            component: (
                <UcHint
                    hintText={t(chartDescription)}
                    alertText={
                        isMobile
                            ? t("Tap a bar to view more info about it. \nSwipe left or right on the graph to reveal more dates if they're available.")
                            : t("Click on a bar to view more info about it.")
                    }
                />
            )
        },
        {
            hasMarginRight: true,
            component: (  
                <IconButton
                    title={t('Date Filter')}
                    onClick={parentHandleOpenFilterDialog}
                    aria-label="filter"
                    sx={{ color: 'primary.main' }}
                >
                    <Tooltip title={t('Date Filter')} placement="top">
                        <CalendarMonthIcon />
                    </Tooltip>
                </IconButton>
            )
        },
        {
            hasMarginRight: true,
            component: (
                <UcMeterSelectV2
                    account={account}
                    startDate={startDate}
                    endDate={endDate}
                    uom={uom}
                    forcedWidth={(!isMobile && !isTablet) ? '50%' : '100%'}
                    value={selectedMeterOption}
                    onChange={handleOnChangeSelectedMeter}
                    options={meterOptions}
                    onlyCommodity={false}
                    marginTop={"1px"}
                />
            )
        },
        {
            hasMarginRight: true,
            component: (
                <ToggleButtonGroup
                    value={chartType}
                    size="small"
                    exclusive
                    onChange={handleOnChangeChartType}
                    aria-label="ChartType"
                >
                    <ToggleButton color='primary' value="day" title={t('Day')}>{t('Day')}</ToggleButton>
                    <ToggleButton color='primary' value="month" title={t('Month')}>{t('Month')}</ToggleButton>
                    <ToggleButton color='primary' value="year" title={t('Year')}>{t('Year')}</ToggleButton>
                </ToggleButtonGroup>
            )
        }
    ];

    if (csvDataToDownload.length > 0) {
        const chartDownloadComponent = {
            component: (
                <UcButtonChartDownloadV2
                    //@ts-ignore
                    data={csvDataToDownload}
                    selectedMeter={selectedMeterOption?.serialNo}
                    uom={uom}
                    csvTempUom={orgTempUnit}
                    filename={getFileName("csv")}
                    onXMLClick={handleXMLClick}
                    isXmlGenerating={false}
                    palletColor='primary.main'
                    hasCostModule={hasCostModule}
                />
            )
        };

        actionItems.splice(1, 0, chartDownloadComponent);
    }

    return { actionItems, getFileName, meterOptions };
}

export default useMyUsageActions;