export const USAGE_HEADERS = {
    YEAR: 'Yearly Usage',
    MONTH: 'Monthly Usage',
    DAY: 'Daily Usage'
}

export const USAGE_DESCRIPTIONS = {
    YEAR: 'This chart displays your yearly usage for electricity, water, or gas within the selected date range, based on their respective rates.',
    MONTH: 'This chart displays your monthly usage for electricity, water, or gas within the selected date range, based on their respective rates.',
    DAY: 'This chart displays your daily usage for electricity, water, or gas within the selected date range, based on their respective rates.'
}

export const USAGE_FILTER_TYPES = {
    YEAR: 'year',
    MONTH: 'month',
    DAY: 'day'
}

export const USAGE_METER_COMMODITIES = {
    ELECTRIC: 'ELECTRIC',
    WATER: 'WATER',
    GAS: 'GAS'
}