// Box.jsx -- Overrides mui Box so we can use title attribute
// Do not use this component on new changes
// This was only a stub to get current functionality working again with a TS update
import React from 'react';
import PropTypes from 'prop-types';
import { Box as MuiBox } from '@mui/material';

export const Box = ({ children, ...props }) => {
  return (
    <MuiBox {...props}>
      {children}
    </MuiBox>
  );
};

Box.propTypes = {
  children: PropTypes.node.isRequired
};