import React, { useRef } from "react";
import { MyUsageFilterProps } from "./MyUsage.interfaces";
import { useTranslation } from "react-i18next";
// @ts-ignore
import { DateTime } from 'luxon';
// @ts-ignore
import { ensureLuxonDateOnly } from '../../lib/Formatters';
// @ts-ignore
import { useMyTimezone } from 'components/customer/orgs/MyOrgAPIEndpoints';
// @ts-ignore
import { BillPeriodAdminSelect } from 'components/admin/Customers/BillPeriodAdminSelect';
// @ts-ignore
import { UcDatePickerFormik } from 'components/common/UcDatePicker';
import { Formik, Form, Field, FormikProps } from 'formik';
import useAccount from "../../lib/useAccount";
import { USAGE_FILTER_TYPES } from "./MyUsage.constants";
import { Alert, Grid } from "@mui/material";
import { UsageHelpers } from "./MyUsage.helpers";

interface MyUsageFilterFormValues {
    account: any;
    startDate: Date | number;
    endDate: Date | number;
    endingOn: Date;
    endingOnMonthly: Date;
    endingOnYearly: Date;
}

function MyUsageFilter(props: Readonly<MyUsageFilterProps>) {
    const dialogSubmit = useRef(props.dialogSubmit);
    const formRef = useRef<FormikProps<MyUsageFilterFormValues>>(null);
    const { t } = useTranslation();
    const timezone = useMyTimezone();
    const { account } = useAccount();
    const [areBillingPeriods, setAreBillingPeriods] = React.useState(true);

    const yesterday = DateTime.now().minus({ days: 1 });
    const minDate = DateTime.fromISO(account?.minStartTimestamp).toUTC().toISO();
    const maxDate = DateTime.fromISO(account?.maxEndTimestamp).toUTC().toISO();

    const getDateFilterInputFormat = () => {
        switch (props.chartType) {
            case USAGE_FILTER_TYPES.DAY:
                return "MMM/DD/YYYY";
            case USAGE_FILTER_TYPES.YEAR:
            case USAGE_FILTER_TYPES.MONTH:
            default:
                return "MMM/YYYY";
        }
    }

    const getDateFilterViews = () => {
        let views = ['day'];

        if (props.chartType === USAGE_FILTER_TYPES.YEAR || props.chartType === USAGE_FILTER_TYPES.MONTH) {
            views = ['month', 'year'];
        }

        return views;
    }

    const getChartMaxDaysLabel = () => {
        if (props.chartType === USAGE_FILTER_TYPES.YEAR) {
            return t("Month/Year (Past {{maxDays}} Months)", { maxDays: 12 });
        }
        if (props.chartType === USAGE_FILTER_TYPES.MONTH) {
            return t("Billing Period");
        }
        return t("Past {{maxHours}} Hours (Day Start to End)", { maxHours: 24 });
    }
    const handleChangeEndingOn = function (value: any) {
        if (formRef.current !== null && formRef.current !== undefined) {
            formRef.current.values.endingOn = DateTime.fromJSDate(new Date(value)).toUTC().toISO();
        }
    }
    const handleChangeEndingOnMonthly = function (value: any) {
        if (formRef.current !== null && formRef.current !== undefined) {
            formRef.current.values.endingOnMonthly = DateTime.fromJSDate(new Date(value)).toUTC().toISO();
        }
    }
    const handleChangeEndingOnYearly = function (value: any) {
        if (formRef.current !== null && formRef.current !== undefined) {
            formRef.current.values.endingOnYearly = DateTime.fromJSDate(new Date(value)).toUTC().toISO();
        }
    }

    React.useEffect(() => {
        if (props.dialogSubmit !== dialogSubmit.current) {
            dialogSubmit.current = props.dialogSubmit;
            if (formRef.current) {
                formRef.current.handleSubmit();
            }
        }
    });

    return (
        <div className="myUsageFilter ignoreCustomFormStyles">
            <Formik
                innerRef={formRef}
                initialValues={props.defaultValues}
                onSubmit={(values) => {
                    switch (props.chartType) {
                        case USAGE_FILTER_TYPES.YEAR: {
                            const endingOnDateTimeYearly = DateTime.fromISO(values.endingOnYearly);
                            let yearStart = UsageHelpers.createDateFromObject(endingOnDateTimeYearly.year, endingOnDateTimeYearly.month, endingOnDateTimeYearly.day, timezone, 'yearStart');
                            const yearEnd = UsageHelpers.createDateFromObject(endingOnDateTimeYearly.year, endingOnDateTimeYearly.month, endingOnDateTimeYearly.day, timezone, 'yearEnd');

                            if (yearStart < minDate) {
                                yearStart = UsageHelpers.createDateFromObject(minDate.year, minDate.month, minDate.day, timezone, 'yearStart');
                            }

                            values.startDate = yearStart;
                            values.endDate = yearEnd;

                            break;
                        }
                        case USAGE_FILTER_TYPES.MONTH: {
                            const endingOnDateTimeMonthly = DateTime.fromISO(values.endingOnMonthly);
                            const monthStart = UsageHelpers.createDateFromObject(endingOnDateTimeMonthly.year, endingOnDateTimeMonthly.month, endingOnDateTimeMonthly.day, timezone);
                            const monthEnd = UsageHelpers.createDateFromObject(endingOnDateTimeMonthly.year, endingOnDateTimeMonthly.month, endingOnDateTimeMonthly.day, timezone, 'monthEnd');

                            values.startDate = monthStart;
                            values.endDate = monthEnd;

                            break;
                        }  
                        case USAGE_FILTER_TYPES.DAY: {
                            const endingOnDateTime = DateTime.fromISO(values.endingOn);
                            const dayStart = UsageHelpers.createDateFromObject(endingOnDateTime.year, endingOnDateTime.month, endingOnDateTime.day, timezone, 'hourStart', 0);
                            const dayEnd = UsageHelpers.createDateFromObject(endingOnDateTime.year, endingOnDateTime.month, endingOnDateTime.day, timezone, 'hourEnd');

                            values.startDate = dayStart;
                            values.endDate = dayEnd;
                            break;
                        }
                        default:
                            break;
                    }

                    values.account = account;
   
                    if (isNaN(values.startDate as number) || isNaN(values.endDate as number)) {
                        values.startDate = new Date(props.defaultValues.startDate);
                        values.endDate = new Date(props.defaultValues.endDate);
                    }

                    props.onSubmit(values);
                }}
            >
                <Form style={{ paddingTop: '6px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            {props.chartType === USAGE_FILTER_TYPES.DAY && (
                                <Field
                                    label={getChartMaxDaysLabel()}
                                    name={'endingOn'}
                                    dateOnly={true}
                                    disabled={false}
                                    defaultValue={yesterday}
                                    component={UcDatePickerFormik}
                                    minDate={ensureLuxonDateOnly(minDate)}
                                    maxDate={ensureLuxonDateOnly(maxDate)}
                                    inputFormat={getDateFilterInputFormat()}
                                    views={getDateFilterViews()}
                                    sendValueToForm={handleChangeEndingOn}
                                />
                            )}
                            {props.chartType === USAGE_FILTER_TYPES.MONTH && (
                                <Field
                                    name={'endingOnMonthly'}
                                    label={getChartMaxDaysLabel()}
                                    component={BillPeriodAdminSelect}
                                    accountId={account?.id}
                                    startDate={minDate}
                                    endDate={maxDate}
                                    excludeFutureStart={true}
                                    minWidth={300}
                                    disableClearable
                                    setAreBillingPeriods={setAreBillingPeriods}
                                    uom={props.uom}
                                    onChange={(event: any, newValue: any, allOptions: any[], forceSubmit: boolean) => {
                                        handleChangeEndingOnMonthly(newValue.startDateTimeUtc);
                                        if (forceSubmit) {
                                            formRef.current?.submitForm();
                                        }
                                    }}
                                />
                            )}
                            {props.chartType === USAGE_FILTER_TYPES.YEAR && (
                                <Field
                                    label={getChartMaxDaysLabel()}
                                    name={'endingOnYearly'}
                                    dateOnly={true}
                                    disabled={false}
                                    defaultValue={yesterday}
                                    component={UcDatePickerFormik}
                                    minDate={ensureLuxonDateOnly(minDate)}
                                    maxDate={ensureLuxonDateOnly(maxDate)}
                                    inputFormat={getDateFilterInputFormat()}
                                    views={getDateFilterViews()}
                                    sendValueToForm={handleChangeEndingOnYearly}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {!areBillingPeriods && (
                        <Alert sx={{ mt: 2 }} severity="warning">{t("No billing cycle information available for this account.")}</Alert>
                    )}
                </Form>
            </Formik>
        </div>
    );
}

export default MyUsageFilter;