import { useTranslation } from "react-i18next";
import {
    Typography,
    Card,
    CardContent,
    Snackbar,
    Alert,
    Grid,
    Switch,
    FormControlLabel,
    Divider,
    Collapse,
    CardHeader,
    Link
} from "@mui/material";
// @ts-ignore
import { useUserPref } from "components/user/useUserPref";
import { useState } from "react";
// @ts-ignore
import { DeliveryMethodMultiSelect } from './DeliveryMethodMultiSelect';
import { SelectChangeEvent } from '@mui/material/Select';
// @ts-ignore
import { useResponsive } from 'lib/useResponsive';
// @ts-ignore
import { useHasModule } from "components/auth/PermissionApiEndpoint";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UcExpandMore } from "components/common/UcExpandMore";
// @ts-ignore
import { useMyUser } from './MyUserAPIEndpoints';
// @ts-ignore
import { useDomainNavigate } from "domain";
// @ts-ignore
import { useEnums } from 'components/auth/PermissionApiEndpoint';
import { Enum } from '../common/Common.interfaces'


export const MyNotificationPreferences = (props: {collapseOnMobile?: boolean}) => {
    const { t } = useTranslation();
    const navigate = useDomainNavigate();
    const { isMobile } = useResponsive();
    const hasBillingModule = useHasModule("Billing");
    const hasOutageModule = useHasModule("OutageManual");
    const notificationDeliveryMethod = useUserPref(
        "notificationDeliveryMethod",
        "Email"
    );
    const isSubscribedNewBill = useUserPref("isSubscribedNewBill", false);
    const isSubscribedBillAlmostDue = useUserPref("isSubscribedBillAlmostDue", false);
    const isSubscribedOutage = useUserPref("isSubscribedOutage", false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const collapse = (isMobile && props.collapseOnMobile) || false;
    const [expanded, setExpanded] = useState(!collapse);

    const swrMyUser = useMyUser();

    let user = null;
    if (!swrMyUser.isLoading && swrMyUser.result) {
        user = { ...swrMyUser.result };
    }

    const deliveryMethodEnums = useEnums("DeliveryMethod");

    let deliveryMethodDisabledOptions: Enum[] = [];
    let smsEnumValue = null;

    if (!user?.phoneNumber && deliveryMethodEnums) {
        smsEnumValue = deliveryMethodEnums?.find((method: Enum) => method.id === "SMS");
        if (smsEnumValue) {
            deliveryMethodDisabledOptions = [ smsEnumValue ]
        }
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleSubscriptionToggle = (event: React.ChangeEvent<HTMLInputElement>, userPref: any, userPrefString: string) => {
        const newValue = event.target.checked;
        userPref.setUserPref(userPrefString, newValue);
        setOpenSnackbar(true);
    };

    const handleChangeNotificationDeliveryMethod = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;

        if (value.length === 0 || value === '') {
            setOpenErrorSnackbar(true);
        }
        else {
            notificationDeliveryMethod.setUserPref("notificationDeliveryMethod", value);
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = (reason: any) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleCloseErrorSnackbar = (reason: any) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenErrorSnackbar(false);
    };

    return (
        <>
            <Card variant="outlined">

            <CardHeader
                onClick={collapse ? handleExpandClick : () => {}}
                action={
                    collapse && (
                        <UcExpandMore
                            expanded={expanded}
                            sx={{ mr: 1}}
                        >
                            <ExpandMoreIcon />
                        </UcExpandMore>
                    )
                }
                title={
                    <Typography
                        color="text.secondary"
                        gutterBottom
                    >
                        {t("Notification Settings")}
                    </Typography>
                }
            />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Grid container direction="row">  
                        <Grid item xs={12} sm={5} md={4} lg={3}>
                            <Grid container direction="column">
                                <Grid item xs={12}>
                                    <DeliveryMethodMultiSelect
                                        enumName="DeliveryMethod"
                                        width="80%"
                                        datacyid={"alert_preference_notification_deliveryMethod"}
                                        forcedDisplay="inline-flex"
                                        label={t("Delivery Method")}
                                        value={notificationDeliveryMethod.value}
                                        disabledOptions={deliveryMethodDisabledOptions}
                                        onChange={handleChangeNotificationDeliveryMethod}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ ml: 1.5 }}>
                                    {!user?.phoneNumber && !swrMyUser.isLoading && smsEnumValue && (
                                        <Alert severity="warning" sx={{ width: '80%' }}> 
                                            {t("In order to select SMS as a preference, you must first add a phone number to your ")}
                                            <Link
                                                component="button"
                                                onClick={() => navigate('/myProfile?profileNav_link=Details')}
                                                variant="body2"
                                            >
                                                {t('Profile.')}
                                            </Link>
                                        </Alert>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                            <Grid item xs={12} sm={7} md={8} lg={9}>
                                {(hasBillingModule || hasOutageModule) && (
                                    <>
                                        <Typography
                                            sx={{ mb: 1, mt: isMobile && 2 }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            {t("Manage Subscriptions")}
                                        </Typography>
                                        {hasBillingModule && (
                                            <>
                                                <Grid item xs={12} sx={{ padding: 1, paddingLeft: 0 }}>
                                                    <FormControlLabel
                                                        sx={{
                                                            justifyContent: 'space-between',
                                                            display: 'flex',
                                                        }}
                                                        labelPlacement="start"
                                                        label={
                                                            <>
                                                                <Typography>
                                                                    {t("New Bills")}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{ fontSize: 14 }}
                                                                    color="text.secondary"
                                                                >
                                                                    {t("Opt-in or out to receiving notifications for newly available bills.")}
                                                                </Typography>
                                                            </>
                                                        }
                                                        control={
                                                            <Switch
                                                                checked={isSubscribedNewBill.valueBool}
                                                                onChange={(event) => handleSubscriptionToggle(event, isSubscribedNewBill, "isSubscribedNewBill")}
                                                                inputProps={{
                                                                    "aria-label": "controlled",
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                                <Divider />
                                                <Grid item xs={12} sx={{ padding: 1, paddingLeft: 0 }} >
                                                    <FormControlLabel
                                                        sx={{
                                                            justifyContent: 'space-between',
                                                            display: 'flex',
                                                        }}
                                                        labelPlacement="start"
                                                        label={
                                                            <>
                                                                <Typography>
                                                                    {t("Bills Almost Due")}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{ fontSize: 14 }}
                                                                    color="text.secondary"
                                                                >
                                                                    {t("Opt-in or out to receiving notifications for bills that are due in less than one week.")}
                                                                </Typography>
                                                            </>
                                                        }
                                                        control={
                                                            <Switch
                                                                checked={isSubscribedBillAlmostDue.valueBool}
                                                                onChange={(event) => handleSubscriptionToggle(event, isSubscribedBillAlmostDue, "isSubscribedBillAlmostDue")}
                                                                inputProps={{
                                                                    "aria-label": "controlled",
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                                <Divider />
                                            </>
                                        )}
                                        {hasOutageModule && (
                                            <>                                      
                                                <Grid item xs={12} sx={{ padding: 1, paddingLeft: 0 }}>
                                                    <FormControlLabel
                                                        sx={{
                                                            justifyContent: 'space-between',
                                                            display: 'flex',
                                                        }}
                                                        labelPlacement="start"
                                                        label={
                                                            <>
                                                                <Typography>
                                                                    {t("Outages")}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{ fontSize: 14 }}
                                                                    color="text.secondary"
                                                                >
                                                                    {t("Opt-in or out to receiving notifications for local outage events.")}
                                                                </Typography>
                                                            </>
                                                        }
                                                        control={
                                                            <Switch
                                                                checked={isSubscribedOutage.valueBool}
                                                                onChange={(event) => handleSubscriptionToggle(event, isSubscribedOutage, "isSubscribedOutage")}
                                                                inputProps={{
                                                                    "aria-label": "controlled",
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                    </Grid>
                </CardContent>
                </Collapse>
            </Card>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: "100%", color: '#fff' }}
                >
                    {t("Preference updated!")}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={3000}
                onClose={handleCloseErrorSnackbar}
            >
                <Alert
                    onClose={handleCloseErrorSnackbar}
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%", color: '#fff' }}
                >
                    {t("You must have at least one delivery method selected!")}
                </Alert>
            </Snackbar>
        </>
    );
};