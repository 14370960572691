import { useState } from "react";
import { useNavigate } from "react-router-dom";

type UcDomain = {
    proto: string;
    host: string;
    baseUrl: string;
    redirectBaseUrl: string;
    theme: string;
    isQueryParam: boolean;
    subDomain: string;
    actualSubDomain: string;
};

export const validSubDomains = ['collus', 'melrosepublicutilities', 'wmuc', 'dev-cep', 'dev-cep-app', 'prod-cep-app', 'cep',
                                'qa-wmuc', 'qa-melrosepublicutilities', 'qa-enerconnect', 'enerconnect', 'qa-essexpowerlines', 'essexpowerlines',
                                'qa-dlpu', 'dlpu', 'qa-saukcentrepublicutilities', 'saukcentrepublicutilities', 'qa-worthingtonutilities', 'worthingtonutilities',
                                'qa-vermillionlightandpower', 'vermillionlightandpower', 'qa-wmu', 'wmu', 'qa-cityofpierreutilities', 'cityofpierreutilities',
                                'qa-beresfordutilities', 'beresfordutilities', 'qa-brookingsutilities', 'brookingsutilities'];
export const permanentSubDomains = ['dev-cep', 'dev-cep-app', 'prod-cep-app', 'cep', 'localhost', 'utilismart'];
export const rootSubDomains = ['localhost'];

export const getDomainObj = (): UcDomain => {
    let actualSubDomain = window.location.host.split(/[.:]/)[0].toLowerCase();
    let subDomainQuery =
        new URLSearchParams(window.location.search).get("subdomain") || "";
    let isQueryParam = false;

    if (subDomainQuery) {
        isQueryParam = true;
        actualSubDomain = subDomainQuery;
    }

    // only use valid subdomains... the root domains do not need an actualSubDomain
    if (!validSubDomains.includes(actualSubDomain)) {
        actualSubDomain = "";
    }

    const subDomain = actualSubDomain;
    const baseHost = (process.env.REACT_APP_CEP_BASE_HOST ?? "").replace(
        "{subdomain}",
        subDomain + "."
    );

    let domain: UcDomain = {
        proto: process.env.REACT_APP_CEP_BASE_PROTO ?? "https://",
        host: baseHost ?? "",
        baseUrl:
            (process.env.REACT_APP_CEP_BASE_PROTO ?? "https://") + baseHost,
        redirectBaseUrl:
            (process.env.REACT_APP_CEP_BASE_PROTO ?? "https://") + baseHost, // for duende redirect... same as baseUrl here
        theme: "default",
        isQueryParam: false,
        subDomain: "",
        actualSubDomain: "",
    };

    if (
        validSubDomains.includes(subDomain) &&
        !permanentSubDomains.includes(subDomain)
    ) {
        domain.subDomain = subDomain;
        domain.actualSubDomain = actualSubDomain;

        domain.theme = subDomain.replace("qa-", "");
        domain.isQueryParam = isQueryParam;
    }

    return domain;
};

export const domain = getDomainObj();

export const appendDomainToUrlStr = (fullUrl: string) => {
    return appendDomainToUrl(new URL(fullUrl));
};

export const appendDomainToUrl = (fullUrl: URL) => {
    const searchParams = new URLSearchParams(fullUrl.search);
    if (domain.isQueryParam) {
        searchParams.set("subdomain", domain.subDomain);
    }

    let queryString = searchParams.toString();
    if (queryString) {
        queryString = "?" + queryString;
    }

    const newUrl = new URL(
        `${fullUrl.origin}${fullUrl.pathname}${queryString}`
    );

    return newUrl.href;
};

export const getUrl = (relativeUrl: string) => {
    let url = new URL("https://replace.com" + relativeUrl);

    const newUrl = appendDomainToUrl(url);

    return newUrl.replace("https://replace.com", "");
};

export const getFullUrl = (relativeUrl: string) => {
    let url = new URL(domain.redirectBaseUrl + relativeUrl);

    return appendDomainToUrl(url);
};

export const useDomainNavigate = () => {
    let navigate = useNavigate();
    const [newNavigate] = useState({
        navigate: (to: string) => {
            navigate(getUrl(to));
        },
    });

    return newNavigate.navigate;
};

export const redirectDomain = (newDomain: string) => {
    // split by . and :
    let actualSubDomain = window.location.host.split(/[.:]/)[0].toLowerCase();
    let searchParams = new URLSearchParams(window.location.search);

    if (
        permanentSubDomains.includes(actualSubDomain) ||
        searchParams.has("subdomain")
    ) {
        // permanentSubDomains can not change subdomain so we have to use querystring alternate
        // Also, if we started down the subdomain querystring path then we should always use subdomain
        searchParams.set("subdomain", newDomain.toLowerCase());
        window.location.search = searchParams.toString();
    } else if (rootSubDomains.includes(actualSubDomain)) {
        window.location.host = `${newDomain}.${window.location.host}`;
    } else {
        window.location.host = window.location.host.replace(
            actualSubDomain,
            newDomain
        );
    }
};
