// @ts-ignore
import { ApiClient } from "../../lib/ApiClient";
// @ts-ignore
import { ensureLuxonDate } from "../../lib/Formatters";
import { useGenericSWR } from "../../lib/useGenericSWR";
import { SwrOptions } from "../../lib/useGenericSWR.interfaces";

const apiEndpoint = 'api/v1/UsageChart';
const apiClient = new ApiClient();

apiClient.getDefaultItem = () => {
    // returning empty object
};
apiClient.getObjectName = () => {
    return "Usage Chart Data";
};

export const useMyUsageChartData = (
    accountId: number | null | undefined,
    groupBy: string,
    uom: string,
    startDate: string | null,
    endDate: string | null,
    serialNo: string | null,
    swrOptions: SwrOptions = {}) => {

    const params = new URLSearchParams();

    if (accountId) {
        params.append('accountId', accountId.toString());
    }

    params.append('groupBy', groupBy);
    params.append('uom', uom);
    params.append('startTimeUTC', ensureLuxonDate(startDate)?.toUTC().toISO());
    params.append('endTimeUTC', ensureLuxonDate(endDate)?.toUTC().toISO());

    if (serialNo) {
        params.append('serialNo', serialNo);
    }

    let url = apiEndpoint + "?" + params.toString();
    return useGenericSWR((accountId === null || serialNo === "none" || startDate === null || endDate === null) ? null : url, apiClient,
        {
            ...swrOptions,
            refreshInterval: 3600000, // refresh every hour
            errorRetryInterval: 3600000, // refresh every hour
            doToast: false
    });
}