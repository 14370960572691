import * as React from 'react';
import { Paper, Box, Typography, Grid, Popover } from '@mui/material';
import { Property } from 'csstype';
// @ts-ignore
import { useResponsive } from '../../lib/useResponsive';
// @ts-ignore
import { MD5 } from 'object-hash';

interface UcWidgetProps {
    children?: JSX.Element | any;
    actionItems?: Array<any>;

    titleRender?: JSX.Element | any;
    title?: string;
    subTitle?: string;
    hideTitlesOnMobile?: boolean;
    titleFixedFontSize?: number;

    background?: string;
    minHeight?: string;
    maxHeight?: string;
    height?: string;
    width?: string;
    className?: string;
    overflowX?: Property.OverflowX | undefined;
    overflowY?: Property.OverflowY | undefined;
    alignTitleLeft?: boolean;

    popoverTitle?: string;
    headerPadding?: string;
    contentPadding?: string;
}

export const UcWidget = (props: UcWidgetProps) => {
    const { isMobile } = useResponsive();
    const { title, popoverTitle, subTitle, titleRender, titleFixedFontSize } = props;
    const { children, actionItems, headerPadding, contentPadding } = props;
    const { background, minHeight, maxHeight, height, width, className, hideTitlesOnMobile, overflowX, overflowY, alignTitleLeft } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const hasPopup = popoverTitle ? true : false;

    const handlePopoverOpen = (event: any) => {
        if (!hasPopup) {
            return;
        }

        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        if (!hasPopup) {
            return;
        }

        setAnchorEl(null);
    };

    const actionItemLength = actionItems?.length ?? 0;

    const [mouseOverId] = React.useState(MD5(new Date()));

    const getPopoverComponent = () => {
        return <Popover
            id={mouseOverId}
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Typography sx={{ p: 1, whiteSpace: "pre-line" }}>
                {popoverTitle}
            </Typography>
        </Popover>
    }

    const getTitleComponent = () => {
        if (titleRender) {
            return titleRender;
        }

        return <Grid container>
            {(title !== undefined && (!hideTitlesOnMobile || !isMobile || actionItems === undefined)) && (
                <Grid item sm={6} md={6} xs={!alignTitleLeft && (actionItems?.length ?? 0) > 0 ? 7 : 12}>
                    <Typography
                        aria-owns={(open && hasPopup) ? mouseOverId : undefined}
                        aria-haspopup={hasPopup}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        fontSize={titleFixedFontSize ? titleFixedFontSize : isMobile ? "20px" : "24px"}
                        fontWeight={500}
                        title={title}
                    >
                        {title}
                        {getPopoverComponent()}
                    </Typography>
                    {subTitle &&
                        <Typography variant="subtitle2" sx={{ fontWeight: 'light' }}>
                            {subTitle}
                        </Typography>
                    }
                </Grid>
            )}
            {(actionItems?.length ?? 0) > 0 && (
                <Grid item sm={6} md={6} xs={(!alignTitleLeft) ? 2 : 12}
                    sx={{
                        textAlign: (title && !isMobile) || !alignTitleLeft ? 'right' : 'left'
                    }}
                >
                    {actionItems?.map((item, index) => (
                        <Box sx={{ display: 'inline', mr: (item.hasMarginRight ? 1 : 0) }} key={item.key}>
                            {item.component}
                        </Box>
                    ))}
                </Grid>
            )}
        </Grid>
    }

    return (
        <Paper elevation={1}
            className={"UcWidget " + (className ?? "")}
            sx={{
                width: width || '100%',
                height: height || '100%',
                background: background || 'background.paper',
                minHeight: minHeight || '358px',
                maxHeight: maxHeight,
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
                borderRadius: 3
            }}
        >
            {(actionItemLength > 0 || title) && (
                <Box
                    sx={{
                        borderRadius: '4px',
                        border: "4px solid transparent",
                        width: '100%'
                    }}
                    style={{ padding: (headerPadding || '1rem 1rem 0px 1rem') }} /*only top and left padding by default*/
                >
                    {getTitleComponent()}
                </Box>
            )}
            <Box
                style={{
                    width: '100%',
                    overflowX: overflowX || 'auto',
                    overflowY: overflowY || 'auto',
                    padding: (contentPadding || '0 0 0 0.5rem'),
                    position: 'relative'
                }}
            >
                {children}
            </Box>
        </Paper>
    );
};