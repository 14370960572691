import { ChartInstanceOfMuiTheme, CustomTickProps } from "./CustomerSummaryChartUtils";
import { useTheme } from '@mui/material';

const CustomTick: React.FC<CustomTickProps> = ({ x = 0, y = 0, payload, barWidth }) => {
    const theme = useTheme<ChartInstanceOfMuiTheme>();

    return (
        <g transform={`translate(${x},${y - 8})`}>
            <line
                x1={barWidth / 2}
                y1={0}
                x2={barWidth / 2}
                y2={8}
                stroke={theme?.props?.Rechart?.axisColor}
                strokeWidth={1}
            />
            <text
                transform={`rotate(${-45})`}
                x={0}
                y={0}
                dy={25}
                textAnchor="end"
                fill={theme?.props?.Rechart?.axisColor}
                fontSize={12}
            >
                {payload?.value}
            </text>
        </g>
    );
};

export default CustomTick;