import { ApiClient } from "../../lib/ApiClient";
import { useGenericSWR } from "../../lib/useGenericSWR";

export const billPeriodApiEndpoint = 'api/v1/BillPeriod';

// See definition from UdmReadings in UdmObjects.cs
const apiClient = new ApiClient();
apiClient.getDefaultItem = () => {
    // returning empty object
};
apiClient.getObjectName = () => {
    return "Bill Period";
};

const useBillPeriods = (filter, load = true, swrOptions = { doToast: false }) => {
    const params = new URLSearchParams();
    
    params.append('filter', JSON.stringify(filter));
    let url = (load && filter.startTimeUTC !== null && filter.endTimeUTC !== null)
        ? billPeriodApiEndpoint + "?" + params.toString()
        : null;

    return useGenericSWR(url, apiClient, { ...swrOptions });
};

export {
    useBillPeriods
};